import { makeStyles } from '@material-ui/core/styles'
import get from 'lodash/get'
import { TextField, useRecordContext } from 'react-admin'

const useStyles = makeStyles({
  preferredSalutation: { 'font-weight': 'bold' },
})

const CustomerTextField = ({ source, ...rest }) => {
  const record = useRecordContext(rest)
  const customerPath = source.match(/^(.*)\./)
  const customer = customerPath ? get(record, customerPath[1]) : record
  const classes = useStyles()
  const classNames = {
    firstname: customer?.informal_salutation ? classes.preferredSalutation : '',
    lastname: customer?.informal_salutation ? '' : classes.preferredSalutation,
  }
  return (
    <TextField
      record={customer}
      source={source}
      className={classNames[source.split('.').pop()]}
      {...rest}
    />
  )
}

CustomerTextField.displayName = 'CustomerTextField'

CustomerTextField.defaultProps = {
  addLabel: true,
}

export default CustomerTextField
