import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import {
  ArrayField,
  ChipField,
  Datagrid,
  DateField,
  EditButton,
  Filter,
  List,
  SelectField,
  SingleFieldList,
  TextField,
  TextInput,
} from 'react-admin'
import { CustomerChipField } from '~/components'
import { documentTypeChoices } from '~/types/choices'

const DocumentsFilter = (props: object) => (
  <Filter {...props}>
    <TextInput label='pos.search' source='type' alwaysOn />
  </Filter>
)

const useStyles = makeStyles((theme) => ({
  tableCell: {
    maxWidth: 330,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}))

const DocumentList = (props: object) => {
  const classes = useStyles()
  return (
    <List
      title='resources.documents.title'
      filters={<DocumentsFilter />}
      bulkActionButtons={false}
      {...props}
    >
      <Datagrid rowClick='edit'>
        {/* <TextField source='type' label='resources.documents.fields.type' /> */}
        <SelectField
          source='type'
          choices={documentTypeChoices}
          label='resources.documents.fields.type'
        />
        <ArrayField source='document_customer' fieldKey='customer_id' basePath='/customers'>
          <SingleFieldList linkType='show'>
            <CustomerChipField source='customer' />
          </SingleFieldList>
        </ArrayField>
        <ArrayField source='document_boat' fieldKey='boat_id' basePath='/boats'>
          <SingleFieldList linkType='show'>
            <ChipField source='boat.model' />
          </SingleFieldList>
        </ArrayField>
        <TextField source='history[0].user.display_name' label='history.modified_by' />
        <DateField source='history[0].time' showTime label='history.last_modified' />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export default DocumentList
