import gql from 'graphql-tag'
import { GET_LIST, GET_ONE } from 'react-admin'
import { upsertQuery } from '~/helpers'

// todo: this is stupid: https://stackoverflow.com/questions/54516028/get-list-is-overriding-get-one-request
// find way to rather merge storage representation of records instaed of overwriting it..
/* then replace GET_LIST with only needed data
      `
        {
          id
          firstname
          lastname
          company
          phones {
            id
            type
            number
          }
          emails {
            id
            type
            email
          }
        }
      `
*/

const fields = gql`
  {
    id
    name
    supplier_services_lkup {
      name
    }
    telephone
    email
    website
    contact_person_email
    contact_person_phone
    contact_person_name
    street
    postal_code
    city
    country
  }
`

export default upsertQuery(
  {
    [GET_ONE]: {
      buildFields: fields,
    },
    [GET_LIST]: {
      buildFields: fields,
    },
  },
  {
    relations: ['contact_person_name'],
  }
)
/*
  ['comments', 'attachments'],
  ['firstname', 'lastname']
  */
