import React from 'react'
import { Datagrid, Filter, List, TextField, TextInput } from 'react-admin'

const UserFilter = (props: object) => (
  <Filter {...props}>
    <TextInput label='pos.search' source='email' alwaysOn />
  </Filter>
)

const UserList = (props: object) => {
  return (
    <List label='Users' filters={<UserFilter />} bulkActionButtons={false} {...props}>
      <Datagrid rowClick='edit'>
        <TextField source='id' label='resources.users.fields.id' />
        <TextField source='user.display_name' label='resources.users.fields.username' />
        <TextField source='email' label='resources.users.fields.email' />
        <TextField source='default_role' label='resources.users.fields.role' />
      </Datagrid>
    </List>
  )
}

export default UserList
