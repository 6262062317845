export const fileStorage = ({ auth, storage }) => {
  return {
    putPrivate: (filename, file) => {
      const user = auth.currentUser
      return storage.put(`/user/${user.id}/${filename}`, file)
    },
    putPublic: (filename, file) => {
      return storage.put(`/public/${filename}`, file)
    },
  }
}
