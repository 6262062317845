import { GridShowLayout, RaGrid, ShowSplitter } from 'ra-compact-ui'
import { cloneElement, isValidElement } from 'react'
import { FormTab, ReferenceManyField, Show, TabbedForm, useRecordContext } from 'react-admin'
import { HistoryTransactionDatagrid, TabToolbar } from '~/components'
import './CompactShow.scss'

const renderGrid = (children, resourceName) =>
  children.map((item) => {
    if (!isValidElement(item)) {
      console.debug('🚨 not a valid element', item)
      return null
    }

    const props = {}
    const { label, source, size } = item.props
    let breakpoints = {}

    // todo child.type.displayName why is this undefined for ArrayField
    if (!item.type.displayName) {
      item.type.name === 'FunctionField' ? (breakpoints = { md: 4 }) : (breakpoints = { md: 12 })
    }
    if (!label) {
      props.label = `resources.${resourceName}.fields.${source}`
    }

    breakpoints = { md: 4, ...breakpoints }
    if (size) {
      breakpoints = Object.keys(breakpoints).reduce(
        (acc, key) => ({ ...acc, [key]: Math.round(breakpoints[key] * parseFloat(size)) }),
        {}
      )
    }

    props.key = props.label ?? item.props.label
    return (
      <RaGrid item {...breakpoints} key={`${props.key}-grid`}>
        {cloneElement(item, props)}
      </RaGrid>
    )
  })

const LeftSide = ({ aside, resource }) => {
  const record = useRecordContext()
  const items = typeof aside === 'function' ? aside(record) : aside
  return (
    <GridShowLayout>
      <RaGrid container spacing={0}>
        {renderGrid(items, resource)}
      </RaGrid>
    </GridShowLayout>
  )
}

const CompactShow = (props) => {
  const { aside, children, toolbars, history = true, ...rest } = props
  return (
    <Show {...rest}>
      <ShowSplitter
        leftSideProps={{
          md: 4,
        }}
        rightSideProps={{
          md: 8,
        }}
        leftSide={<LeftSide {...props} />}
        rightSide={
          <TabbedForm toolbar={<TabToolbar toolbars={toolbars} {...props} />}>
            {children}
            {history && (
              <FormTab label='resources.history.title'>
                <ReferenceManyField
                  reference='history_transactions'
                  target='relation_id'
                  label=''
                  filter={{ relation: props.resource }}
                  sort={{ field: 'action_tstamp_tx', order: 'DESC' }}
                >
                  <HistoryTransactionDatagrid hideRelation />
                </ReferenceManyField>
              </FormTab>
            )}
          </TabbedForm>
        }
      />
    </Show>
  )
}

export default CompactShow
