import { Button } from '@material-ui/core'
import { RemoveCircleOutline } from '@material-ui/icons'

export const CompactRemoveButton = (props) => {
  return (
    <Button color='primary' size='small' {...props}>
      <RemoveCircleOutline fontSize='small' />
      {'\u2063'}
    </Button>
  )
}

export default CompactRemoveButton
