import { Grid } from '@material-ui/core'
import React from 'react'
import {
  ArrayInput,
  BooleanInput,
  DateInput,
  email,
  FormDataConsumer,
  number,
  required,
  SelectInput,
  SimpleFormIterator,
  TextInput,
} from 'react-admin'
import { AutocompleteTextInput, CompactRemoveButton, RelatedCustomer } from '~/components'

const choices = [
  { id: 'Herr', name: 'Herr' },
  { id: 'Frau', name: 'Frau' },
  { id: 'Divers', name: 'Divers' },
]

const PrimaryInfo = ({ essentialOnly }) => (
  <>
    <>
      <Grid item xs={4}>
        <SelectInput
          fullWidth
          source='salutation'
          label='resources.customers.fields.salutation'
          choices={choices}
        />
      </Grid>

      <Grid item xs={4}>
        <TextInput fullWidth source='title' label='resources.customers.fields.title' />
      </Grid>

      <Grid item xs={4}>
        <BooleanInput
          source='informal_salutation'
          label='resources.customers.fields.informal_salutation'
        />
      </Grid>
    </>

    <Grid item xs={essentialOnly ? 6 : 4}>
      <TextInput
        fullWidth
        source='firstname'
        validate={[required()]}
        label='resources.customers.fields.firstname'
      />
    </Grid>

    <Grid item xs={essentialOnly ? 6 : 4}>
      <TextInput
        fullWidth
        source='lastname'
        validate={[required()]}
        label='resources.customers.fields.lastname'
      />
    </Grid>

    {!essentialOnly && (
      <Grid item xs={4}>
        <TextInput fullWidth source='birth_name' label='resources.customers.fields.birth_name' />
      </Grid>
    )}

    {!essentialOnly && (
      <>
        <Grid item xs={4}>
          <DateInput fullWidth source='birthday' label='resources.customers.fields.birthday' />
        </Grid>

        <Grid item xs={4}>
          <AutocompleteTextInput
            fullWidth
            source='nationality'
            label='resources.customers.fields.nationality'
          />
        </Grid>

        <Grid item xs={4}>
          <TextInput
            fullWidth
            source='identity_card_number'
            label='resources.customers.fields.identity_card_number'
          />
        </Grid>
      </>
    )}
  </>
)

const AddressInfo = (props) => (
  <>
    <Grid item xs={3}>
      <TextInput fullWidth source='company' label='resources.customers.fields.company' />
    </Grid>

    <Grid item xs={9}>
      <TextInput fullWidth source='street' label='resources.customers.fields.street' />
    </Grid>

    <Grid item xs={4}>
      <TextInput fullWidth source='postal_code' label='resources.customers.fields.postal_code' />
    </Grid>

    <Grid item xs={4}>
      <TextInput fullWidth source='city' label='resources.customers.fields.city' />
    </Grid>

    <Grid item xs={4}>
      <AutocompleteTextInput
        fullWidth
        source='country'
        label='resources.customers.fields.country'
      />
    </Grid>
  </>
)

const ContactInfo = (props) => (
  <>
    <Grid item md={12} lg={6}>
      <ArrayInput source='emails' label='resources.customers.fields.emails'>
        <SimpleFormIterator TransitionProps={{ classNames: '' }} disableReordering>
          <FormDataConsumer>
            {({ scopedFormData, getSource }) => (
              <AutocompleteTextInput
                source={getSource('type')}
                label='resources.customers.array_fields.type'
                validate={[required()]}
                autoFocus={!scopedFormData}
              />
            )}
          </FormDataConsumer>
          <TextInput
            source='email'
            label='resources.customers.array_fields.email'
            validate={[required(), email()]}
          />
        </SimpleFormIterator>
      </ArrayInput>
    </Grid>
    <Grid item md={12} lg={6}>
      <ArrayInput source='phones' label='resources.customers.fields.phones'>
        <SimpleFormIterator TransitionProps={{ classNames: '' }} disableReordering>
          <FormDataConsumer>
            {({ scopedFormData, getSource }) => (
              <AutocompleteTextInput
                source={getSource('type')}
                label='resources.customers.array_fields.type'
                validate={[required()]}
                autoFocus={!scopedFormData}
              />
            )}
          </FormDataConsumer>
          <TextInput
            source='number'
            label='resources.customers.array_fields.phone'
            validate={[required(), number()]}
          />
        </SimpleFormIterator>
      </ArrayInput>
    </Grid>
  </>
)

const UpsertCustomer = ({ essentialOnly, ...props }) => (
  <Grid container spacing={essentialOnly ? 0 : 10}>
    <Grid item lg={essentialOnly ? 12 : 8}>
      <Grid container spacing={3}>
        <PrimaryInfo essentialOnly={essentialOnly} {...props} />
        {!essentialOnly && <AddressInfo {...props} />}
        <ContactInfo {...props} />
      </Grid>
    </Grid>
    {!essentialOnly && (
      <Grid item lg={4}>
        <ArrayInput source='customer_customer' label='resources.customers.fields.relationships'>
          <SimpleFormIterator
            TransitionProps={{ classNames: '' }}
            disableReordering
            removeButton={<CompactRemoveButton />}
          >
            <FormDataConsumer>
              {({ scopedFormData, getSource }) => (
                <RelatedCustomer
                  source={getSource('related_customer_id')}
                  reference='customers'
                  id={props.id}
                  validate={[required()]}
                  autoFocus={!scopedFormData}
                />
              )}
            </FormDataConsumer>
            <AutocompleteTextInput
              source='relation_type'
              label='resources.customers.array_fields.type'
              validate={[required()]}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </Grid>
    )}
  </Grid>
)

export default UpsertCustomer
/*
 * Possible sidebar solution ...
 *  - needs styling and toolbar moved ...
 *  better solution Overwride Edit component and include aside into form
  <ShowSplitter
    leftSideProps={{
      md: 8,
    }}
    rightSideProps={{
      md: 4,
      component: 'div',
    }}
    leftSide={
      <Grid container spacing={10}>
            <PrimaryInfo {...props} />
            <AddressInfo {...props} />
            <ContactInfo {...props} />
        </Grid>
    }
    rightSide={
  <ArrayInput source='customer_customer' label='Beziehungen'>
    <SimpleFormIterator>
      <ReferenceInput source='related_customer_id' reference='customers' label='Kunde'>
        <AutocompleteInput
          optionText={({ firstname, lastname }) => `${firstname} ${lastname}`}
          matchSuggestion={(searchText: string, { id }) => props.id != id}
        />
      </ReferenceInput>
      <TextInput source='relation_type' label='Relationstyp' />
    </SimpleFormIterator>
  </ArrayInput>
    } />
*/
