import { Datagrid, DateField, TextField } from 'react-admin'
import { JsonField } from 'react-admin-json-view'
import { TranslateField } from '~/components'

export const HistoryDatagrid = ({ hideRelation }) => {
  return (
    <Datagrid
      expand={
        <JsonField
          source='change'
          reactJsonOptions={{
            name: null,
            collapsed: false,
            enableClipboard: true,
            displayDataTypes: false,
          }}
          addLabel={false}
        />
      }
    >
      {!hideRelation && (
        <TranslateField
          // eslint-disable-next-line no-template-curly-in-string
          translate='resources.${record.relation}.title'
          source='relation'
          label='resources.history.fields.relation'
        />
      )}
      <TranslateField
        // eslint-disable-next-line no-template-curly-in-string
        translate='resources.history.operations.${record.operation}'
        source='operation'
        label='resources.history.fields.operation'
      />
      <DateField source='time' showTime label='resources.history.fields.time' />
      <TextField source='user.display_name' label='resources.users.title' />
      <TextField source='user.account.role.role' label='resources.users.fields.role' />
    </Datagrid>
  )
}

export default HistoryDatagrid
