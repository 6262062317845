//import { Chip } from '@material-ui/core'
import React from 'react'
import { Datagrid, EditButton, Filter, List, TextField, TextInput } from 'react-admin'
import { CustomSearchFilter } from '~/components'
const SupplierFilter = (props: object) => (
  <Filter {...props}>
    <TextInput label='pos.search' source='name' alwaysOn />
  </Filter>
)

/*
const EmailChip = ({ record }) => (
  <Chip
    source='email'
    size='small'
    label={record.email}
    onClick={(event) => {
      if (typeof event.stopPropagation === 'function') {
        event.stopPropagation()
      } else {
        event.cancelBubble = true
      }

      // redirect to email
      window.open(`mailto:${record.email}`, '_blank')
    }}
  />
)*/

const SupplierList = (props: object) => {
  const searchColums = ['name', 'contact_person_name']
  return (
    <List
      title='Kunden'
      filters={<CustomSearchFilter {...props} columns={searchColums} />}
      {...props}
    >
      <Datagrid rowClick='show'>
        <TextField source='id' label='resources.suppliers.fields.id' />
        <TextField source='name' label='resources.suppliers.fields.name' />
        <TextField
          source='supplier_services_lkup.name'
          label='resources.suppliers.fields.services'
        />
        <TextField source='contact_person_name' label='resources.suppliers.fields.contact.name' />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export default SupplierList
