import { AutocompleteInput, ReferenceInput, useTranslate } from 'react-admin'
import { useFormState } from 'react-final-form'

// todo could use this Input generic
const RelatedCustomer = (props) => {
  const translate = useTranslate()
  const { values } = useFormState()

  // let { data, loading } = useQuery(gql`
  //   {
  //     customers {
  //       id
  //       lastname
  //       firstname
  //     }
  //   }
  // `)
  // data = Object.values(data?.customers || {})

  const [, source, index, field] = props.source.match(/^([^[]+)\[(\d)\]\.(.+)$/) ?? []
  // todo after ~10 records selected, values[source] becomes undef.
  const form = (values ?? {})[source] || []

  const selected = form
    // there are undefined values if not filled
    .filter((_) => !!_)
    // filter out source from form values
    .map((record) => record[field])
    // add current resource to selected (no self selecting)
    .concat(parseInt(props.id))

  const escapeRegExp = (value) => (value ? value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') : '') // $& means the whole matched string

  const getOptionText = (record) => record && `${record.firstname} ${record.lastname}`

  return (
    <ReferenceInput
      source={props.source}
      reference='customers'
      label={translate('resources.customers.name', { smart_count: 1 })}
      validate={props.validate}
      enableGetChoices={({ q }) => q?.trim().length > 2}
    >
      <AutocompleteInput
        {...props}
        optionText={getOptionText}
        shouldRenderSuggestions={(value) => value.trim().length > 2}
        matchSuggestion={(filter, suggestion) => {
          const regex = new RegExp(escapeRegExp(filter), 'i')
          const suggestionText = getOptionText(suggestion)
          return !selected.includes(suggestion.id) && suggestionText.match(regex)
        }}
        options={{
          disabled: false, // !notSelected.length,
          helperText: false, // !notSelected.length && translate('resources.customers.empty'),
          InputProps: { autoFocus: props.autoFocus },
        }}
      />
    </ReferenceInput>
  )
}

export default RelatedCustomer
