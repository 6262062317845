import React from 'react'
import { Create, SimpleForm, useGetList } from 'react-admin'
import { UpsertSupplier } from '~/components'

const SupplierCreate = (props: object) => {
  // let services = [
  //   { id: 'boat engine service', name: 'Boat Engine Service' },
  //   { id: 'underwater ship grinding', name: 'Underwater Ship Grinding' },
  //   { id: 'rig service', name: 'Rig Service' },
  //   { id: 'sailmaker', name: 'Sailmaker' },
  // ]
  let services = []
  const { data } = useGetList('supplier_services_lkup')
  Object.keys(data).map((key) => services.push(data[key]))

  return (
    <Create title='resources.customers.create' {...props}>
      <SimpleForm submitOnEnter={true} redirect='show'>
        <UpsertSupplier services={services} />
      </SimpleForm>
    </Create>
  )
}

export default SupplierCreate
