import CancelIcon from '@material-ui/icons/Cancel'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import ErrorIcon from '@material-ui/icons/Error'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import HelpIcon from '@material-ui/icons/Help'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import InfoIcon from '@material-ui/icons/Info'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import WatchLaterIcon from '@material-ui/icons/WatchLater'
import WatchLaterOutlinedIcon from '@material-ui/icons/WatchLaterOutlined'

export const boatStatusChoices = [
  { id: 'processing', name: 'resources.boats.choices.status.processing' },
  { id: 'mediation', name: 'resources.boats.choices.status.mediation' },
  { id: 'handover', name: 'resources.boats.choices.status.handover' },
  { id: 'settlement', name: 'resources.boats.choices.status.settlement' },
  { id: 'completed', name: 'resources.boats.choices.status.completed' },
  { id: 'cancelled', name: 'resources.boats.choices.status.cancelled' },
]

export const boatTypeChoices = [
  { id: 'sailboat', name: 'resources.boats.choices.type.sailboat' },
  { id: 'motorboat', name: 'resources.boats.choices.type.motorboat' },
]

export const motorboatTypeChoices = [
  { id: 'cabin_cruiser', name: 'resources.boats.choices.motorboat_type.cabin_cruiser' },
  { id: 'fishing_boat', name: 'resources.boats.choices.motorboat_type.fishing_boat' },
  { id: 'catamaran', name: 'resources.boats.choices.motorboat_type.catamaran' },
  { id: 'hovercraft', name: 'resources.boats.choices.motorboat_type.hovercraft' },
  { id: 'houseboat', name: 'resources.boats.choices.motorboat_type.houseboat' },
]

export const motorboatPlaningTypeChoices = [
  { id: 'glider', name: 'resources.boats.choices.motorboat_plan.glider' },
  { id: 'semi-glider', name: 'resources.boats.choices.motorboat_plan.semi-glider' },
  { id: 'displacer', name: 'resources.boats.choices.motorboat_plan.displacer' },
]

export const sailboatTypeChoices = [
  { id: 'center_cockpit', name: 'resources.boats.choices.sailboat_type.center_cockpit' },
  { id: 'daysailer', name: 'resources.boats.choices.sailboat_type.daysailer' },
  { id: 'decksalon', name: 'resources.boats.choices.sailboat_type.decksalon' },
  { id: 'dinghy', name: 'resources.boats.choices.sailboat_type.dinghy' },
  { id: 'catamaran', name: 'resources.boats.choices.sailboat_type.catamaran' },
  { id: 'trimaran', name: 'resources.boats.choices.sailboat_type.trimaran' },
  { id: 'classic', name: 'resources.boats.choices.sailboat_type.classic' },
  { id: 'motor_sailer', name: 'resources.boats.choices.sailboat_type.motor_sailer' },
  { id: 'flatboat', name: 'resources.boats.choices.sailboat_type.flatboat' },
]

export const sailboatRiggingTypeChoices = [
  { id: 'kat', name: 'resources.boats.choices.sailboat_rigging.kat' },
  { id: 'sloop', name: 'resources.boats.choices.sailboat_rigging.sloop' },
  { id: 'schooner', name: 'resources.boats.choices.sailboat_rigging.schooner' },
  { id: 'ketch', name: 'resources.boats.choices.sailboat_rigging.ketch' },
  { id: 'yawl', name: 'resources.boats.choices.sailboat_rigging.yawl' },
  { id: 'gaff', name: 'resources.boats.choices.sailboat_rigging.gaff' },
  { id: 'cutter', name: 'resources.boats.choices.sailboat_rigging.cutter' },
]

export const usedBoatChoices = [
  { id: 'used', name: 'resources.boats.choices.usedboat.used' },
  { id: 'new', name: 'resources.boats.choices.usedboat.new' },
  { id: 'display', name: 'resources.boats.choices.usedboat.display' },
]

export const boatConditionChoices = [
  { id: 'tinkerware', name: 'resources.boats.choices.boat_condition.tinkerware' },
  { id: 'casco', name: 'resources.boats.choices.boat_condition.casco' },
  { id: 'used', name: 'resources.boats.choices.boat_condition.used' },
  { id: 'good', name: 'resources.boats.choices.boat_condition.good' },
  { id: 'very_good', name: 'resources.boats.choices.boat_condition.very_good' },
  { id: 'like_new', name: 'resources.boats.choices.boat_condition.like_new' },
  { id: 'new', name: 'resources.boats.choices.boat_condition.new' },
]

export const boatCeLabelingChoices = [
  { id: 'a', name: 'resources.boats.choices.boat_labels.a' },
  { id: 'b', name: 'resources.boats.choices.boat_labels.b' },
  { id: 'c', name: 'resources.boats.choices.boat_labels.c' },
  { id: 'd', name: 'resources.boats.choices.boat_labels.d' },
]

export const selectionUserContextChoices = [
  { id: 'resources.attributes.choices', name: 'resources.selections.contexts.attributes.choices' },
]

export const selectionContextChoices = [
  ...selectionUserContextChoices,
  { id: 'resources.appointments', name: 'resources.appointments.title' },
  { id: 'resources.boats', name: 'resources.boats.title' },
]

export const documentTypeChoices = [
  { id: 'brokerage_agreement', name: 'resources.documents.types.brokerage_agreement' },
]

export const notesIconChoices = [
  { id: 'check', icon: CheckCircleIcon, outlineIcon: CheckCircleOutlineIcon },
  { id: 'info', icon: InfoIcon, outlineIcon: InfoOutlinedIcon },
  { id: 'help', icon: HelpIcon, outlineIcon: HelpOutlineIcon },
  { id: 'error', icon: ErrorIcon, outlineIcon: ErrorOutlineIcon },
  { id: 'cancel', icon: CancelIcon, outlineIcon: CancelOutlinedIcon },
  { id: 'later', icon: WatchLaterIcon, outlineIcon: WatchLaterOutlinedIcon },
]
