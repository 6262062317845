import React from 'react'
import { Datagrid, Filter, List, TextField, TextInput } from 'react-admin'

const RolesFilter = (props: object) => (
  <Filter {...props}>
    <TextInput label='pos.search' source='name' alwaysOn />
  </Filter>
)

const RolesList = (props: object) => {
  return (
    <List label='resources.name' filters={<RolesFilter />} bulkActionButtons={false} {...props}>
      <Datagrid rowClick='edit'>
        <TextField source='id' label='resources.roles.fields.id' />
        <TextField source='name' label='resources.roles.fields.name' />
      </Datagrid>
    </List>
  )
}

export default RolesList
