import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import PropTypes from 'prop-types'
import { FieldTitle, InputProps, useInput } from 'ra-core'
import { FunctionComponent } from 'react'
import { InputHelperText, sanitizeInputRestProps } from 'react-admin'
import NumberFormat from 'react-number-format'
import { standardDecimalFormat } from '~/helpers'

const convertStringToNumber = (value) => {
  const stripped = value.replaceAll(/[^\d,]/g, '')
  const float = parseFloat(stripped.replace(',', '.'))

  return isNaN(float) ? null : float
}

const FormattedNumberInput: FunctionComponent<FormattedNumberInputProps> = ({
  format,
  helperText,
  label,
  margin = 'dense',
  onBlur,
  onFocus,
  onChange,
  options,
  parse = convertStringToNumber,
  resource,
  source,
  step,
  min,
  max,
  validate,
  variant = 'filled',
  inputProps: overrideInputProps,
  ...rest
}) => {
  const {
    id,
    input,
    isRequired,
    meta: { error, submitError, touched },
  } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    source,
    type: 'text',
    validate,
    ...rest,
  })

  const inputProps = { ...overrideInputProps, step, min, max }

  return (
    <NumberFormat
      customInput={TextField}
      {...standardDecimalFormat}
      id={id}
      {...input}
      variant={variant}
      error={!!(touched && (error || submitError))}
      helperText={
        <InputHelperText touched={touched} error={error || submitError} helperText={helperText} />
      }
      label={
        <FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />
      }
      margin={margin}
      inputProps={inputProps}
      {...options}
      {...sanitizeInputRestProps(rest)}
    />
  )
}

FormattedNumberInput.propTypes = {
  label: PropTypes.string,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

FormattedNumberInput.defaultProps = {
  options: {},
  step: 'any',
  textAlign: 'right',
}

export interface FormattedNumberInputProps
  extends InputProps<TextFieldProps>,
    Omit<
      TextFieldProps,
      'label' | 'helperText' | 'onChange' | 'onBlur' | 'onFocus' | 'defaultValue'
    > {
  step?: string | number
  min?: string | number
  max?: string | number
}

export default FormattedNumberInput
