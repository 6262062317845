import { useLocale } from 'react-admin'

const SectionLabel = ({ section }) => {
  const locale = useLocale()
  if (!section) {
    return null
  }
  const sectionSlug = section.slug

  return <span>{section?.translated_name?.[locale] || sectionSlug}</span>
}

export default SectionLabel
