import gql from 'graphql-tag'
import { GET_ONE } from 'react-admin'
import { upsertQuery } from '~/helpers'

export default upsertQuery(
  {
    [GET_ONE]: {
      buildFields: gql`
        {
          id
          created_at
          updated_at
          type
          comment
          linked_type
          linked_id
          relation
          relation_id
          pictures {
            id
            created_at
            updated_at
            type
            uri
            content_type
            title
            caption
          }
        }
      `,
    },
  },
  {
    relations: ['attachments'],
    attachments: ['pictures'],
  }
)
