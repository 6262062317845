import React from 'react'
import { Edit, SimpleForm } from 'react-admin'
import { UpsertSupplier } from '~/components'

export const SupplierEdit = (props: object) => {
  return (
    <Edit mutationMode='undoable' {...props}>
      <SimpleForm warnWhenUnsavedChanges submitOnEnter={true} redirect='show'>
        <UpsertSupplier {...props} />
      </SimpleForm>
    </Edit>
  )
}

export default SupplierEdit
