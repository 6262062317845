import { Card, CardContent } from '@material-ui/core'
import { useTranslate } from 'react-admin'

const Dashboard = (props) => {
  const translate = useTranslate()
  return (
    <>
      <Card>
        <CardContent>{translate('general.homepage_content')}</CardContent>
      </Card>
    </>
  )
}

export default Dashboard
