import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { makeStyles } from '@material-ui/core/styles'
import React, { useCallback } from 'react'
import { Title, useLocale, useSetLocale, useTranslate } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import { changeLocale, changeTheme } from '~/reducers'
import { AppState } from '~/types'

const useStyles = makeStyles({
  label: { width: '10em', display: 'inline-block' },
  button: { margin: '1em' },
})

const Configuration = () => {
  const translate = useTranslate()
  const locale = useLocale()
  const setLocale = useSetLocale()
  const classes = useStyles()
  const theme = useSelector((state: AppState) => state.theme)
  const dispatch = useDispatch()
  return (
    <Card>
      <Title title={translate('pos.configuration')} />
      <CardContent>
        <div className={classes.label}>{translate('pos.theme.name')}</div>
        <Button
          variant='contained'
          className={classes.button}
          color={theme === 'light' ? 'primary' : 'default'}
          onClick={useCallback(() => dispatch(changeTheme('light')), [dispatch])}
        >
          {translate('pos.theme.light')}
        </Button>
        <Button
          variant='contained'
          className={classes.button}
          color={theme === 'dark' ? 'primary' : 'default'}
          onClick={useCallback(() => dispatch(changeTheme('dark')), [dispatch])}
        >
          {translate('pos.theme.dark')}
        </Button>

        <Button
          variant='contained'
          className={classes.button}
          color={theme === 'test' ? 'primary' : 'default'}
          onClick={useCallback(() => dispatch(changeTheme('test')), [dispatch])}
        >
          {translate('pos.theme.test')}
        </Button>
      </CardContent>
      <CardContent>
        <div className={classes.label}>{translate('pos.language')}</div>
        <Button
          variant='contained'
          className={classes.button}
          color={locale === 'de' ? 'primary' : 'default'}
          onClick={() => {
            setLocale('de')
            dispatch(changeLocale('de'))
          }}
        >
          Deutsch
        </Button>
        <Button
          variant='contained'
          className={classes.button}
          color={locale === 'en' ? 'primary' : 'default'}
          onClick={() => {
            setLocale('en')
            dispatch(changeLocale('en'))
          }}
        >
          English
        </Button>
      </CardContent>
    </Card>
  )
}

export default Configuration
