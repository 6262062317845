import { gql, useLazyQuery } from '@apollo/client'
import { useInput, useRecordContext, useResourceContext } from 'react-admin'
import { AutocompleteFreeSolo } from '~/components'

export const AutocompleteTextInput = ({ predefinedChoices = [], ...props }) => {
  // TODO: it might be possible to have ra passing the record prop implicitly
  const record = useRecordContext(props) || {}
  let resource = useResourceContext()
  // Get form value via Input-Hook if record context is missing
  const {
    input: { value },
  } = useInput(props)

  let source = props.source
  const splitSourceArray = props.source.split(/\[\d+\]\./)
  if (splitSourceArray.length > 1) {
    resource = splitSourceArray[splitSourceArray.length - 2]
    source = splitSourceArray[splitSourceArray.length - 1]
  }

  // Trying to detect usage as translatable Input
  // (TODO: Add prop to force/disable this behaviour)
  const splitSourceLocale = source.split('.')
  let locale = null
  if (splitSourceLocale.length > 1) {
    locale = splitSourceLocale[splitSourceLocale.length - 1]
    if (locale.match(/^[a-z]{2,3}(-[A-Z]{2,2})?$/)) {
      source = splitSourceLocale.slice(0, splitSourceLocale.length - 1).join('.')
    } else {
      locale = null
    }
  }

  let whereClause = ''
  if (props.where) {
    whereClause = Object.keys(props.where)
      .map((key) => `${key}: {_eq: ${props.where[key]}}`)
      .join(',')
  }

  const [getChoices, { data, loading }] = useLazyQuery(gql`
    {
      ${resource}(distinct_on: ${source}, where: {${whereClause}}) {
        ${source}${locale ? '(path: "' + locale + '")' : ''}
      }
    }
  `)
  const choices = predefinedChoices.concat(
    (data ? data[resource] : [])
      .map((data) => data[source] || '')
      .filter((data) => !!data)
      .filter((data) => !predefinedChoices.includes(data))
  )

  return (
    <>
      <AutocompleteFreeSolo
        getChoices={getChoices}
        choices={choices}
        record={record}
        loading={loading}
        {...props}
      />
    </>
  )
}

export default AutocompleteTextInput
