import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useEffect, useState } from 'react'
import { LinearProgress, useGetList, useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import { SectionLabel } from '~/components'
import { Sections } from '~/graphql/generated'

const useStyles = makeStyles((theme) => {
  console.log(theme)
  return {
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    formControl: {
      // margin: theme.spacing(1),
      minWidth: 120,
    },
    label: {
      background: theme.palette.background.default,
      padding: '0 2px',
    },
  }
})

const ToolbarAddBoatSection = (props) => {
  const classes = useStyles(props)
  const translate = useTranslate()
  const { change, getState } = useForm()
  const { values } = getState()
  const [sectionId, setSection] = useState('')

  const { data: sections, loading } = useGetList<Sections>(
    'sections',
    { page: 1, perPage: 25 },
    { field: 'position', order: 'ASC' }
  )

  useEffect(() => {
    if (sectionId === '') return

    change('boat_section', [
      ...(values.boat_section ?? []),
      {
        section_id: sectionId,
      },
    ])
    setSection('')
  }, [sectionId, setSection, change])

  return loading ? (
    <LinearProgress />
  ) : (
    <>
      <FormControl variant='outlined' margin='dense' className={classes.formControl}>
        <InputLabel className={classes.label} id='section-label'>
          {translate('resources.sections.add')}
        </InputLabel>
        <Select
          labelId='section-label'
          onChange={(e) => setSection(e.target.value)}
          value={sectionId}
        >
          {Object.values(sections)
            .filter(
              (section) =>
                !values.boat_section ||
                !values.boat_section.find((s) => s.section_id === section.id)
            )
            .map((section) => (
              <MenuItem key={section.id} value={section.id}>
                <SectionLabel section={section} />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </>
  )
}

export default ToolbarAddBoatSection
