import { generateApolloClient } from '@nhost/react-apollo'
import { createClient } from 'nhost-js-sdk'

export const generateClient = ({
  auth,
  graphqlURL,
  headers,
  publicRole,
  cache,
  onTokenChanged,
  onAuthStateChanged,
}) => {
  // https://github.com/nhost/react-apollo/blob/master/src/index.js#L10
  const { client, wsLink } = generateApolloClient(auth, graphqlURL, headers, publicRole, cache)
  const noop = () => {}
  auth.onTokenChanged(() => {
    if (wsLink.subscriptionClient.status === 1) {
      wsLink.subscriptionClient.tryReconnect()
    }
    ;(onTokenChanged ?? noop)()
  })
  auth.onAuthStateChanged((data) => {
    // reconnect ws connection with new auth headers for the logged in/out user
    if (wsLink.subscriptionClient.status === 1) {
      // must close first to avoid race conditions
      wsLink.subscriptionClient.close()
      // reconnect
      wsLink.subscriptionClient.tryReconnect()
    }
    ;(onAuthStateChanged ?? noop)(data)
  })
  return client
}

export const backendPlus = (options) => {
  const { baseURL, useCookies = false, ssr = false, autoLogin = true } = options

  // https://docs.nhost.io/libraries/nhost-js-sdk#setup
  return createClient({
    baseURL,
    useCookies,
    ssr,
    autoLogin,
    clientStorage: {
      setItem: (key, value) => {
        console.debug('nhost setItem', key, value)
        return window.localStorage.setItem(key, value)
      },
      getItem: (key) => {
        console.debug('nhost getItem', key)
        //return 'd0fac12a-84df-41a3-ada4-59a8c8a0615c'
        return window.localStorage.getItem(key)
      },
      removeItem: (key) => {
        console.debug('nhost removeItem', key)
        return window.localStorage.removeItem(key)
      },
    },
  })
}
