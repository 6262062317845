// import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ImageField, ImageInput } from 'react-admin'
// import { Field } from 'react-final-form'
// import ReactCrop from 'react-image-crop'
// import 'react-image-crop/dist/ReactCrop.css'

/*
function cropImage(image, canvas, crop) {
  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height
  const ctx = canvas.getContext('2d')
  const pixelRatio = window.devicePixelRatio

  canvas.width = crop.width * pixelRatio
  canvas.height = crop.height * pixelRatio

  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
  ctx.imageSmoothingQuality = 'high'

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  )
}
*/

export const FileInputPreview = (props: object) => {
  /*
  const { refCanvas } = props
  const imgRef = useRef(null)

  const [upImg, setUpImg] = useState()
  const [crop, setCrop] = useState({ width: 0, height: 0 })
  const [completedCrop, setCompletedCrop] = useState(null)

  useEffect(() => {
    if (!completedCrop || !refCanvas.current || !imgRef.current) return

    cropImage(imgRef.current, refCanvas.current, completedCrop)
  }, [completedCrop, refCanvas, imgRef])

  const setLoadedImage = useCallback((img) => {
    imgRef.current = img
  }, [])

  const onSelectFile = (file) => {
    if (file && file.length > 0) {
      const reader = new FileReader()
      reader.readAsDataURL(file[file.length - 1])
      reader.addEventListener('load', () => setUpImg(reader.result))
    }
  }
  */

  return (
    <ImageInput
      source='pictures'
      label='Bilderupload'
      accept='image/*'
      multiple={true}
      // onChange={onSelectFile}
      placeholder={<p>Hier Datei ablegen oder klicken zum Auswählen.</p>}
    >
      {/* <Field name='gallery' component='gallery' source='src'>
        <ReactCrop
          src={upImg}
          onImageLoaded={setLoadedImage}
          crop={crop}
          onChange={(c) => setCrop(c)}
          onComplete={(c) => setCompletedCrop(c)}
        />
        <div>
          <canvas
            source='edited'
            ref={refCanvas}
            style={{
              width: Math.round(completedCrop?.width ?? 0),
              height: Math.round(completedCrop?.height ?? 0),
              display: 'none',
            }}
          />
        </div>
      </Field> */}
      <ImageField source='src' title='title' />
    </ImageInput>
  )
}

export default FileInputPreview
