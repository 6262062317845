import {
  Button as MUIButton,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Step,
  StepButton,
  StepContent,
  StepLabel,
  Stepper,
  Theme,
  Toolbar as MUIToolbar,
  useMediaQuery,
} from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import WarningIcon from '@material-ui/icons/Warning'
import { WizardFormStep } from '@react-admin/ra-form-layout'
import clsx from 'clsx'
import React from 'react'
import {
  FormWithRedirectProps,
  LinearProgress,
  Loading,
  SaveButton,
  setSidebarVisibility,
  useGetList,
  useLocale,
  useTranslate,
} from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import {
  CategoryEntryIcon,
  UpsertBoat,
  UpsertBoatSection,
  WizardForm,
  WizardFormProgressProps,
  WizardToolbarProps,
} from '~/components'
import { Sections } from '~/graphql/generated'
import { categoryEntryState } from '~/helpers'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
  },
}))

const ProgressStepper = ({
  currentStep,
  onStepClick,
  steps,
  invalid,
  ...rest
}: WizardFormProgressProps) => {
  const classes = useStyles()
  const open = useSelector((state) => state.admin.ui.sidebarOpen)
  const { values } = useFormState()
  const translate = useTranslate()
  const locale = useLocale()

  const handleStepClick = (index: number) => (): void => onStepClick(index)

  const isXSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('xs'))

  const dispatch = useDispatch()
  const closeSidebar = () => dispatch(setSidebarVisibility(false))

  const stepsStates = React.useMemo(() => {
    return steps.map((step, index) => {
      const { checkFields, section } = step.props
      const sectionValues = section
        ? values.boat_section?.find((bs) => bs.section_id === section.id)
        : null
      const missingFields = checkFields ? checkFields.filter((field) => !values?.[field]) : []
      const categoriesStates = section
        ? section.categories.map((category) => {
            const categoryValues = sectionValues?.boat_section_category?.find(
              (bsc) => bsc.category_id === category.id
            )
            return categoryEntryState(categoryValues, category)
          })
        : null
      const completed = sectionValues && categoriesStates?.every((cState) => cState.checked)

      return { section, sectionValues, checkFields, missingFields, categoriesStates, completed }
    })
  }, [steps, values])

  return (
    <Drawer
      variant={isXSmall ? 'temporary' : 'permanent'}
      PaperProps={{ style: { borderTop: 'none' } }}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      open={isXSmall ? open : true}
      onClose={closeSidebar}
    >
      <Stepper
        activeStep={currentStep}
        {...rest}
        style={{ marginTop: '48px', border: 'none' }}
        orientation='vertical'
        nonLinear
      >
        {steps.map((step, index) => {
          const label = React.cloneElement(step, { intent: 'label' })
          const { section, checkFields, missingFields, categoriesStates, completed } =
            stepsStates[index]

          return (
            <Step key={`step_${index}`} completed={completed}>
              {!onStepClick || invalid ? (
                <StepLabel error={missingFields?.length > 0}>{label}</StepLabel>
              ) : (
                <StepButton onClick={handleStepClick(index)} tabIndex='-1'>
                  <StepLabel error={missingFields?.length > 0}>{label}</StepLabel>
                </StepButton>
              )}
              {checkFields && (
                <StepContent>
                  <List dense disablePadding>
                    {checkFields.map((field) => (
                      <ListItem disableGutters key={`step_${index}_check_${field}`}>
                        <ListItemIcon>
                          {values?.[field] ? <CheckIcon /> : <WarningIcon />}
                        </ListItemIcon>
                        <ListItemText primary={translate(`resources.boats.fields.${field}`)} />
                      </ListItem>
                    ))}
                  </List>
                </StepContent>
              )}
              {section && (
                <StepContent>
                  <List dense disablePadding>
                    {section.categories.map((category, cIndex) => {
                      const { categoryValues, attributesState } = categoriesStates[cIndex]

                      return (
                        <ListItem
                          disableGutters
                          disabled={!categoryValues}
                          key={`step_${index}_category_${category.id}`}
                        >
                          <ListItemIcon>
                            <CategoryEntryIcon values={categoryValues} state={attributesState} />
                          </ListItemIcon>
                          <ListItemText
                            primary={category.translated_name?.[locale] || category.name}
                          />
                        </ListItem>
                      )
                    })}
                  </List>
                </StepContent>
              )}
            </Step>
          )
        })}
      </Stepper>
    </Drawer>
  )
}

const GuidedBoatSectionForm = ({ section }: { section: Sections }) => {
  const locale = useLocale()
  const { change, getState } = useForm()
  const { values } = getState()
  values.boat_section ||= []
  const searchIdx = values.boat_section.findIndex((bs) => bs.section_id === section.id)
  const formDataIdx =
    searchIdx >= 0 ? searchIdx : values.boat_section.push({ section_id: section.id }) - 1
  const getSource = (scopedSource: string) => `boat_section[${formDataIdx}].${scopedSource}`

  return (
    <>
      {section.translated_name?.[locale]}
      <UpsertBoatSection
        section={section}
        formData={values}
        source={getSource('boat_section_category')}
        guided
        showNotes
      />
    </>
  )
}

const GuidedToolbar = ({
  hasPreviousStep,
  hasNextStep,
  onPreviousClick,
  onNextClick,
  handleSubmit,
  handleSubmitWithRedirect,
  invalid,
  redirect,
  saving,
  submitOnEnter,
  pristine,
  topbarRef,
}: WizardToolbarProps) => {
  const save = handleSubmitWithRedirect || handleSubmit

  const translate = useTranslate()

  return (
    <MUIToolbar>
      <Grid container direction='row' justifyContent='space-between' alignItems='center'>
        <Grid item>
          {hasPreviousStep ? (
            <MUIButton variant='text' onClick={onPreviousClick}>
              Previous{/* {translate('ra-form-layout.action.previous')} */}
            </MUIButton>
          ) : null}
        </Grid>
        <Grid item>
          {hasNextStep ? (
            <MUIButton disabled={invalid} variant='contained' color='primary' onClick={onNextClick}>
              Next{/* {translate('ra-form-layout.action.next')} */}
            </MUIButton>
          ) : (
            <SaveButton
              handleSubmitWithRedirect={handleSubmitWithRedirect || handleSubmit}
              disabled={invalid}
              invalid={invalid}
              redirect={redirect}
              saving={saving}
              submitOnEnter={submitOnEnter}
            />
          )}
        </Grid>
      </Grid>
    </MUIToolbar>
  )
}

const GuidedBoatForm = (props: Omit<FormWithRedirectProps, 'render'>) => {
  const url = useRouteMatch().url
  const formRoot = `${url}/guided`
  const locale = useLocale()
  const { data: sections, loading } = useGetList<Sections>(
    'sections',
    { page: 1, perPage: 25 },
    { field: 'position', order: 'ASC' }
  )
  const classes = useStyles()
  const open = useSelector((state) => state.admin.ui.sidebarOpen)

  return loading ? (
    <>
      <Drawer
        variant='permanent'
        PaperProps={{ style: { zIndex: 1400 } }}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <LinearProgress />
      </Drawer>
      <Loading />
    </>
  ) : (
    <WizardForm
      {...props}
      progress={ProgressStepper}
      toolbar={GuidedToolbar}
      formRootPathname={formRoot}
      syncWithLocation
    >
      <WizardFormStep label='resources.boats.base' checkFields={['name', 'model']}>
        <UpsertBoat {...props} />
      </WizardFormStep>
      {Object.values(sections).map((section) => (
        <WizardFormStep
          label={section.translated_name?.[locale]}
          section={section}
          key={section.slug}
        >
          <GuidedBoatSectionForm section={section} />
        </WizardFormStep>
      ))}
    </WizardForm>
  )
}

export default GuidedBoatForm
