import { Box, Theme, useMediaQuery } from '@material-ui/core'
import SettingsIcon from '@material-ui/icons/Settings'
import * as React from 'react'
import { FC, useState } from 'react'
import {
  DashboardMenuItem,
  LinearProgress,
  MenuItemLink,
  MenuProps,
  usePermissions,
  useTranslate,
} from 'react-admin'
import { useSelector } from 'react-redux'
import {
  AppointmentIcon,
  BoatIcon,
  CategoryIcon,
  CustomerIcon,
  DocumentIcon,
  HistoryTransactionIcon,
  RoleIcon,
  SelectionIcon,
  UserIcon,
} from '~/resources'
import { AppState } from '../types'
import SubMenu from './SubMenu'

type MenuName = 'menuCatalog' | 'menuSales' | 'menuCustomers'

const Menu: FC<MenuProps> = ({ onMenuClick, logout, dense = false }) => {
  const { loaded, permissions } = usePermissions()
  const [state, setState] = useState({
    administration: true,
    customers: true,
    boats: true,
    appointments: true,
    documents: true,
  })

  const translate = useTranslate()

  const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen)
  useSelector((state: AppState) => state.theme) // force rerender on theme change

  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }))
  }

  // todo create this object depending on introspection query result
  const menu = [
    {
      toggle: 'administration',
      icon: <RoleIcon />,
      role: 'admin',
      children: [
        {
          resource: 'auth_accounts',
          icon: <UserIcon />,
        },
        // {
        //   resource: 'auth_roles',
        //   icon: <RoleIcon />,
        // },
        {
          resource: 'history_transactions',
          icon: <HistoryTransactionIcon />,
        },
        {
          resource: 'selections',
          icon: <SelectionIcon />,
        },
      ],
    },
    {
      toggle: 'customers',
      icon: <CustomerIcon />,
      role: 'admin',
      children: [
        {
          resource: 'customers',
          icon: <CustomerIcon />,
        },
        {
          resource: 'suppliers',
          icon: <CustomerIcon />,
        },
      ],
    },
    {
      toggle: 'boats',
      icon: <BoatIcon />,
      role: 'admin',
      children: [
        {
          resource: 'boats',
          icon: <BoatIcon />,
        },
        {
          resource: 'categories',
          icon: <CategoryIcon />,
        },
      ],
    },
    {
      toggle: 'appointments',
      icon: <AppointmentIcon />,
      role: 'admin',
      children: [
        {
          resource: 'appointments',
          icon: <AppointmentIcon />,
        },
      ],
    },
    {
      toggle: 'documents',
      icon: <DocumentIcon />,
      role: 'admin',
      children: [
        {
          resource: 'documents',
          icon: <DocumentIcon />,
        },
      ],
    },
    {
      resource: 'configuration',
      icon: <SettingsIcon />,
      text: 'pos.configuration',
      condition: useMediaQuery((theme: Theme) => theme.breakpoints.down('xs')),
    },
  ]

  const menuRender = (item) => {
    if (item.role && !(permissions ?? []).includes(item.role)) return null
    if (typeof item.condition !== 'undefined' && !item.condition) return null

    return item.toggle ? (
      <SubMenu
        key={item.key || item.toggle}
        handleToggle={() => handleToggle(item.toggle)}
        isOpen={state[item.toggle]}
        sidebarIsOpen={open}
        name={`resources.${item.toggle}.menu`}
        icon={item.icon}
        dense={dense}
      >
        {item.children.map(menuRender)}
      </SubMenu>
    ) : (
      <MenuItemLink
        key={item.key || item.resource}
        to={`/${item.resource}`}
        primaryText={translate(item.text || `resources.${item.resource}.title`)}
        leftIcon={item.icon}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />
    )
  }

  return loaded ? (
    <Box mt={1}>
      {' '}
      <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
      {menu.map(menuRender)}
    </Box>
  ) : (
    <LinearProgress />
  )
}

export default Menu
