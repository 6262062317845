import { FormControl, Grid, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core'
import { get, set } from 'lodash'
import React, { useEffect, useState } from 'react'
import {
  ArrayInput,
  FormDataConsumer,
  Loading,
  ReferenceInput,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  useLocale,
  useTranslate,
} from 'react-admin'
import { useForm } from 'react-final-form'
import { AutocompleteTextInput, CompactRemoveButton, DynamicInput } from '~/components'
import { Categories, Sections } from '~/graphql/generated'
import { notesIconChoices } from '~/types/choices'
import './UpsertBoatAttributes.css'

const useStyles = makeStyles((theme) => ({
  disguisedInput: {
    [`& .MuiInputBase-root`]: {
      background: 'none',
    },
    [`& .MuiFilledInput-underline:before`]: {
      border: 'none',
    },
    [`& .MuiFormLabel-root.Mui-disabled`]: {
      color: theme.palette.text.primary,
    },
    [`& .MuiInputBase-root.Mui-disabled`]: {
      color: theme.palette.text.primary,
    },
    [`& .MuiInputBase-input.Mui-disabled::placeholder`]: {
      color: theme.palette.text.primary,
    },
  },
}))

const AttributesFromCategory = ({
  category,
  formData,
  source,
  guided,
  ...rest
}: {
  category: Categories
  formData: any
  source: string
  guided: boolean
}) => {
  const { change } = useForm()
  // const { values } = useFormState()
  const { attributes } = category

  if (get(formData, source) === undefined) {
    const default_attributes = attributes
      .filter((a) => !a.hidden)
      .map((a) => ({ attribute_id: a.id, value: a.default_value }))
    set(formData, source, default_attributes)
  }

  const handleAttributeSelected = (event) => {
    const attribute_id = event.target.value
    const attribute = attributes.find((a) => a.id === attribute_id)
    change(
      [...event.target.name.split('.').slice(0, -1), 'value'].join('.'),
      attribute.default_value
    )
  }

  return (
    <ArrayInput {...rest} source={source} label='' className='boat-attributes'>
      <SimpleFormIterator
        TransitionProps={{ classNames: '' }}
        removeButton={<CompactRemoveButton />}
        disableReordering
        disableRemove={guided}
        disableAdd={guided}
        getItemLabel={() => ''}
      >
        {!guided && (
          <ReferenceInput
            source='attribute_id'
            reference='attributes'
            label='resources.attributes.fields.name'
            filter={{ category_id: category.id }}
            onChange={handleAttributeSelected}
          >
            <SelectInput optionText='name' />
          </ReferenceInput>
        )}
        <FormDataConsumer>
          {({ scopedFormData: { attribute_id } = {}, getSource, ...rest }) => {
            const attribute = attributes.find((a) => a.id === attribute_id)
            const valueSource = getSource('value')

            return attribute ? (
              <DynamicInput attribute={attribute} source={valueSource} standalone={guided} />
            ) : null
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  )
}

const AddBoatCategorySelection = ({
  source,
  categories,
  ...rest
}: {
  source: string
  categories: Categories[]
}) => {
  const translate = useTranslate()
  const { change, getState } = useForm()
  const { values } = getState()
  const [categoryId, setCategory] = useState('')

  useEffect(() => {
    if (categoryId === '') return

    change(source, [
      ...(get(values, source) ?? []),
      {
        category_id: categoryId,
      },
    ])
    setCategory('')
  }, [categoryId, setCategory, change])

  return (
    <FormControl variant='outlined' margin='dense'>
      <InputLabel id='category-label'>{translate('resources.categories.add')}</InputLabel>
      <Select
        labelId='category-label'
        onChange={(e) => setCategory(e.target.value)}
        value={categoryId}
      >
        {Object.values(categories).map((category) => (
          <MenuItem key={category.id} value={category.id}>
            {category.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

const UpsertBoatSection = ({
  section,
  formData,
  source,
  guided,
  showNotes,
  ...rest
}: UpsertBoatSectionProps) => {
  const { change } = useForm()
  // const { values } = useFormState()
  const { categories } = section
  const locale = useLocale()
  const classes = useStyles()

  if (get(formData, source) === undefined) {
    const default_categories = categories
      //.filter((a) => a.show_by_default)
      .map((c) => ({ category_id: c.id }))
    set(formData, source, default_categories)
  }

  const handleCategorySelected = (event) => {
    const category_id = event.target.value
    const category = categories.find((c) => c.id === category_id)
    // change(
    //   [...event.target.name.split('.').slice(0, -1), 'value'].join('.'),
    //   category.default_value
    // )
  }

  const handleMultilineKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.stopPropagation()
    }
  }

  return (
    <div>
      <ArrayInput source={source} label='' className='boat-categories'>
        <SimpleFormIterator
          disableAdd
          disableReordering
          {...(showNotes ? { removeButton: <CompactRemoveButton /> } : {})}
        >
          <FormDataConsumer>
            {({ scopedFormData: { category_id } = {}, getSource, ...rest }) => {
              if (!category_id) {
                return null
              }

              //const { data, loading } = useGetOne('categories', category_id)
              const data = categories.find((c) => c.id === category_id)
              const loading = false

              const nameSource = getSource('name')

              return loading ? (
                <Loading />
              ) : (
                <Grid container spacing={1}>
                  {guided ? (
                    <Grid item md={4}>
                      {/* <Typography component="span" variant="body1">
                        {data?.translated_name?.[locale] || data?.name}
                      </Typography> */}
                      <TextInput
                        source={nameSource}
                        label={data?.translated_name?.[locale] || data?.name}
                        className={classes.disguisedInput}
                        disabled
                      />
                    </Grid>
                  ) : (
                    <Grid item md={4}>
                      <AutocompleteTextInput
                        source={nameSource}
                        label={data?.translated_name?.[locale] || data?.name}
                        where={{ category_id: category_id }}
                      />
                    </Grid>
                  )}
                  {guided ? (
                    <Grid item md={5}>
                      <TextInput
                        source={getSource('description')}
                        placeholder={data.translated_description?.[locale] || data.description}
                        InputLabelProps={{ shrink: true }}
                        label={'resources.categories.fields.description'}
                        className={classes.disguisedInput}
                        disabled
                      />
                    </Grid>
                  ) : (
                    <Grid item md={5}>
                      <TextInput
                        source={getSource('description')}
                        placeholder={data.translated_description?.[locale] || data.description}
                        InputLabelProps={data.description ? { shrink: true } : {}}
                        label={'resources.categories.fields.description'}
                      />
                    </Grid>
                  )}
                  <Grid item md={3}>
                    <SelectInput
                      style={{ width: 'auto', float: 'right' }}
                      source={getSource('notes_icon')}
                      label='resources.categories.notes_icon'
                      choices={notesIconChoices}
                      translateChoice={false}
                      allowEmpty
                      optionText={(value) => React.createElement(value.icon, { fontSize: 'small' })}
                    />
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item lg={showNotes ? 8 : 12}>
                      <AttributesFromCategory
                        category={data}
                        source={getSource('boat_section_attribute')}
                        label='resources.attributes.title'
                        guided={guided}
                        {...rest}
                      />
                    </Grid>
                    {showNotes && (
                      <Grid item lg={4}>
                        <TextInput
                          source={getSource('notes')}
                          label='resources.categories.notes'
                          multiline
                          minRows={4}
                          onKeyPress={handleMultilineKeyPress}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
      {true && <AddBoatCategorySelection categories={categories} source={source} />}
    </div>
  )
}

export interface UpsertBoatSectionProps {
  section: Sections
  formData: any
  source: string
  guided: boolean
  showNotes: boolean
}

export default UpsertBoatSection
