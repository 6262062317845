import { ShowButton, TopToolbar } from 'react-admin'
import { Route } from 'react-router-dom'

const TabActions = ({
  actions,
  basePath,
  data,
  resource,
}: {
  actions: object
  basePath?: string
  data?: object
  resource: object
}) => {
  return (
    <TopToolbar>
      <Route
        exact
        path={`/${resource}/:id/:tab_index`}
        render={({ match }) => actions[match.params.tab_index]}
      />
      <ShowButton basePath={basePath} record={data} />
    </TopToolbar>
  )
}

export default TabActions
