import get from 'lodash/get'
import React from 'react'
import { BooleanInput, FormDataConsumer } from 'react-admin'

const FormattedBooleanInput = ({ format, parse, source, options, ...props }) => (
  <FormDataConsumer>
    {({ formData, ...rest }) => (
      <BooleanInput
        options={{
          checked: format(get(formData, source)),
          ...options,
        }}
        format={format}
        parse={parse}
        source={source}
        {...props}
      />
    )}
  </FormDataConsumer>
)

FormattedBooleanInput.defaultProps = {
  options: {},
}

export default FormattedBooleanInput
