import React from 'react'
import { Edit, SimpleForm } from 'react-admin'
import { UpsertCustomer } from '~/components'

export const CustomersEdit = (props: object) => {
  return (
    <Edit mutationMode='undoable' {...props}>
      <SimpleForm warnWhenUnsavedChanges submitOnEnter={true} redirect='show'>
        <UpsertCustomer {...props} />
      </SimpleForm>
    </Edit>
  )
}

export default CustomersEdit
