import { useQuery } from '@apollo/client'
import { Typography, TypographyProps } from '@material-ui/core'
import get from 'lodash/get'
import * as React from 'react'
import { FC, memo } from 'react'
import {
  InjectedFieldProps,
  LinearProgress,
  PublicFieldProps,
  sanitizeFieldRestProps,
  useLocale,
  useRecordContext,
  useResourceContext,
} from 'react-admin'
import { selectionChoicesQuery } from '~/helpers'

export const SelectionField: FC<SelectionFieldProps> = memo((props) => {
  const { className, emptyText, ...rest } = props
  const locale = useLocale()
  const record = useRecordContext(props)
  const value = get(record, props.source)
  let resource = useResourceContext()

  let source = props.source || ''
  const splitSourceArray = source.split(/\[\d+\]\./)
  if (splitSourceArray.length > 1) {
    resource = splitSourceArray[splitSourceArray.length - 2]
    source = splitSourceArray[splitSourceArray.length - 1]
  }
  const splitSource = source.split('.')
  if (splitSource.length > 1) {
    source = splitSource[splitSource.length - 1]
  }

  const { data, loading } = useQuery(selectionChoicesQuery({ resource, source, locale }))
  const choice = (data ? data.choices : [])
    .map((data) => ({
      id: data.key,
      name: data.translated_name || data.key,
    }))
    .find((c) => c.id === value)

  if (!loading && !choice) {
    return emptyText ? (
      <Typography
        component='span'
        variant='body2'
        className={className}
        {...sanitizeFieldRestProps(rest)}
      >
        {emptyText}
      </Typography>
    ) : null
  }

  let choiceText = choice?.name

  return loading ? (
    <LinearProgress />
  ) : (
    <Typography
      component='span'
      variant='body2'
      className={className}
      {...sanitizeFieldRestProps(rest)}
    >
      {choiceText}
    </Typography>
  )
})

SelectionField.defaultProps = {
  addLabel: true,
}

export interface SelectionFieldProps
  extends PublicFieldProps,
    InjectedFieldProps,
    TypographyProps {}

SelectionField.displayName = 'SelectionField'

export default SelectionField
