import React from 'react'
import {
  Datagrid,
  DateField,
  EditButton,
  Filter,
  List,
  TextField,
  TextInput,
  useLocale,
} from 'react-admin'

const SectionsFilter = (props: object) => (
  <Filter {...props}>
    <TextInput label='pos.search' source='name' alwaysOn />
  </Filter>
)

const SectionList = (props: object) => {
  const locale = useLocale()
  return (
    <List
      title='resources.sections.title'
      filters={<SectionsFilter />}
      bulkActionButtons={false}
      sort={{ field: 'position', order: 'ASC' }}
      perPage={25}
      {...props}
    >
      <Datagrid rowClick='edit'>
        <TextField source='position' label='#' />
        {/* <TextField source='slug' label='resources.sections.fields.slug' /> */}
        <TextField source={`translated_name.${locale}`} label='resources.sections.fields.name' />
        <TextField
          source='categories_aggregate.aggregate.count'
          label='resources.sections.fields.category_count'
        />
        {/* <FunctionField
          source='categories'
          label='resources.sections.fields.category_count'
          render={({categories}) => categories ? categories.length : ''}
        /> */}
        <TextField source='history[0].user.display_name' label='history.modified_by' />
        <DateField source='history[0].time' showTime label='history.last_modified' />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export default SectionList
