import { Grid } from '@material-ui/core'
import React from 'react'
import { email, number, required, SelectInput, TextInput } from 'react-admin'

/*
const choices = [
  { id: 'boat engine service', name: 'Boat Engine Service' },
  { id: 'underwater ship grinding', name: 'Underwater Ship Grinding' },
  { id: 'rig service', name: 'Rig Service' },
  { id: 'sailmaker', name: 'Sailmaker' },
]
*/

const CompanyInfo = (props) => (
  <>
    <Grid item xs={6}>
      <TextInput fullWidth source='name' validate={[required()]} label='Company Name' />
    </Grid>

    <Grid item xs={6}>
      <TextInput fullWidth source='telephone' validate={[required()]} label='telephone' />
    </Grid>

    <Grid item xs={6}>
      <TextInput fullWidth source='website' validate={[required()]} label='website' />
    </Grid>

    <Grid item xs={6}>
      <TextInput fullWidth source='email' validate={[required(), email()]} label='email' />
    </Grid>

    <Grid item xs={12}>
      <SelectInput fullWidth source='services' label='Services' choices={props.services} />
    </Grid>

    <Grid item xs={4}>
      <TextInput
        fullWidth
        source='contact_person_name'
        validate={[required()]}
        label='contact_person_name'
      />
    </Grid>

    <Grid item xs={4}>
      <TextInput
        fullWidth
        source='contact_person_email'
        validate={[email(), required()]}
        label='contact_person_email'
      />
    </Grid>

    <Grid item xs={4}>
      <TextInput
        fullWidth
        source='contact_person_phone'
        validate={[number(), required()]}
        label='contact_person_phone'
      />
    </Grid>
  </>
)

const AddressInfo = (props) => (
  <>
    <Grid item xs={9}>
      <TextInput
        fullWidth
        source='street'
        validate={[required()]}
        label='resources.customers.fields.street'
      />
    </Grid>

    <Grid item xs={3}>
      <TextInput fullWidth source='postal_code' label='resources.customers.fields.postal_code' />
    </Grid>

    <Grid item xs={6}>
      <TextInput fullWidth source='city' label='resources.customers.fields.city' />
    </Grid>

    <Grid item xs={6}>
      <TextInput fullWidth source='country' label='resources.customers.fields.country' />
    </Grid>
  </>
)

/* const ContactInfo = (props) => (
  <>
    <Grid item md={12} lg={6}>
      <ArrayInput source='emails'>
        <SimpleFormIterator>
          <TextInput source='type' label='Typ' validate={[required()]} />
          <TextInput source='email' label='Email' validate={[required()]} />
        </SimpleFormIterator>
      </ArrayInput>
    </Grid>
    <Grid item md={12} lg={6}>
      <ArrayInput source='phones'>
        <SimpleFormIterator>
          <TextInput source='type' label='Typ' validate={[required()]} />
          <TextInput source='number' label='Nummer' validate={[required()]} />
        </SimpleFormIterator>
      </ArrayInput>
    </Grid>
  </>
) */

const UpsertSupplier = (props) => (
  <Grid container spacing={10}>
    <Grid item lg={8}>
      <Grid container spacing={3}>
        <CompanyInfo {...props} />
        <AddressInfo {...props} />
        {/* <ContactInfo {...props} /> */}
      </Grid>
    </Grid>
    {/* <Grid item lg={4}>
      <ArrayInput source='customer_customer' label='Beziehungen'>
        <SimpleFormIterator>
          <RelatedCustomer
            source='related_customer_id'
            reference='customers'
            label='Kunde'
            id={props.id}
            validate={[required()]}
          />
          <TextInput source='relation_type' label='Relationstyp' validate={[required()]} />
        </SimpleFormIterator>
      </ArrayInput>
    </Grid> */}
  </Grid>
)

export default UpsertSupplier
