import { Grid } from '@material-ui/core'
import { PDFViewer } from '@react-pdf/renderer'
import isFunction from 'lodash/isFunction'
import React from 'react'
import {
  AutocompleteArrayInput,
  FormDataConsumer,
  ReferenceArrayInput,
  SelectInput,
  TextInput,
  useGetMany,
  useLocale,
} from 'react-admin'
import { useFormState } from 'react-final-form'
import { BrokerageAgreementDoc, CreateCustomer } from '~/components'
// import './UpsertDocument.css'
import { documentTypeChoices } from '~/types/choices'
import { DefaultAccessor, documentDefinitions } from '~/types/documents'

const UpsertDocument = ({ record, showPreview }) => {
  const locale = useLocale()
  const { values } = useFormState()
  const customerIds =
    values.document_customer?.filter((dc) => dc.customer_id).map((dc) => dc.customer_id) || []
  const { data: customers, loading: customersLoading } = useGetMany('customers', customerIds)
  const boatIds = values.document_boat?.filter((db) => db.boat_id).map((db) => db.boat_id) || []
  const { data: boats, loading: boatsLoading } = useGetMany('boats', boatIds)

  const handleTypeChange = ({ target }) => {
    // if (target.value) {
    //   change('title', null)
    // }
  }

  return (
    <Grid container spacing={2}>
      <Grid item md={2}>
        <SelectInput
          source='type'
          choices={documentTypeChoices}
          label='resources.documents.fields.type'
          fullWidth
          onChange={handleTypeChange}
        />
      </Grid>
      <Grid item md={5}>
        <FormDataConsumer>
          {({ formData }) => (
            <ReferenceArrayInput
              source='document_customer'
              reference='customers'
              label='resources.documents.fields.document_customer'
              format={(rels) => (rels ? rels.map((rel) => rel.customer_id) : [])}
              parse={(ids, source) =>
                ids.map(
                  (id) =>
                    formData[source]?.find((rel) => rel.customer_id === id) || { customer_id: id }
                )
              }
              enableGetChoices={({ q }) => (q ? q.length >= 1 : false)}
            >
              <AutocompleteArrayInput
                optionText={(record) =>
                  record && record.id === '@@ra-create'
                    ? record.name
                    : `${record.firstname} ${record.lastname}`
                }
                shouldRenderSuggestions={(value) => value.length >= 1}
                create={<CreateCustomer />}
              />
            </ReferenceArrayInput>
          )}
        </FormDataConsumer>
      </Grid>
      <Grid item md={5}>
        <FormDataConsumer>
          {({ formData }) => (
            <ReferenceArrayInput
              source='document_boat'
              reference='boats'
              label='resources.documents.fields.document_boat'
              format={(rels) => (rels ? rels.map((rel) => rel.boat_id) : [])}
              parse={(ids, source) =>
                ids.map(
                  (id) => formData[source]?.find((rel) => rel.boat_id === id) || { boat_id: id }
                )
              }
              enableGetChoices={({ q }) => (q ? q.length >= 1 : false)}
            >
              <AutocompleteArrayInput
                optionText={(record) => record && `${record.model} (${record.name})`}
                shouldRenderSuggestions={(value) => value.length >= 1}
              />
            </ReferenceArrayInput>
          )}
        </FormDataConsumer>
      </Grid>

      <Grid item md={showPreview ? 6 : 12}>
        <Grid container spacing={1}>
          <FormDataConsumer>
            {({ formData }) => {
              const docDef = formData.type ? documentDefinitions[formData.type] : null
              if (!docDef) return null
              return Object.keys(docDef.fields).map((k) => {
                const fieldDef = docDef.fields[k]
                const defaultSources = { customers, boats }
                const defaultValue = isFunction(fieldDef.default)
                  ? (fieldDef.default as DefaultAccessor)(defaultSources)
                  : fieldDef.default
                return (
                  <Grid item md={showPreview ? 6 : 3} key={k}>
                    <TextInput
                      source={`values.${k}`}
                      label={`resources.documents.${formData.type}.fields.${k}`}
                      placeholder={defaultValue}
                      InputLabelProps={{ shrink: !!defaultValue || undefined }}
                    />
                  </Grid>
                )
              })
            }}
          </FormDataConsumer>
        </Grid>
      </Grid>
      {showPreview && values.type === 'brokerage_agreement' && (
        <Grid item md={6}>
          <PDFViewer
            style={{ width: '100%', height: 'auto', minHeight: '650px', maxHeight: '1000px' }}
          >
            <BrokerageAgreementDoc record={values} defaultSources={{ customers, boats }} />
          </PDFViewer>
        </Grid>
      )}

      {/* <Grid item md={6}>
        <FormDataConsumer>
          {({ formData }) => (
            <TextInput
              source='title'
              fullWidth
              label='resources.documents.fields.title'
              validate={validateTitle}
              disabled={!!formData.type}
            />
          )}
        </FormDataConsumer>
      </Grid> */}
      {/* <Grid item md={12}>
        <TextInput multiline source='notes' fullWidth label='resources.documents.fields.notes' />
      </Grid> */}
    </Grid>
  )
}

// TODO: fill and use interface
export interface DocumentDefinition {}

export default UpsertDocument
