import { useState } from 'react'
import {
  Edit,
  ListButton,
  SaveButton,
  SimpleForm,
  Toolbar,
  TopToolbar,
  useTranslate,
} from 'react-admin'
import { ToggleI18nButton, UpsertSection } from '~/components'

const SectionName = ({ record }) => {
  const translate = useTranslate()
  return (
    <span>
      {translate(`resources.sections.name`, { smart_count: 1 })}: {record ? `${record.slug}` : ''}
    </span>
  )
}

const SectionEditActions = ({ basePath, data, showI18n, setShowI18n }) => (
  <TopToolbar>
    <ToggleI18nButton showI18n={showI18n} setShowI18n={setShowI18n} />
    <ListButton basePath={basePath} record={data} label='ra.action.back' />
  </TopToolbar>
)

const SectionEditToolbar = ({
  handleSubmitWithRedirect,
  handleSubmit,
  disabled,
  invalid,
  redirect,
  saving,
  validating,
  submitOnEnter,
}) => (
  <Toolbar>
    <SaveButton
      handleSubmitWithRedirect={handleSubmitWithRedirect || handleSubmit}
      disabled={disabled}
      invalid={invalid}
      redirect={redirect}
      saving={saving || validating}
      submitOnEnter={submitOnEnter}
    />
  </Toolbar>
)

export const SectionEdit = (props, record) => {
  const [showI18n, setShowI18n] = useState(false)

  return (
    <Edit
      title={<SectionName />}
      actions={<SectionEditActions showI18n={showI18n} setShowI18n={setShowI18n} />}
      disable
      {...props}
    >
      <SimpleForm redirect='list' warnWhenUnsavedChanges toolbar={<SectionEditToolbar />}>
        <UpsertSection {...props} showI18n={showI18n} />
      </SimpleForm>
    </Edit>
  )
}

export default SectionEdit
