import germanMessages from 'ra-language-german'
import { TranslationMessages } from 'react-admin'

germanMessages.ra.boolean.null = 'Keine Angabe'

const customGermanMessages: TranslationMessages = {
  ...germanMessages,
  pos: {
    search: 'Suchen',
    configuration: 'Konfiguration',
    language: 'Sprache',
    theme: {
      name: 'Darstellung',
      light: 'Hell',
      dark: 'Dunkel',
      test: 'Test',
    },
    i18n: {
      show: 'Übersetzungen anzeigen',
      hide: 'Übersetzungen ausblenden',
    },
    notes: {
      show: 'Notizen anzeigen',
      hide: 'Notizen ausblenden',
    },
  },
  resources: {
    phones: {
      title: 'Telefone',
    },
    emails: {
      title: 'Emails',
    },
    customer_customer: {
      title: 'Benutzer zu Benutzer Beziehungen',
    },
    administration: {
      menu: 'Administration',
    },
    auth_accounts: {
      title: 'Benutzer',
    },
    users: {
      title: 'Benutzer',
      create: 'Benutzer hinzufügen',
      fields: {
        username: 'Benutzername',
        password: 'Passwort',
        id: 'Benutzer ID',
        role: 'Rolle',
        email: 'Email',
        active: 'Aktiviert',
      },
    },
    events: {
      title: 'History',
    },
    suppliers: {
      menu: 'Anbieter-Datenbank',
      name: 'Anbieter |||| Anbieter',
      title: 'Anbieter',
      empty: 'Es gibt noch keine Anbieter.',
      create: 'Neuen Anbieter hinzufügen',
      base: 'Stammdaten',
      fields: {
        id: 'Anbieter-ID',
        name: 'Name der Firma',
        services: 'Services',
        website: 'Website',
        email: 'Email',
        contact: {
          name: 'Contact Name',
          phone: 'Contact Phone',
          email: 'Contact Email',
        },
      },
    },
    customers: {
      menu: 'Kundendatenbank',
      name: 'Kunde |||| Kunden',
      title: 'Kunden',
      empty: 'Es gibt noch keine Kunden.',
      create: 'Neuen Kunden anlegen',
      base: 'Stammdaten',
      fields: {
        id: 'Kunden-ID',
        title: 'Titel',
        firstname: 'Vorname',
        lastname: 'Nachname',
        salutation: 'Anrede',
        role: 'Rolle',
        company: 'Firmenname',
        identity_card_number: 'Personalausweisnummer',
        nationality: 'Nationalität',
        birthday: 'Geburtstag',
        street: 'Straße und Hausnummer',
        postal_code: 'Postleitzahl',
        city: 'Ort',
        country: 'Land',
        phones: 'Telefonnummern',
        emails: 'E-Mails',
        birth_name: 'Geburtsname',
        informal_salutation: 'Du',
        relationships: 'Beziehungen',
      },
      array_fields: {
        phone: 'Nummer',
        email: 'Email',
        type: 'Typ',
      },
      types: {
        relation_to: 'Beziehungen zu',
        relation_from: 'Beziehungen durch',
        seller: 'Verkäufer',
        suitor: 'Interessent',
        buyer: 'Käufer',
        brokered: 'Interessent an Vermittlung',
        boats: 'Boote',
        boat_name: 'Bootsname',
      },
    },
    auth_roles: {
      title: 'Rollen',
    },
    roles: {
      name: 'Rolle |||| Rollen',
      title: 'Rollen',
      empty: 'Es gibt noch keine Rollen.',
      create: 'Neue Rolle anlegen',
      fields: {
        id: 'Rollen-ID',
        name: 'Name',
      },
    },
    boats: {
      menu: 'Bootdatenbank',
      name: 'Boot |||| Boote',
      title: 'Boote',
      empty: 'Es gibt noch keine Boote.',
      create: 'Neues Boot anlegen',
      base: 'Grundinformationen',
      attributes: 'Ausstattung',
      empty_attributes: 'Noch keine Ausstattung hinzugefügt',
      guided_input: 'Geführte Eingabe',
      types: {
        customers: 'Kunde',
        customer_name: 'Kunde',
      },
      fields: {
        id: 'Boot-ID',
        manufacturer: 'Hersteller',
        model: 'Modell',
        name: 'Name',
        build_year: 'Baujahr',
        first_launch: 'Erstwasserung',
        status: 'Status',
        asking_price: 'Fragpreis',
        price_reductions: 'Preissenkungen',
        lower_price_limit: 'Vertragliche Preisuntergrenze',
        summer_storage: 'Sommerlager',
        winter_storage: 'Winterlager',
        boat_type: 'Bootstyp',
        motorboat_type: 'Motorboot',
        motorboat_planing_type: 'Typ',
        sailboat_type: 'Segelboot',
        sailboat_rigging_type: 'Takelung',
        goods_type: 'Güterart',
        condition: 'Zustand',
        previous_owners: 'Vorbesitzer',
        build_number: 'Baunummer',
        ce_labeling: 'CE-Kennzeichnung',
        hull_material: 'Rumpfmaterial',
        hull_color: 'Rumpffarbe',
        keel_type: 'Kieltyp',
        lake_constance_approval: 'Bodenseezulassung',
        length_total: 'Länge ü.A. (m)',
        length_waterline: 'Länge Wasserline (m)',
        width: 'Breite (m)',
        draft_max: 'Tiefgang max. (m)',
        draft_min: 'Tiefgang min. (m)',
        displacement: 'Verdrängung (kg)',
        ballast: 'Ballast (kg)',
        customer_id: 'Besitzer',
        relationships: 'Beziehungen',
      },
      relationships: {
        type: 'Typ',
      },
      sections: {
        price: 'Preis',
        storage: 'Lagerung',
        boat_type: 'Boot Typ',
        condition: 'Konditionen',
        materials: 'Materialien',
        certificates: 'Zertifikate',
        dimensions: 'Dimensionen',
      },
      choices: {
        status: {
          processing: 'in Bearbeitung',
          mediation: 'in Vermittlung',
          handover: 'Verkauft in Übergabe',
          settlement: 'Verkauft in Abwicklung',
          completed: 'Verkauf abgeschloßen',
          cancelled: 'Maklervertrag gekündigt',
        },
        type: {
          sailboat: 'Segelyacht',
          motorboat: 'Motoryacht',
        },
        motorboat_type: {
          cabin_cruiser: 'Cabincruiser',
          fishing_boat: 'Angelboot',
          catamaran: 'Katamaran',
          hovercraft: 'Luftkissen (Hovercraft)',
          houseboat: 'Hausboot',
        },
        motorboat_plan: {
          glider: 'Gleiter',
          'semi-glider': 'Halbgleiter',
          displacer: 'Verdränger',
        },
        sailboat_type: {
          center_cockpit: 'Zentrales Cockpit',
          daysailer: 'Daysailer',
          decksalon: 'Deckssalon',
          dinghy: 'Jolle',
          catamaran: 'Katamaran',
          trimaran: 'Trimaran',
          classic: 'Klassiker',
          motor_sailer: 'Motorsegler',
          flatboat: 'Plattbodenschiff',
        },

        sailboat_rigging: {
          kat: 'Kat',
          sloop: 'Sloop',
          schooner: 'Schoner',
          ketch: 'Ketsch',
          yawl: 'Yawl',
          gaff: 'Gaffel',
          cutter: 'Kutter',
        },

        usedboat: {
          used: 'Gebrauchtboot',
          new: 'Neuboot',
          display: 'Vorführmodell',
        },

        boat_condition: {
          tinkerware: 'Bastlerboot',
          casco: 'Kasko',
          used: 'gebraucht',
          good: 'gut',
          very_good: 'sehr gut',
          like_new: 'neuwertig',
          new: 'neu',
        },
        boat_labels: {
          a: 'A - Hochsee',
          b: 'B - Außerhalb von Küstengewässern',
          c: 'C - Küstennahe Gewässer',
          d: 'D - Geschützte Gewässer',
        },
      },
    },
    sections: {
      title: 'Bereiche',
      name: 'Bereich |||| Bereiche',
      add: 'Bereich hinzufügen',
      fields: {
        slug: 'Eindeutiger Name',
        name: 'Name',
        position: 'Position',
        category_count: '# Kategorien',
      },
    },
    categories: {
      title: 'Ausstattung',
      name: 'Kategorie |||| Kategorien',
      add: 'Ausstattung hinzufügen',
      empty: 'Es gibt noch keine Ausstattung.',
      create: 'Neue Ausstattung anlegen',
      notes: 'Notizen',
      notes_icon: 'Eingabestatus',
      fields: {
        id: 'Kategorie-ID',
        name: 'Name',
        section: 'Bereich',
        description: 'Beschreibung',
        attributes: 'Mögliche Attribute',
        attribute_count: '# Attribute',
      },
    },
    attributes: {
      title: 'Ausstattungsteile',
      save_first: '» Neu hinzugefügte Option zuerst speichern «',
      hint_switching_input_type:
        'Hinweis: Das Attribut "%{attribute}" ist bereits in Verwendung, eine Änderung des Eingabetyps könnte zu Problem führen.',
      fields: {
        id: 'Attribut-ID',
        name: 'Name',
        description: 'Beschreibung',
        default_value: 'Standardwert',
        suffix: 'Suffix',
        unit: 'Einheit',
        input_type: 'Eingabetyp',
        show_by_default: 'Immer anzeigen',
        hidden: 'versteckt',
        choices: 'Auswahl',
        selection: 'Auswahliste',
      },
      types: {
        text: 'Text',
        autocomplete: 'Autovervollst.',
        numeric: 'Zahl',
        boolean: 'Ja / Nein',
        dropdown: 'Liste',
        shared_dropdown: 'Geteilte Liste',
      },
    },
    selections: {
      title: 'Auswahllisten',
      add: 'Auswahlliste hinzufügen',
      empty: 'Es gibt noch keine Auswahlliste.',
      create: 'Neue Auswahlliste anlegen',
      fields: {
        id: 'Listen-ID',
        name: 'Name (Intern)',
        section: 'Bereich',
        context: 'Kontext',
        predefined: 'vorgegeben',
        choices: 'Auswahlmöglichkeiten',
        choice_count: '# Auswahlmöglichkeiten',
      },
      contexts: {
        attributes: {
          choices: 'Austattungs-Attribute',
        },
      },
    },
    choices: {
      create: 'Neue Auswahlmöglichkeit',
      create_item: '"%{item}" hinzufügen',
      fields: {
        key: 'Eindeutiger Name',
        name: 'Name',
      },
    },
    comments: {
      name: 'Kommentar |||| Kommentare',
      empty: 'Noch keine Komentare.',
      title: 'Kommentare',
      create: 'Kommentieren',
      update: 'Ändern',
      remove: 'Entfernen',
      loading: 'Kommentare werden geladen...',
      fields: {
        comment: 'Kommentar',
        type: 'Typ',
        linked_type: 'Verknüpfung zu',
      },
      linked_types: {
        boats: 'Boot',
        customers: 'Kunde',
      },
    },
    attachments: {
      name: 'Anhang |||| Anhänge',
      empty: 'Noch keine Anhänge.',
      title: 'Anhänge',
      create: 'Anhang erstellen',
      upload: 'Hochladen',
      uploaded: 'Anhang wurde hochgeladen.',
      deleted: 'Anhang wurde gelöscht.',
      loading: 'Anhange werden geladen...',
      fields: {
        type: 'Typ',
        caption: 'Untertitel',
      },
    },
    appointments: {
      name: 'Termin |||| Termine',
      menu: 'Terminverwaltung',
      title: 'Termine',
      create: 'Neuen Termin anlegen',
      empty: 'Es gibt noch keine Termine.',
      calendar_view: 'Kalenderansicht',
      list_view: 'Listenansicht',
      new: 'Neu',
      fields: {
        title: 'Titel',
        start: 'Start',
        end: 'Ende',
        type: 'Typ',
        appointment_customer: 'Kunden',
        appointment_boat: 'Boote',
        allDay: 'ganztägig',
        location: 'Ort',
        notes: 'Notizen',
      },
      types: {
        none: 'Keinen (Sonstiges)',
        viewing: 'Besichtigung',
        handover: 'Übergabe',
        pickup: 'Abholung',
      },
    },
    documents: {
      name: 'Dokument |||| Dokumente',
      menu: 'Dokumentenverwaltung',
      title: 'Dokumente',
      create: 'Neues Dokument anlegen',
      empty: 'Es gibt noch keine Dokumente.',
      new: 'Neu',
      fields: {
        title: 'Titel',
        type: 'Typ',
        document_customer: 'Kunde(n)',
        document_boat: 'Boot(e)',
        notes: 'Notizen',
      },
      types: {
        none: 'Keinen (Sonstiges)',
        brokerage_agreement: 'Maklervertrag',
      },
      brokerage_agreement: {
        fields: {
          customer_names: 'Kundenname(n)',
          customer_street: 'Straße/Hausnr.',
          customer_postcity: 'Postleitzahl/Ort',
          customer_phone: 'Telefon privat/mobil',
          customer_email: 'E-Mail Adresse',
          start_date: 'Zur verfügung gestellt ab',
          boat_name: 'Bootsname',
          boat_manufacturer: 'Hersteller',
          boat_model: 'Typ',
          boat_weight: 'Gewicht',
          boat_registry: 'registriert bei/unter',
          boat_build_year: 'Baujahr',
          boat_build_number: 'Baunummer',
          boat_length_total: 'Länge ü.A. (m)',
          boat_width: 'Breite (m)',
          boat_draft: 'Tiefgang (m)',
          motor_manufacturer: 'Motorhersteller',
          motor_type: 'Motortyp',
          motor_number: 'Motornummer',
          motor_build_year: 'Baujahr Motor',
          operating_hours: 'Betriebsstunden',
          other: 'Sonstiges',
        },
      },
    },
    relations: {
      add: 'Relation hinzufügen',
      types: {
        seller: 'Verkäufer',
        suitor: 'Interessenten',
        buyer: 'Käufer',
        brokered: 'Vermittlungen',
      },
    },
    history: {
      title: 'Geschichte',
      fields: {
        relation: 'Relation',
        operation: 'Aktion',
        time: 'Zeit',
      },
      operations: {
        INSERT: 'Hinzufügung',
        DELETE: 'Löschung',
        UPDATE: 'Änderung',
      },
    },
    history_transactions: {
      title: 'Geschichte',
    },
  },
  general: {
    homepage_content: 'Hier kommt eine uebersicht ueber alle Kunden, Boote, etc...',
  },
  validation: {
    startEnd: 'Muss hinter Start liegen',
  },
  history: {
    modified_by: 'Geändert von',
    last_modified: 'Letzte Änderung',
  },
}

export default customGermanMessages
