import get from 'lodash/get'
import { Boats, Customers } from '~/graphql/generated'

const customerNames: DefaultAccessor = ({ customers }) => {
  if (!customers || customers.length < 1) return ''
  return customers.map((c) => c && `${c.firstname} ${c.lastname}`).join(' & ')
}

const firstCustomerProp =
  (propPath: string): DefaultAccessor =>
  ({ customers }) => {
    if (!customers || customers.length < 1 || !customers[0]) return ''
    return get(customers[0], propPath)
  }

const firstCustomerProps =
  (propPaths: string[]): DefaultAccessor =>
  ({ customers }) => {
    if (!customers || customers.length < 1 || !customers[0]) return ''
    return propPaths.map((propPath) => get(customers[0], propPath)).join(' ')
  }

const firstBoatProp =
  (propPath: string): DefaultAccessor =>
  ({ boats }) => {
    if (!boats || boats.length < 1 || !boats[0]) return ''
    return get(boats[0], propPath)
  }

export const brokerageAgreement: DocumentDefinition = {
  fields: {
    customer_names: {
      default: customerNames,
    },
    customer_street: {
      default: firstCustomerProp('street'),
    },
    customer_postcity: {
      default: firstCustomerProps(['postal_code', 'city']),
    },
    customer_phone: {
      default: firstCustomerProp('phones[0].number'),
    },
    customer_email: {
      default: firstCustomerProp('emails[0].email'),
    },
    start_date: {
      default: 'sofort',
    },
    boat_name: {
      default: firstBoatProp('name'),
    },
    boat_manufacturer: {
      default: firstBoatProp('manufacturer'),
    },
    boat_model: {
      default: firstBoatProp('model'),
    },
    boat_build_year: {
      default: firstBoatProp('build_year'),
    },
    boat_width: {
      default: firstBoatProp('width'),
    },
    boat_length_total: {
      default: firstBoatProp('length_total'),
    },
    boat_build_number: {
      default: firstBoatProp('build_number'),
    },
    boat_draft: {
      default: firstBoatProp('draft_max'),
    },
    motor_type: { default: '' },
    boat_weight: { default: '' },
    boat_registry: { default: '' },
    motor_number: { default: '' },
    motor_build_year: { default: '' },
    operating_hours: { default: '' },
    other: { default: '' },
  },
}

export const documentDefinitions: DocumentDefinitions = {
  brokerage_agreement: brokerageAgreement,
}

export interface DefaultSources {
  customers: Customers[]
  boats: Boats[]
}

export interface DefaultAccessor {
  (params: DefaultSources): string
}

export interface DocumentDefinition {
  fields: {
    [fieldKey: string]: {
      default: DefaultAccessor | string
    }
  }
}

export interface DocumentDefinitions {
  [docKey: string]: DocumentDefinition
}
