import { useState } from 'react'
import { Edit, ListButton, SaveButton, SimpleForm, Toolbar, TopToolbar } from 'react-admin'
import { ToggleI18nButton, UpsertSelection } from '~/components'

const SelectionName = ({ record }: { record?: { name: string } }) => {
  return <span>Selection: {record ? `${record.name}` : ''}</span>
}

const SelectionEditActions = ({ basePath, data, showI18n, setShowI18n }) => (
  <TopToolbar>
    <ToggleI18nButton showI18n={showI18n} setShowI18n={setShowI18n} />
    <ListButton basePath={basePath} record={data} label='ra.action.back' />
  </TopToolbar>
)

export const SelectionEdit = (props: object, record: object) => {
  const [showI18n, setShowI18n] = useState(true)

  const SelectionEditToolbar = (props) => {
    return props.record.predefined ? (
      <Toolbar {...props}>
        <SaveButton disabled={props.pristine} />
      </Toolbar>
    ) : (
      <Toolbar {...props} />
    )
  }

  return (
    <Edit
      title={<SelectionName />}
      actions={<SelectionEditActions showI18n={showI18n} setShowI18n={setShowI18n} />}
      {...props}
    >
      <SimpleForm redirect='list' warnWhenUnsavedChanges toolbar={<SelectionEditToolbar />}>
        <UpsertSelection {...props} showI18n={showI18n} />
      </SimpleForm>
    </Edit>
  )
}

export default SelectionEdit
