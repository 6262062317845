import { Document, Font, Image, Page, Rect, StyleSheet, Svg, Text, View } from '@react-pdf/renderer'
import get from 'lodash/get'
import isFunction from 'lodash/isFunction'
import mapValues from 'lodash/mapValues'
import React, { useContext } from 'react'
import { TranslationContext } from 'react-admin'
import {
  brokerageAgreement as documentDefinition,
  DefaultAccessor,
  DefaultSources,
  DocumentDefinition,
} from '~/types/documents'

const styles = StyleSheet.create({
  page: {
    paddingTop: 23,
    paddingLeft: 35,
    paddingRight: 20,
    backgroundColor: '#FFFFFF',
    fontSize: 10,
    lineHeight: 1.45,
    fontFamily: 'GillSans',
  },
  logo: {
    position: 'absolute',
    top: 18,
    left: 60,
    width: 50,
    height: 50,
  },
  title: {
    marginRight: 20,
    fontSize: 14,
    fontFamily: 'GillSans',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  subtitle: {
    marginRight: 20,
    textAlign: 'center',
    fontSize: 11,
    marginBottom: 11,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  bullet: {
    height: '100%',
  },
  noColumn: {
    margin: '0 40 0 20',
  },
  columns: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: '0 10',
  },
  columnContactLeft: {
    margin: 0,
    padding: '0 10',
    flex: '0 0 40%',
  },
  columnContactRight: {
    margin: 0,
    padding: '0 10',
    flex: '0 0 60%',
  },
  columnBoatLeft: {
    margin: 0,
    padding: '0 10',
    flex: '0 0 50%',
  },
  columnBoatRight: {
    margin: 0,
    padding: '0 10',
    flex: '0 0 50%',
  },
  columnLeft: {
    margin: 0,
    padding: '0 10',
    flex: '0 0 50%',
  },
  columnRight: {
    margin: 0,
    padding: '0 10',
    flex: '0 0 50%',
  },
  emph: {
    fontFamily: 'GillSans',
    fontWeight: 'bold',
    lineHeight: 1.6,
  },
  fields: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: 0,
  },
  fieldLabels: {
    margin: 0,
    paddingRight: 2,
    flex: '2',
  },
  fieldValues: {
    margin: 0,
    flex: '3',
  },
  paragraph: {
    margin: '3 0',
    position: 'relative',
  },
  paragraphNo: {
    position: 'absolute',
    left: -15,
  },
  colParagraphNo: {
    position: 'absolute',
    left: -5,
  },
  rightBox: {
    position: 'absolute',
    right: 25,
  },
  signatures: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: '20 20',
    justifyContent: 'space-between',
  },
})

const ListItem = ({ children, noBullet = false }) => {
  return (
    <View style={styles.row}>
      <View style={{ ...styles.bullet, color: noBullet ? 'white' : null }}>
        <Text>{'\u2022' + ' '}</Text>
      </View>
      <Text>{children}</Text>
    </View>
  )
}

const translated = (record, prop, locale) => {
  return get(record, `translated_${prop}.${locale}`) || record[prop]
}

const docValues = (record, docDef: DocumentDefinition, defaultSources: DefaultSources) => {
  return (
    mapValues(
      docDef.fields,
      (fieldDef, fieldKey) =>
        record?.values?.[fieldKey] ||
        (isFunction(fieldDef.default)
          ? (fieldDef.default as DefaultAccessor)(defaultSources)
          : fieldDef.default)
    ) || ''
  )
}

const BrokerageAgreementDoc = ({ record, locale = 'de', defaultSources }) => {
  // Workaround to "renew" locale value of TranslationContext
  // which apparently is lost within the Document tree otherwise
  // i18nProvider probably has to be bridged as well if needed
  // Also see: https://github.com/facebook/react/issues/17275
  const translation = useContext(TranslationContext)
  const translationValue = { ...translation, locale: locale }

  const {
    customer_names,
    customer_street,
    customer_postcity,
    customer_phone,
    customer_email,
    start_date,
    boat_name,
    boat_manufacturer,
    boat_model,
    boat_build_year,
    boat_build_number,
    boat_length_total,
    boat_width,
    boat_draft,
    boat_weight,
    boat_registry,
    motor_manufacturer,
    motor_type,
    motor_number,
    motor_build_year,
    operating_hours,
    other,
  } = docValues(record, documentDefinition, defaultSources)

  // TODO: should be possible to import font files directly in code, doesn't work though
  Font.register({
    family: 'GillSans',
    fonts: [
      { src: `${process.env.PUBLIC_URL}/fonts/GillSans.ttf` },
      { src: `${process.env.PUBLIC_URL}/fonts/GillSans-Bold.ttf`, fontWeight: 600 },
    ],
  })

  return (
    <Document>
      <TranslationContext.Provider value={translationValue}>
        <Page size='A4' style={styles.page}>
          <Image style={styles.logo} src={`${process.env.PUBLIC_URL}/apple-touch-icon.png`} />
          <Text style={styles.title}>Vermittlungsvertrag über ein Gebrauchtboot</Text>
          <Text style={styles.subtitle}>- zur Verwendung gegenüber Privatpersonen -</Text>
          <View style={styles.columns}>
            <View style={styles.columnContactLeft}>
              <Text style={styles.emph}>Zwischen</Text>
              <Text>yachthandel24.de / ALC - Yachting GmbH</Text>
              <Text>Rüdiger Sommerfeld</Text>
              <Text>Am Hafen 23</Text>
              <Text>24376 Kappeln</Text>
              <Text>Tel.: 04642 965959</Text>
              <Text>als Vermittler</Text>
            </View>
            <View style={styles.columnContactRight}>
              <Text style={styles.emph}>und</Text>
              <View style={styles.fields}>
                <View style={styles.fieldLabels}>
                  <Text>Herrn/Frau</Text>
                  <Text>Straße/Hausnr.</Text>
                  <Text>Postleitzahl/Ort</Text>
                  <Text>Telefon privat/mobil</Text>
                  <Text>E-Mail Adresse</Text>
                  <Text>Bankverbindung (IBAN)</Text>
                </View>
                <View style={styles.fieldValues}>
                  <Text>{customer_names || ' '}</Text>
                  <Text>{customer_street || ' '}</Text>
                  <Text>{customer_postcity || ' '}</Text>
                  <Text>{customer_phone || ' '}</Text>
                  <Text>{customer_email || ' '}</Text>
                  <Text> </Text>
                  <Text>als Auftraggeber</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.noColumn}>
            <View style={styles.paragraph}>
              <Text>
                wird gemäß den umseitigen Allgemeinen Geschäftsbedingungen folgender
                Vermittlungsvertrag geschlossen.
              </Text>
            </View>
            <View style={styles.paragraph}>
              <Text style={styles.paragraphNo}>1.</Text>
              <Text>
                Der Vermittler wird beauftragt und ermächtigt, im Namen und für Rechnung des
                Auftraggebers das unter 3. beschriebene Gebrauchtboot zu verkaufen und zu
                übereignen. Der Auftraggeber erteilt dem Vermittler einen unbefristeten Alleinauf-
                trag, der für die Dauer von sechs Monaten nur aus wichtigem Grund gekündigt werden
                kann. Danach kann die Kündi- gung, die der Schriftform bedarf, mit einer Frist von
                acht Wochen gekündigt werden.
              </Text>
              <Text>
                Der Vermittler ist berechtigt, den Kaufpreis im Namen und für Rechnung des
                Auftraggebers einzuziehen.
              </Text>
            </View>
            <View style={styles.paragraph}>
              <Text>
                Der Vermittler ist berechtigt, vom Verkaufserlös die Provision und die darauf
                anfallende Umsatzsteuer abzuziehen, sowie mit etwaigen sonstigen im Zusammenhang mit
                dem Vermittlungsauftrag stehenden Forderungen an den Auftraggeber aufzurechnen. Der
                Verkauf geschieht unter Ausschluß jeglicher Gewährleistung.
              </Text>
            </View>
            <View style={styles.paragraph}>
              <Text style={styles.paragraphNo}>2.</Text>
              <Text>
                Das Boot wird dem Vermittler ab {start_date || '______________'} zur Verfügung
                gestellt.
              </Text>
            </View>
          </View>
          <View style={styles.columns}>
            <View style={styles.columnBoatLeft}>
              <View style={styles.fields}>
                <Text style={styles.paragraphNo}>3.</Text>
                <View style={styles.fieldLabels}>
                  <Text>Bootsname</Text>
                  <Text>Typ</Text>
                  <Text>Breite (m)</Text>
                  <Text>Länge ü.a. (m)</Text>
                  <Text>Tiefgang (m)</Text>
                  <Text>Motorhersteller</Text>
                  <Text>Motornummer</Text>
                  <Text>Betriebsstunden</Text>
                </View>
                <View style={styles.fieldValues}>
                  <Text>{boat_name || ' '}</Text>
                  <Text>{boat_model || ' '}</Text>
                  <Text>{boat_width || ' '}</Text>
                  <Text>{boat_length_total || ' '}</Text>
                  <Text>{boat_draft || ' '}</Text>
                  <Text>{motor_manufacturer || ' '}</Text>
                  <Text>{motor_number || ' '}</Text>
                  <Text>{operating_hours || ' '}</Text>
                </View>
              </View>
            </View>
            <View style={styles.columnBoatRight}>
              <View style={styles.fields}>
                <View style={styles.fieldLabels}>
                  <Text>Hersteller</Text>
                  <Text>Gewicht</Text>
                  <Text>registriert bei/unter</Text>
                  <Text>Bau Nr.</Text>
                  <Text>Baujahr</Text>
                  <Text>Motortyp</Text>
                  <Text>Baujahr Motor</Text>
                  <Text>Sonstiges</Text>
                </View>
                <View style={styles.fieldValues}>
                  <Text>{boat_manufacturer || ' '}</Text>
                  <Text>{boat_weight || ' '}</Text>
                  <Text>{boat_registry || ' '}</Text>
                  <Text>{boat_build_number || ' '}</Text>
                  <Text>{boat_build_year || ' '}</Text>
                  <Text>{motor_type || ' '}</Text>
                  <Text>{motor_build_year || ' '}</Text>
                  <Text>{other || ' '}</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.columns}>
            <View style={{ ...styles.columnLeft, flex: '0 0 70%' }}>
              <Text style={styles.colParagraphNo}>4.</Text>
              <Text>Der Auftraggeber versichert daß ihm / ihr keine Mängel bekannt sind.</Text>
            </View>
            <View style={{ ...styles.columnRight, flex: '0 0 30%' }}>
              <Svg viewBox='0 0 10 10' width='10' height='10'>
                <Rect width='10' height='10' fill='white' stroke='black' />
              </Svg>
            </View>
          </View>
          <View style={styles.columns}>
            <View style={{ ...styles.columnLeft, flex: '0 0 70%' }}>
              <Text>Der Auftraggeber erklärt, daß ihm folgende Mängel bekannt sind</Text>
            </View>
            <View style={{ ...styles.columnRight, flex: '0 0 30%' }}>
              <Svg viewBox='0 0 10 10' width='10' height='10'>
                <Rect width='10' height='10' fill='white' stroke='black' />
              </Svg>
            </View>
          </View>
          <View style={styles.noColumn}>
            <View style={styles.paragraph}>
              <Text style={{ minHeight: 35 }}>Keine ...</Text>
            </View>
          </View>
          <View style={styles.columns}>
            <View style={{ ...styles.columnLeft, flex: '0 0 80%' }}>
              <Text style={styles.colParagraphNo}>5.</Text>
              <Text>
                Der Auftraggeber versichert, daß das Boot sein Alleineigentum und frei von Rechten
                Dritter ist:
              </Text>
            </View>
            <View style={{ ...styles.columnRight, flex: '0 0 20%' }}>
              <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                <Text>ja </Text>
                <Svg viewBox='0 0 10 10' width='10' height='10'>
                  <Rect width='10' height='10' fill='white' stroke='black' />
                </Svg>
                <Text style={{ flex: '1' }}> </Text>
                <Text>nein </Text>
                <Svg viewBox='0 0 10 10' width='10' height='10'>
                  <Rect width='10' height='10' fill='white' stroke='black' />
                </Svg>
              </View>
            </View>
          </View>
          <View style={styles.noColumn}>
            <View style={styles.paragraph}>
              <Text>
                Falls ”nein”, Name und Anschrift der Miteigentümer sowie Beschreibung der Rechte
                Dritter:
              </Text>
            </View>
            <View style={styles.paragraph}>
              <Text>–</Text>
            </View>
            <View style={styles.paragraph}>
              <Text style={styles.paragraphNo}>6.</Text>
              <Text>Der Vermittler erhält als Treuhänder folgende Papiere, Schlüssel, etc.:</Text>
            </View>
            <View style={styles.paragraph}>
              <Text style={styles.paragraphNo}>7.</Text>
              <Text>
                Für den Verkauf wird ein Fragpreis von ...........€ und eine untere Preisgrenze von
                ...........€ festgelegt.
              </Text>
            </View>
            <View style={styles.paragraph}>
              <Text style={styles.paragraphNo}>8.</Text>
              <Text>Der Vermittler erhält für den Verkauf des Bootes folgende Provision:</Text>
              <ListItem>
                8% vom Kaufpreis bei einem Verkauf durch den Vermittler • 4% vom Kaufpreis bei einem
                Kunden des Auftraggebers
              </ListItem>
              <ListItem noBullet>
                2% bei einem Verkauf ohne unser Zutun, während der Vertragslaufzeit, jeweils
                zuzüglich der gesetzlichen Umsatzsteuer
              </ListItem>
              <ListItem>
                eine feste Provision von 3.500 €, inkl. USt. ( Mindestprovision bei einem Kaufpreis
                unter 35.000€ )
              </ListItem>
              <ListItem>
                Bei Vertragsabschluß wird eine Kostenpauschale i.H. von 500€ inkl. USt fällig, sowie
                alle12 Monate in Höhe von 250€
              </ListItem>
            </View>
            <View style={styles.paragraph}>
              <Text>
                Der Auftraggeber versichert mit seiner Unterschrift, daß die obigen Angaben der
                Wahrheit entsprechen.
              </Text>
              <Text>
                Dieser Vertrag wird unter Einbeziehung der umseitig abgedruckten Allgemeinen
                Geschäftsbedingungen geschlossen.
              </Text>
            </View>
          </View>
          <View style={styles.signatures}>
            <View>
              <Text>Kappeln, den ..............</Text>
            </View>
            <View>
              <Text>.....................................</Text>
              <Text>Vermittler</Text>
            </View>
            <View>
              <Text>.....................................</Text>
              <Text>Auftraggeber/in</Text>
            </View>
          </View>
        </Page>
      </TranslationContext.Provider>
    </Document>
  )
}

export default BrokerageAgreementDoc
