import { Add as AddIcon } from '@material-ui/icons'
import { Button } from 'react-admin'

export const CreateButton = (props) => {
  return (
    <Button
      color='primary'
      variant='contained'
      disableElevation
      label='ra.action.create'
      {...props}
    >
      <AddIcon />
    </Button>
  )
}

export default CreateButton
