import { Grid, LinearProgress } from '@material-ui/core'
import get from 'lodash/get'
import React, { useContext } from 'react'
import {
  ArrayInput,
  BooleanInput,
  DataProviderContext,
  FormDataConsumer,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  TranslatableInputs,
  useGetList,
  useLocale,
  useTranslate,
} from 'react-admin'
import { useForm } from 'react-final-form'
import { AutocompleteTextInput, CompactRemoveButton, SectionLabel } from '~/components'
import { Selections } from '~/graphql/generated'
import './UpsertCategory.css'

export const UpsertCategory = ({ showI18n }) => {
  const { change, getState } = useForm()
  const selectionsFilter = { context: 'resources.attributes.choices' }
  const selectionsSort = { field: 'name', order: 'ASC' }
  const { data: selections, loading } = useGetList<Selections>(
    'selections',
    undefined,
    selectionsSort,
    selectionsFilter
  )
  const locale = useLocale()
  const translate = useTranslate()
  const dataProvider = useContext(DataProviderContext)

  // todo: SimpleFormIterator: https://github.com/ValentinnDimitroff/ra-compact-ui/issues/13
  return (
    <Grid container spacing={3}>
      <Grid item md={3}>
        <TextInput
          source='name'
          validate={[required()]}
          label='resources.categories.fields.name'
          fullWidth
        />
        {showI18n && (
          <TranslatableInputs locales={['de', 'en']} defaultLocale={locale} groupKey='name'>
            <TextInput
              source='translated_name'
              label='resources.categories.fields.name'
              fullWidth
            />
          </TranslatableInputs>
        )}
      </Grid>
      <Grid item md={6}>
        <TextInput source='description' label='resources.attributes.fields.description' fullWidth />
        {showI18n && (
          <TranslatableInputs locales={['de', 'en']} defaultLocale={locale} groupKey='description'>
            <TextInput
              source='translated_description'
              label='resources.attributes.fields.description'
              fullWidth
            />
          </TranslatableInputs>
        )}
      </Grid>
      <Grid item md={3}>
        <ReferenceInput
          label='resources.categories.fields.section'
          source='section_id'
          reference='sections'
          sort={{ field: 'id', order: 'ASC' }}
        >
          <SelectInput optionText={(section) => <SectionLabel section={section} />} />
        </ReferenceInput>
      </Grid>
      <Grid item md={12}>
        <ArrayInput source='attributes' label='resources.categories.fields.attributes'>
          <SimpleFormIterator
            TransitionProps={{ classNames: '' }}
            className='attributes-iterator'
            removeButton={<CompactRemoveButton />}
          >
            <FormDataConsumer>
              {({
                scopedFormData: {
                  id: attribute_id,
                  input_type,
                  name,
                  choices,
                  selection,
                  suffix,
                } = {},
                getSource,
                ...rest
              }) => {
                return (
                  <Grid container spacing={1}>
                    <Grid item md={3}>
                      <TextInput
                        source={getSource('name')}
                        validate={[required()]}
                        label='resources.attributes.fields.name'
                        fullWidth
                        autoFocus={!name}
                      />
                      {showI18n && (
                        <TranslatableInputs
                          locales={['de', 'en']}
                          defaultLocale={locale}
                          groupKey={getSource('name')}
                        >
                          <TextInput
                            source={getSource('translated_name')}
                            label='resources.attributes.fields.name'
                            fullWidth
                          />
                        </TranslatableInputs>
                      )}
                    </Grid>
                    <Grid item md={3}>
                      {input_type === 'numeric' ? (
                        <NumberInput
                          source={getSource('default_value')}
                          label='resources.attributes.fields.default_value'
                          fullWidth
                        />
                      ) : input_type === 'boolean' ? (
                        <NumberInput
                          source={getSource('default_value')}
                          label='resources.attributes.fields.default_value'
                          max='1'
                          min='0'
                          format={(v) => (v === 'true' ? 1 : 0)}
                          parse={(v) => (v ? 'true' : 'false')}
                          fullWidth
                        />
                      ) : input_type === 'select' ? (
                        <SelectInput
                          source={getSource('default_value')}
                          label='resources.attributes.fields.default_value'
                          resettable
                          optionValue='key'
                          optionText={(c) => get(c, `translated_name.${locale}`) || c?.key}
                          choices={choices?.filter((c) => !!c?.key) || []}
                          translateChoice={false}
                          fullWidth
                        />
                      ) : input_type === 'shared_select' ? (
                        <SelectInput
                          source={getSource('default_value')}
                          label='resources.attributes.fields.default_value'
                          resettable
                          choices={selection?.choices || []}
                          optionValue='key'
                          optionText={(c) => get(c, `translated_name.${locale}`) || c.key}
                          fullWidth
                        />
                      ) : (
                        <TextInput
                          source={getSource('default_value')}
                          label='resources.attributes.fields.default_value'
                          fullWidth
                        />
                      )}
                    </Grid>
                    <Grid item md={2}>
                      {input_type === 'numeric' ? (
                        <AutocompleteTextInput
                          source={getSource('suffix')}
                          label='resources.attributes.fields.unit'
                          where={{ input_type: 'numeric' }}
                        />
                      ) : input_type === 'boolean' ? (
                        <AutocompleteTextInput
                          source={getSource('suffix')}
                          label={'resources.attributes.fields.suffix'}
                          where={{ input_type: 'boolean' }}
                        />
                      ) : (
                        <AutocompleteTextInput
                          source={getSource('suffix')}
                          label={'resources.attributes.fields.suffix'}
                          where={{ name: `"${name}"` }}
                        />
                      )}
                      {showI18n && (
                        <TranslatableInputs
                          locales={['de', 'en']}
                          defaultLocale={locale}
                          groupKey={getSource('suffix')}
                        >
                          <AutocompleteTextInput
                            source={getSource('translated_suffix')}
                            label={`resources.attributes.fields.${
                              input_type === 'numeric' ? 'unit' : 'suffix'
                            }`}
                            where={{ suffix: `"${suffix}"` }}
                          />
                        </TranslatableInputs>
                      )}
                    </Grid>
                    <Grid item md={2}>
                      <SelectInput
                        source={getSource('input_type')}
                        label='resources.attributes.fields.input_type'
                        initialValue='text'
                        choices={[
                          { id: 'text', name: 'resources.attributes.types.text' },
                          { id: 'autocomplete', name: 'resources.attributes.types.autocomplete' },
                          { id: 'numeric', name: 'resources.attributes.types.numeric' },
                          { id: 'boolean', name: 'resources.attributes.types.boolean' },
                          { id: 'select', name: 'resources.attributes.types.dropdown' },
                          {
                            id: 'shared_select',
                            name: 'resources.attributes.types.shared_dropdown',
                          },
                        ]}
                        fullWidth
                        onChange={async (val) => {
                          if (
                            attribute_id &&
                            ['autocomplete', 'select', 'shared_select'].includes(val.target.value)
                          ) {
                            const attribute_data = await dataProvider.getManyReference(
                              'boat_section_attribute',
                              {
                                target: 'attribute_id',
                                id: attribute_id,
                                filter: undefined,
                                sort: { field: 'id', order: 'DESC' },
                                pagination: { page: 1, perPage: 5 },
                              }
                            )
                            attribute_data.data.length > 0 &&
                              alert(
                                translate('resources.attributes.hint_switching_input_type', {
                                  attribute: name,
                                })
                              )
                          }
                        }}
                      />
                    </Grid>

                    <Grid item md={2}>
                      <BooleanInput
                        source={getSource('hidden')}
                        label='resources.attributes.fields.hidden'
                        fullWidth
                      />
                    </Grid>
                    {input_type === 'shared_select' ? (
                      <Grid item md={3}>
                        {/* Maybe rather use ReferenceInput */}
                        {loading ? (
                          <LinearProgress />
                        ) : (
                          <SelectInput
                            label='resources.attributes.fields.selection'
                            source={getSource('selection_id')}
                            allowEmpty
                            choices={Object.values(selections)}
                            onChange={(event) => {
                              selection = selections[event.target.value]
                              change(
                                getSource('selection'),
                                selection
                                  ? {
                                      ...selection,
                                      choices: [...selection.choices],
                                    }
                                  : undefined
                              )
                            }}
                          />
                        )}
                      </Grid>
                    ) : null}
                    {input_type === 'select' || input_type === 'autocomplete' ? (
                      <Grid item md={12}>
                        <ArrayInput
                          source={getSource('choices')}
                          label='resources.attributes.fields.choices'
                          className='attribute-choices'
                        >
                          <SimpleFormIterator
                            TransitionProps={{ classNames: '' }}
                            removeButton={<CompactRemoveButton />}
                          >
                            <FormDataConsumer>
                              {({ getSource, scopedFormData }) => (
                                <>
                                  <TextInput
                                    source={getSource('key')}
                                    label='resources.choices.fields.key'
                                    disabled={!!scopedFormData?.id}
                                  />
                                  {showI18n && (
                                    <TranslatableInputs
                                      locales={['de', 'en']}
                                      defaultLocale={locale}
                                      groupKey={getSource('')}
                                    >
                                      <TextInput
                                        source={getSource('translated_name')}
                                        label='resources.choices.fields.name'
                                      />
                                    </TranslatableInputs>
                                  )}
                                </>
                              )}
                            </FormDataConsumer>
                          </SimpleFormIterator>
                        </ArrayInput>
                      </Grid>
                    ) : null}
                  </Grid>
                )
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </Grid>
    </Grid>
  )
}

export default UpsertCategory
