import useMediaQuery from '@material-ui/core/useMediaQuery'
import LinearScaleIcon from '@material-ui/icons/LinearScale'
import { useEffect, useState } from 'react'
import {
  Button,
  Create,
  FormTab,
  ListButton,
  SaveButton,
  setSidebarVisibility,
  TabbedForm,
  TopToolbar,
  useNotify,
  useRedirect,
} from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useRouteMatch } from 'react-router-dom'
import {
  GuidedBoatForm,
  ToggleNotesButton,
  ToolbarAddBoatSection,
  UpsertBoat,
  UpsertBoatAttributes,
} from '~/components'

const RemoteSaveButton = ({ saveProps }) => {
  const {
    handleSubmitWithRedirect,
    handleSubmit,
    invalid,
    pristine,
    redirect,
    saving,
    submitOnEnter,
  } = saveProps
  return (
    <SaveButton
      variant='text'
      size='small'
      handleSubmitWithRedirect={handleSubmitWithRedirect || handleSubmit}
      disabled={invalid || pristine}
      invalid={invalid}
      redirect={redirect}
      saving={saving}
      submitOnEnter={submitOnEnter}
    />
  )
}

const BoatCreateActions = ({
  basePath,
  data,
  guided,
  saveProps,
  showToggleNotes,
  showNotes,
  setShowNotes,
}) => (
  <TopToolbar>
    {saveProps && <RemoteSaveButton saveProps={saveProps} />}
    {showToggleNotes && <ToggleNotesButton showNotes={showNotes} setShowNotes={setShowNotes} />}
    {!guided && (
      <Button
        component={Link}
        to={`${basePath}/guided_create`}
        label='resources.boats.guided_input'
      >
        <LinearScaleIcon />
      </Button>
    )}
    <ListButton basePath={basePath} record={data} label='ra.action.back' />
  </TopToolbar>
)

const BoatCreate = (props: object) => {
  const url = useRouteMatch().url
  const guidedMatch = useRouteMatch(`${url}/guided`)
  const firstTabMatch = useRouteMatch(`${url}/1`)
  const guided = !!guidedMatch
  const dispatch = useDispatch()
  const open = useSelector((state) => state.admin.ui.sidebarOpen)
  const openSidebar = () => dispatch(setSidebarVisibility(true))
  const closeSidebar = () => dispatch(setSidebarVisibility(false))
  const [saveProps, setSaveProps] = useState(null)
  const [showNotes, setShowNotes] = useState(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => guided && !open && openSidebar(), [guided])

  const notify = useNotify()
  const redirect = useRedirect()

  const onSuccessGuided = ({ data }) => {
    notify('ra.notification.created', { messageArgs: { smart_count: 1 } })
    redirect(`${props.basePath}/${data.id}/guided`)
  }

  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'))

  return guided ? (
    <Create
      title='resources.boats.create'
      defaultValue={{ created_at: Date.now() }}
      actions={<BoatCreateActions guided saveProps={saveProps} />}
      {...props}
      style={isXSmall ? {} : { marginLeft: open ? '244px' : '60px' }}
      onSuccess={onSuccessGuided}
      key='guided_create' // ensures remounting when switching to guided input
    >
      <GuidedBoatForm
        formRootPathname={`${url}/guided`}
        warnWhenUnsavedChanges
        saveProps={saveProps}
        setSaveProps={setSaveProps}
      />
    </Create>
  ) : (
    <Create
      title='resources.boats.create'
      defaultValue={{ created_at: Date.now() }}
      actions={
        <BoatCreateActions
          showToggleNotes={!!firstTabMatch}
          showNotes={showNotes}
          setShowNotes={setShowNotes}
        />
      }
      {...props}
    >
      <TabbedForm warnWhenUnsavedChanges submitOnEnter={true} redirect='show'>
        <FormTab label='resources.boats.base'>
          <UpsertBoat {...props} />
        </FormTab>
        <FormTab label='resources.boats.attributes'>
          <UpsertBoatAttributes {...props} showNotes={showNotes} />
          <ToolbarAddBoatSection />
        </FormTab>
      </TabbedForm>
    </Create>
  )
}

export default BoatCreate
