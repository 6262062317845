import { Reducer } from 'redux'
import { CalendarViewName } from '~/types'
import { changeDates, changeView, CHANGE_DATES, CHANGE_VIEW } from './actions'

type State = { view: CalendarViewName; dates: { start: Date; end: Date } }
type Action =
  | ReturnType<typeof changeView>
  | ReturnType<typeof changeDates>
  | { type: 'OTHER_ACTION'; payload?: any }

export * from './actions'
/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (
  previousState = { view: 'dayGridMonth', dates: { start: null, end: null } },
  action
): Reducer<State, Action> => {
  if (action.type === CHANGE_VIEW) {
    return { ...previousState, view: action.payload }
  }
  if (action.type === CHANGE_DATES) {
    return { ...previousState, dates: action.payload }
  }
  return previousState
}
