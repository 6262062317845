// import { identifier } from 'flocky'
// import { useState } from 'react'
import get from 'lodash/get'
import { SelectInput, TextInput, useLocale } from 'react-admin'
import { AutocompleteTextInput, FormattedBooleanInput, FormattedNumberInput } from '~/components'

export const DynamicInput = (props) => {
  const locale = useLocale()
  const { attribute, standalone, ...rest } = props
  // const key = attribute.id ? `attribute-value-${attribute.id}` : identifier()

  const name = attribute.translated_name?.[locale] || attribute.name
  const suffix = attribute.translated_suffix?.[locale] || attribute.suffix

  const fullLabel = suffix ? `${name} (${suffix})` : name
  const label = standalone ? fullLabel : suffix || name

  // console.log(attribute, rest)
  switch (attribute.input_type) {
    default:
    case 'text':
      return <TextInput {...rest} label={label} />
    case 'autocomplete':
      return (
        <AutocompleteTextInput
          where={{ attribute_id: attribute.id }}
          predefinedChoices={attribute.choices.map((c) => c.key)}
          {...rest}
          label={label}
        />
      )
    case 'numeric':
      return <FormattedNumberInput {...rest} label={label} />
    case 'boolean':
      return (
        <FormattedBooleanInput
          {...rest}
          format={(v) => v === 'true'}
          parse={(v) => (v ? 'true' : 'false')}
          label={label}
        />
      )
    case 'select':
      return (
        <SelectInput
          {...rest}
          choices={attribute.choices}
          optionValue='key'
          optionText={(c) => get(c, `translated_name.${locale}`) || c.key}
          label={label}
        />
      )
    case 'shared_select':
      return (
        <SelectInput
          {...rest}
          choices={attribute.selection.choices}
          optionValue='key'
          optionText={(c) => get(c, `translated_name.${locale}`) || c.key}
          label={label}
        />
      )
  }
}

/*
  const { id = Math.random(), input_type, name, show_by_default } = attribute

    text: <TextField label={name} value={value} source={source} key={key} onChange={} 
    numeric: <TextField type='number' label={name} value={value} source={source} key={key} />,
    boolean: (
      <FormControlLabel
        control={
          <Checkbox
            type='number'
            label={name}
            checked={!!value}
            onClick={(event) => console.log(event)}
            variant='filled'
            key={key}
          />
        }
        label={attribute.name}
        labelPlacement='end'
      />
    ),
*/

export default DynamicInput
