import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { React } from 'react'
import {
  ArrayInput,
  FormDataConsumer,
  minValue,
  NullableBooleanInput,
  number,
  NumberInput,
  required,
  SelectInput,
  SimpleFormIterator,
  TextInput,
} from 'react-admin'
import {
  AutocompleteTextInput,
  CompactRemoveButton,
  FormattedNumberInput,
  GridDevider,
  RelatedCustomer,
  SelectionInput,
} from '~/components'
import { moneyEuroFormat, positivDecimalFormat } from '~/helpers'
import {
  boatCeLabelingChoices,
  boatConditionChoices,
  boatStatusChoices,
  boatTypeChoices,
  motorboatPlaningTypeChoices,
  motorboatTypeChoices,
  sailboatRiggingTypeChoices,
  sailboatTypeChoices,
  usedBoatChoices,
} from '~/types/choices'
import './UpsertBoat.css'

const useStyles = makeStyles({
  input: {
    width: '100%',
  },
})

export const Base = (props: object) => {
  const classes = useStyles()
  return (
    <Grid container spacing={3}>
      <Grid item md={4}>
        <SelectInput
          source='status'
          choices={boatStatusChoices}
          label='resources.boats.fields.status'
        />
      </Grid>
      <Grid item md={8}></Grid>
      <Grid item md={4}>
        <TextInput source='name' label='resources.boats.fields.name' validate={[required()]} />
      </Grid>
      <Grid item md={4}>
        <AutocompleteTextInput source='manufacturer' label='resources.boats.fields.manufacturer' />
      </Grid>
      <Grid item md={4}>
        <AutocompleteTextInput
          source='model'
          label='resources.boats.fields.model'
          validate={[required()]}
        />
      </Grid>
      <Grid item md={4}>
        <TextInput multiline source='build_number' label='resources.boats.fields.build_number' />
      </Grid>
      <Grid item md={4}>
        <TextInput source='build_year' label='resources.boats.fields.build_year' />
      </Grid>
      <Grid item md={4}>
        <TextInput source='first_launch' label='resources.boats.fields.first_launch' />
      </Grid>
      <GridDevider text='resources.boats.sections.price' />
      {/*<FormGroupContextProvider name='price'>*/}
      <Grid item md={4}>
        <FormattedNumberInput
          source='asking_price'
          label='resources.boats.fields.asking_price'
          {...moneyEuroFormat}
        />
      </Grid>
      <Grid item md={4}>
        <FormattedNumberInput
          source='lower_price_limit'
          label='resources.boats.fields.lower_price_limit'
          {...moneyEuroFormat}
        />
      </Grid>
      <Grid item md={4}>
        <TextInput
          multiline
          source='price_reductions'
          label='resources.boats.fields.price_reductions'
        />
      </Grid>
      {/*</FormGroupContextProvider>*/}
      <GridDevider text='resources.boats.sections.storage' />
      {/*<FormGroupContextProvider name='storage'>*/}
      <Grid item md={6}>
        <TextInput
          multiline
          source='summer_storage'
          label='resources.boats.fields.summer_storage'
        />
      </Grid>
      <Grid item md={6}>
        <TextInput
          multiline
          source='winter_storage'
          label='resources.boats.fields.winter_storage'
        />
      </Grid>
      <Grid item md={12}></Grid>
      {/*</FormGroupContextProvider>*/}
      <GridDevider text='resources.boats.sections.boat_type' />
      {/*<FormGroupContextProvider name='boat_type'>*/}
      <Grid item md={4}>
        <SelectInput
          source='boat_type'
          choices={boatTypeChoices}
          label='resources.boats.fields.boat_type'
        />
      </Grid>
      <Grid item md={4}>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            ({
              sailboat: (
                <SelectInput
                  source='sailboat_type'
                  choices={sailboatTypeChoices}
                  {...rest}
                  label='resources.boats.fields.sailboat_type'
                />
              ),
              motorboat: (
                <SelectInput
                  source='motorboat_type'
                  choices={motorboatTypeChoices}
                  {...rest}
                  label='resources.boats.fields.motorboat_type'
                />
              ),
            }[formData.boat_type])
          }
        </FormDataConsumer>
      </Grid>
      <Grid item md={4}>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            ({
              sailboat: (
                <SelectInput
                  source='sailboat_rigging_type'
                  choices={sailboatRiggingTypeChoices}
                  label='resources.boats.fields.sailboat_rigging_type'
                  {...rest}
                />
              ),
              motorboat: (
                <SelectInput
                  source='motorboat_planing_type'
                  choices={motorboatPlaningTypeChoices}
                  label='resources.boats.fields.motorboat_planing_type'
                  {...rest}
                />
              ),
            }[formData.boat_type])
          }
        </FormDataConsumer>
      </Grid>
      {/*</FormGroupContextProvider>*/}
      <GridDevider text='resources.boats.sections.condition' />
      {/*<FormGroupContextProvider name='condition'>*/}
      <Grid item md={4}>
        <SelectInput
          source='goods_type'
          label='resources.boats.fields.goods_type'
          choices={usedBoatChoices}
        />
      </Grid>
      <Grid item md={4}>
        <SelectInput
          source='condition'
          label='resources.boats.fields.condition'
          choices={boatConditionChoices}
        />
      </Grid>
      <Grid item md={4}>
        <NumberInput
          source='previous_owners'
          validate={[number(), minValue(0)]}
          min='0'
          step='1'
          label='resources.boats.fields.previous_owners'
        />
      </Grid>
      {/*</FormGroupContextProvider>*/}
      <GridDevider text='resources.boats.sections.materials' />
      {/*<FormGroupContextProvider name='hull'>*/}
      <Grid item md={4}>
        <SelectionInput
          autocomplete
          source='hull_material'
          label='resources.boats.fields.hull_material'
          resettable
        />
      </Grid>
      <Grid item md={4}>
        <SelectionInput
          autocomplete
          source='hull_color'
          label='resources.boats.fields.hull_color'
          resettable
        />
      </Grid>
      <Grid item md={4}>
        <SelectionInput
          autocomplete
          source='keel_type'
          label='resources.boats.fields.keel_type'
          resettable
        />
      </Grid>
      {/*</FormGroupContextProvider>*/}
      <GridDevider text='resources.boats.sections.certificates' />
      {/*<FormGroupContextProvider name='approvals'>*/}
      <Grid item md={6}>
        <SelectInput
          source='ce_labeling'
          label='resources.boats.fields.ce_labeling'
          choices={boatCeLabelingChoices}
        />
      </Grid>
      <Grid item md={6}>
        <NullableBooleanInput
          classes={classes}
          source='lake_constance_approval'
          label='resources.boats.fields.lake_constance_approval'
        />
      </Grid>
      {/*</FormGroupContextProvider>*/}
      <GridDevider text='resources.boats.sections.dimensions' />
      {/*<FormGroupContextProvider name='size'>*/}
      <Grid item md={4}>
        <FormattedNumberInput
          source='length_total'
          validate={[number(), minValue(0)]}
          {...positivDecimalFormat}
          label='resources.boats.fields.length_total'
        />
      </Grid>
      <Grid item md={4}>
        <FormattedNumberInput
          source='length_waterline'
          validate={[number(), minValue(0)]}
          {...positivDecimalFormat}
          label='resources.boats.fields.length_waterline'
        />
      </Grid>
      <Grid item md={4}>
        <FormattedNumberInput
          source='width'
          validate={[number(), minValue(0)]}
          {...positivDecimalFormat}
          label='resources.boats.fields.width'
        />
      </Grid>
      {/*</FormGroupContextProvider>*/}
      {/*<FormGroupContextProvider name='size2'>*/}
      <Grid item md={3}>
        <FormattedNumberInput
          source='draft_max'
          validate={[number(), minValue(0)]}
          {...positivDecimalFormat}
          label='resources.boats.fields.draft_max'
        />
      </Grid>
      <Grid item md={3}>
        <FormattedNumberInput
          source='draft_min'
          validate={[number(), minValue(0)]}
          {...positivDecimalFormat}
          label='resources.boats.fields.draft_min'
        />
      </Grid>
      <Grid item md={3}>
        <FormattedNumberInput
          source='displacement'
          validate={[number(), minValue(0)]}
          {...positivDecimalFormat}
          label='resources.boats.fields.displacement'
        />
      </Grid>
      <Grid item md={3}>
        <FormattedNumberInput
          source='ballast'
          validate={[number(), minValue(0)]}
          {...positivDecimalFormat}
          label='resources.boats.fields.ballast'
        />
      </Grid>
      {/*</FormGroupContextProvider>*/}
    </Grid>
  )
}

export const UpsertBoat = (props: object) => (
  <Grid container spacing={10}>
    <Grid item lg={8}>
      <Grid container spacing={3}>
        <Base {...props} />
      </Grid>
    </Grid>
    <Grid item lg={4}>
      <ArrayInput source='boat_customer' label='resources.customers.fields.relationships'>
        <SimpleFormIterator
          TransitionProps={{ classNames: '' }}
          disableReordering
          removeButton={<CompactRemoveButton />}
        >
          <FormDataConsumer>
            {({ scopedFormData, getSource }) => (
              <RelatedCustomer
                source={getSource('customer_id')}
                reference='customers'
                autoFocus={!scopedFormData}
              />
            )}
          </FormDataConsumer>
          <AutocompleteTextInput
            source='relation_type'
            label='resources.boats.relationships.type'
            validate={[required()]}
          />
        </SimpleFormIterator>
      </ArrayInput>
    </Grid>
  </Grid>
)

export default UpsertBoat
/*

      <Card style={{ width: 'auto' }}>
        <CardContent>
          <EditAttributes boatId={props.id} />
        </CardContent>
      </Card>
      */
