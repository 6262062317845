import { useCallback } from 'react'
import { Create, SimpleForm, useNotify, useRedirect } from 'react-admin'
import { auth } from '~/App'
import { UpsertUser } from '~/components'

const UserCreate = (props: object) => {
  const notify = useNotify()
  const redirect = useRedirect()

  const save = useCallback(
    async (form) => {
      const { email, password, user, ...options } = form
      options.userData = user

      try {
        const { user } = await auth.register({ email, password, options })
        redirect(`/auth_accounts/${user.id}`)
      } catch (e) {
        console.warn(e)
        notify(e.message)
      }
    },
    [notify, redirect]
  )

  return (
    <Create title='resources.users.create' {...props}>
      <SimpleForm redirect='list' save={save}>
        <UpsertUser create={true} />
      </SimpleForm>
    </Create>
  )
}

export default UserCreate
