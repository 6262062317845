import { useState } from 'react'
import {
  Edit,
  EditButton,
  ListButton,
  SimpleForm,
  TopToolbar,
  useLocale,
  useTranslate,
} from 'react-admin'
import { ToggleI18nButton, UpsertCategory } from '~/components'

const CategoryName = ({ record }) => {
  const translate = useTranslate()
  return (
    <span>
      {translate(`resources.categories.name`, { smart_count: 1 })}: {record ? `${record.name}` : ''}
    </span>
  )
}

const CategoryEditActions = ({ basePath, data, showI18n, setShowI18n }) => {
  const locale = useLocale()
  return (
    <TopToolbar>
      <ToggleI18nButton showI18n={showI18n} setShowI18n={setShowI18n} />
      {data?.section && (
        <EditButton
          basePath='/sections'
          record={{ id: data.section_id }}
          label={data.section.translated_name?.[locale] || data.section.slug}
        />
      )}
      <ListButton basePath={basePath} record={data} label='resources.categories.title' />
    </TopToolbar>
  )
}

export const CategoryEdit = (props, record) => {
  const [showI18n, setShowI18n] = useState(false)

  return (
    <Edit
      title={<CategoryName />}
      actions={<CategoryEditActions showI18n={showI18n} setShowI18n={setShowI18n} />}
      {...props}
    >
      <SimpleForm redirect='list' warnWhenUnsavedChanges>
        <UpsertCategory {...props} showI18n={showI18n} />
      </SimpleForm>
    </Edit>
  )
}

export default CategoryEdit
