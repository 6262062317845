import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { InputHelperText } from 'ra-ui-materialui'
import { useCallback, useState } from 'react'
import { useInput, useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'

export const AutocompleteFreeSolo = ({
  source,
  record,
  getChoices,
  choices,
  label = '',
  loading,
  helperText,
  autoFocus,
  ...rest
}) => {
  const [open, setOpen] = useState(false)
  const translate = useTranslate()

  const {
    input,
    isRequired,
    meta: { error, submitError, touched },
  } = useInput({
    source,
    ...rest,
  })

  const form = useForm()
  const change = useCallback(
    (_, value) => {
      input.onChange(_, value)
      form.change(source, value)
    },
    [form, source, input]
  )

  return (
    <>
      <Autocomplete
        freeSolo
        autoSelect
        autoHighlight
        onOpen={() => {
          setOpen(true)
          getChoices && getChoices()
        }}
        onClose={() => {
          setOpen(false)
        }}
        open={open}
        loading={loading}
        options={choices}
        openOnFocus={true}
        {...input}
        onChange={change}
        renderInput={(params) => (
          <TextField
            {...params}
            required={isRequired}
            error={!!(touched && (error || submitError))}
            label={translate(label, { _: label })}
            variant='filled'
            margin='dense'
            autoFocus={autoFocus}
            helperText={
              <InputHelperText
                touched={touched}
                error={error || submitError}
                helperText={helperText}
              />
            }
          />
        )}
      />
    </>
  )
}

export default AutocompleteFreeSolo
