import { Grid } from '@material-ui/core'
import { Edit as EditIcon, Notes as NotesIcon } from '@material-ui/icons'
import RichTextInput from 'ra-input-rich-text'
import React, { useCallback, useRef, useState } from 'react'
import {
  Button,
  Datagrid,
  DateField,
  DeleteButton,
  FileInput,
  FormDataConsumer,
  FormTab,
  FunctionField,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  required,
  RichTextField,
  SelectInput,
  SimpleForm,
  TabbedForm,
  TextField,
  TextInput,
  useMutation,
  useRecordContext,
  useRefresh,
} from 'react-admin'
import { useForm } from 'react-final-form'
import {
  AutocompleteTextInput,
  CreateModal,
  EditModal,
  Empty,
  FileImageField,
  HistoryDatagrid,
  TabToolbar,
} from '~/components'
const { REACT_APP_HBP_URI } = process.env

export const EditComments = (props: object = {}) => {
  const {
    relation = [props.resource, props.match.params.id],
    stateCreate,
    disableDelete,
    listLinked,
  } = props

  const stateEditModal = useState(false)
  const [editCommentId, edit] = useState(0)

  const refresh = useRefresh()
  const [mutate] = useMutation()

  const { boat_customer } = useRecordContext()
  // TODO: Make this more generic (working for other entities than boats/customers)
  const linkedType =
    boat_customer && boat_customer.length > 0
      ? boat_customer[0].boat_id
        ? 'boats'
        : 'customers'
      : null
  const linkedSelectionFilter =
    linkedType === 'boats'
      ? {
          id: boat_customer.map((bc) => bc.boat_id),
        }
      : linkedType === 'customers'
      ? {
          id: boat_customer.map((bc) => bc.customer_id),
        }
      : {}
  const linkedOptionText = (record) => {
    return linkedType === 'boats'
      ? `${record.model} (${record.name})`
      : linkedType === 'customers'
      ? `${record.firstname} ${record.lastname}`
      : ''
  }

  // todo: move this somehow to editdialog and add button to datagrid
  // can't figure out how to access List from Create-, EditModal (has to be possible)
  const EditModalButton = ({ source, record }) => (
    <Button
      onClick={(e) => {
        edit(record.id)
        stateEditModal[1](true)
      }}
      label='ra.action.edit'
    >
      <EditIcon />
    </Button>
  )

  const createComment = useCallback(
    async (values) => {
      const [relName, relId] = relation
      await mutate(
        {
          type: 'create',
          resource: 'comments',
          payload: {
            data: {
              ...values,
              id: undefined,
              relation: relName,
              relation_id: parseInt(relId),
            },
          },
        },
        { returnPromise: true }
      )
      refresh()
      stateCreate[1](false)
    },
    [mutate, refresh, stateCreate, relation]
  )

  const editComment = useCallback(
    async (values) => {
      const [relName, relId] = relation
      await mutate(
        {
          type: 'update',
          resource: 'comments',
          payload: {
            id: values.id,
            data: {
              ...values,
              updated_at: new Date(),
              relation: relName,
              relation_id: parseInt(relId),
            },
          },
        },
        { returnPromise: true }
      )
      refresh()
      stateEditModal[1](false)
    },
    [mutate, refresh, stateEditModal, relation]
  )

  const refCreateCanvas = useRef(null)

  const AttachmentField = ({ record, edit, ...rest }) => {
    return record.rawFile instanceof File ? (
      <>
        <FileImageField
          record={record}
          {...rest}
          captionSource={edit === 'caption' ? null : 'caption'}
        />
        {edit && (
          <FormDataConsumer>
            {({ formData }) => {
              const index = formData.pictures.findIndex((p) => p.src === record.src)
              return (
                edit && (
                  <TextInput
                    source={`pictures[${index}].${edit}`}
                    label={`resources.attachments.fields.${edit}`}
                  />
                )
              )
            }}
          </FormDataConsumer>
        )}
      </>
    ) : (
      <>
        <FileImageField
          record={record}
          {...rest}
          source='uri'
          typeSource='content_type'
          captionSource={edit === 'caption' ? null : 'caption'}
          uriPrefix={`${REACT_APP_HBP_URI}/storage/o/`}
        />
        {edit && (
          <FormDataConsumer>
            {({ formData }) => {
              const index = formData.pictures.findIndex((p) => p.id === record.id)
              return (
                edit && (
                  <TextInput
                    source={`pictures[${index}].${edit}`}
                    label={`resources.attachments.fields.${edit}`}
                  />
                )
              )
            }}
          </FormDataConsumer>
        )}
      </>
    )
  }

  const ExpandComment = ({ record, ...rest }) => {
    return (
      <div>
        <RichTextField record={record} source='comment' />
        {record.pictures.map((picture) => (
          <AttachmentField record={picture} key={picture.uri} />
        ))}
      </div>
    )
  }

  const CommentFormInputs = ({ linkedType, linkedSelectionFilter, linkedOptionText }) => {
    const form = useForm()
    const onLinkedIdChanged = useCallback(
      (linked_id) => {
        form.change(listLinked ? 'relation' : 'linked_type', linked_id ? linkedType : null)
      },
      [form, linkedType]
    )

    return (
      <>
        <Grid container fullWidth spacing={1}>
          <Grid item md={7}>
            <AutocompleteTextInput source='type' label='Type' />
          </Grid>
          <Grid item md={5}>
            {linkedType && (
              <ReferenceInput
                label={`resources.comments.linked_types.${linkedType}`}
                source={listLinked ? 'relation_id' : 'linked_id'}
                reference={linkedType}
                filter={linkedSelectionFilter}
                allowEmpty
                onChange={onLinkedIdChanged}
              >
                <SelectInput optionText={linkedOptionText} />
              </ReferenceInput>
            )}
          </Grid>
        </Grid>
        <RichTextInput source='comment' label='' validate={[required()]} />
        <FileInput
          source='pictures'
          label=''
          accept='image/*'
          multiple={true}
          refCanvas={refCreateCanvas}
        >
          <AttachmentField source='src' title='title' edit='caption' />
        </FileInput>
      </>
    )
  }

  return (
    <>
      <Empty {...props} icon={NotesIcon} resource='comments' basePath='/comments'>
        <Datagrid expand={<ExpandComment />} rowClick='expand'>
          <TextField source='id' label='#' />
          <TextField source='type' />
          {linkedType && (
            <ReferenceField
              source={listLinked ? 'relation_id' : 'linked_id'}
              reference={linkedType}
              link='show'
              label={`resources.comments.linked_types.${linkedType}`}
            >
              {linkedType === 'boats' ? (
                <FunctionField
                  source='name'
                  render={(record) => `${record.name} (${record.model})`}
                />
              ) : (
                <FunctionField
                  source='firstname'
                  render={(record) => `${record.firstname} ${record.lastname}`}
                />
              )}
            </ReferenceField>
          )}
          <DateField source='created_at' />
          <DateField source='updated_at' />
          <EditModalButton />
          {disableDelete !== true && <DeleteButton redirect={false} />}
        </Datagrid>
      </Empty>

      <CreateModal
        title='resources.comments.create'
        state={stateCreate}
        {...props}
        resource='comments'
      >
        <>
          <SimpleForm
            redirect={false}
            save={createComment}
            // toolbar={<TabToolbar {...props} />}
          >
            <CommentFormInputs
              linkedType={linkedType}
              linkedSelectionFilter={linkedSelectionFilter}
              linkedOptionText={linkedOptionText}
            />
          </SimpleForm>
        </>
      </CreateModal>

      <EditModal state={stateEditModal} {...props} resource='comments' id={editCommentId}>
        {/* todo: don't know why props (redirect) are not passed to standard toolbar.. */}
        <TabbedForm
          syncWithLocation={false}
          redirect={false}
          save={editComment}
          toolbar={<TabToolbar {...props} />}
        >
          <FormTab label='ra.action.edit'>
            <CommentFormInputs
              linkedType={linkedType}
              linkedSelectionFilter={linkedSelectionFilter}
              linkedOptionText={linkedOptionText}
            />
          </FormTab>
          <FormTab label='resources.history.title'>
            <ReferenceManyField
              reference='history'
              target='relation_id'
              label=''
              filter={{ relation: 'comments' }}
              sort={{ field: 'time', order: 'DESC' }}
            >
              <HistoryDatagrid hideRelation />
            </ReferenceManyField>
          </FormTab>
        </TabbedForm>
      </EditModal>
    </>
  )
}

export default EditComments
