import { createTheme } from '@material-ui/core/styles'
import { useEffect } from 'react'
import { Layout, LayoutProps, Sidebar, useSetLocale } from 'react-admin'
import { useSelector, useStore } from 'react-redux'
import { useLocation } from 'react-router-dom'
// import { AppState } from '~/types'
import { AppState } from '../types'
import AppBar from './AppBar'
import Menu from './Menu'
import { darkTheme, lightTheme, testTheme } from './themes'

const CustomSidebar = (props: any) => <Sidebar {...props} size={200} />
const NoSideBar = () => <></>

const getTheme = (theme: String) => {
  switch (theme) {
    case 'dark':
      return darkTheme
    case 'test':
      return testTheme
    default:
      return lightTheme
  }
}

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (props: LayoutProps) => {
  const theme = createTheme(useSelector((state: AppState) => getTheme(state.theme)))

  // Set locale on Mount
  const setLocale = useSetLocale()
  const store = useStore()
  useEffect(() => {
    setLocale(store.getState().locale)
  }, [setLocale])

  const location = useLocation()
  const isGuided = location.pathname.includes('/guided')

  return (
    <Layout
      {...props}
      theme={theme}
      appBar={AppBar}
      sidebar={isGuided ? NoSideBar : CustomSidebar}
      menu={Menu}
    />
  )
}
