export const categoryEntryState = (
  categoryValues,
  category
): {
  categoryValues: any
  attributesState: 'none' | 'some' | 'all' | undefined
  checked: boolean
} => {
  const ignoreAttributes = category.attributes
    .filter((a) => a.input_type === 'boolean')
    .map((a) => a.id)
  const attributesValues = categoryValues?.boat_section_attribute
    ?.filter((bsa) => !ignoreAttributes.includes(bsa.attribute_id))
    .map((bsa) => bsa.value)
  const attributesState = !categoryValues
    ? undefined
    : attributesValues?.every((v) => !!v)
    ? 'all'
    : attributesValues?.find((v) => !!v)
    ? 'some'
    : 'none'
  const markedState = categoryValues?.notes_icon
  const checked =
    !attributesState || markedState === 'check' || (!markedState && attributesState === 'all')
  return { categoryValues, attributesState, checked }
}
