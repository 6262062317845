import { Grid } from '@material-ui/core'
import {
  ArrayInput,
  FormDataConsumer,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  TranslatableInputs,
  useLocale,
} from 'react-admin'
import { CompactRemoveButton } from '~/components'
import { selectionContextChoices, selectionUserContextChoices } from '~/types/choices'
import './UpsertSelection.css'

export const UpsertSelection = ({ record, showI18n }) => {
  const locale = useLocale()

  // todo: SimpleFormIterator: https://github.com/ValentinnDimitroff/ra-compact-ui/issues/13
  return (
    <Grid container spacing={3}>
      <FormDataConsumer>
        {({ formData }) => (
          <>
            <Grid item md={3}>
              <TextInput
                source='name'
                label='resources.selections.fields.name'
                disabled={formData.predefined}
                fullWidth
              />
            </Grid>
            <Grid item md={6}>
              <SelectInput
                source='context'
                choices={
                  formData.predefined ? selectionContextChoices : selectionUserContextChoices
                }
                label='resources.selections.fields.context'
                disabled={formData.predefined}
                fullWidth
              />
            </Grid>
          </>
        )}
      </FormDataConsumer>
      {/* <Grid item md={3}>
        <ReferenceInput
          label='resources.selection.fields.section'
          source='section_id'
          reference='sections'
          sort={{ field: 'id', order: 'ASC' }}
        >
          <SelectInput optionText={(section) => <SectionLabel section={section} />} />
        </ReferenceInput>
      </Grid> */}
      <Grid item md={12}>
        <ArrayInput
          record={record}
          source='choices'
          label='resources.selections.fields.choices'
          className='selection-choices'
        >
          <SimpleFormIterator
            TransitionProps={{ classNames: '' }}
            disableRemove={(r) => r.id > 0}
            removeButton={<CompactRemoveButton />}
          >
            <FormDataConsumer>
              {({ getSource, scopedFormData }) => (
                <>
                  <TextInput
                    source={getSource('key')}
                    label='resources.choices.fields.key'
                    disabled={!!scopedFormData?.id}
                    autoFocus={!scopedFormData}
                  />
                  {showI18n && (
                    <TranslatableInputs
                      locales={['de', 'en']}
                      defaultLocale={locale}
                      groupKey={getSource('')}
                    >
                      <TextInput
                        source={getSource('translated_name')}
                        label='resources.choices.fields.name'
                      />
                    </TranslatableInputs>
                  )}
                </>
              )}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </Grid>
    </Grid>
  )
}

export default UpsertSelection
