import gql from 'graphql-tag'
import { GET_LIST, GET_ONE } from 'react-admin'
import { upsertQuery } from '~/helpers'

const buildFields = gql`
  {
    id
    email
    default_role
    active
    user {
      id
      display_name
    }
    account_roles {
      role
    }
  }
`

export const UserQueries = upsertQuery(
  {
    [GET_ONE]: { buildFields },
    [GET_LIST]: { buildFields },
  },
  {
    relations: ['users'],
  }
)
export default UserQueries
