import englishMessages from 'ra-language-english'
import { TranslationMessages } from 'react-admin'

englishMessages.ra.boolean.null = 'Not specified'

const customEnglishMessages: TranslationMessages = {
  ...englishMessages,
  pos: {
    search: 'Search',
    configuration: 'Configuration',
    language: 'Language',
    theme: {
      name: 'Theme',
      light: 'Light',
      dark: 'Dark',
      test: 'Test',
    },
    i18n: {
      show: 'Show Translations',
      hide: 'Hide Translations',
    },
    notes: {
      show: 'Notizen anzeigen',
      hide: 'Notizen ausblenden',
    },
  },
  resources: {
    phones: {
      title: 'Phones',
    },
    emails: {
      title: 'Emails',
    },
    customer_customer: {
      title: 'Customer to customer relations',
    },
    administration: {
      menu: 'Adminstration',
    },
    auth_accounts: {
      title: 'Users',
    },
    users: {
      title: 'Users',
      create: 'Add new User',
      fields: {
        username: 'Username',
        password: 'Password',
        id: 'User-ID',
        role: 'Role',
        email: 'Email',
        active: 'Active',
      },
    },
    suppliers: {
      menu: 'Supplier database',
      name: 'Supplier |||| Supplier',
      title: 'Supplier',
      empty: 'There are no supplier yet.',
      create: 'Add new supplier',
      base: 'General information',
      fields: {
        id: 'Supplier-ID',
        name: 'Company Name',
        services: 'Services',
        website: 'Website',
        email: 'Email',
        contact: {
          name: 'Contact Name',
          phone: 'Contact Phone',
          email: 'Contact Email',
        },
      },
    },
    customers: {
      menu: 'Customer database',
      name: 'Customer |||| Customers',
      title: 'Customer',
      empty: 'There are no customers yet.',
      create: 'Add new customer',
      base: 'General information',
      fields: {
        id: 'Customer-ID',
        title: 'Title',
        firstname: 'Firstname',
        lastname: 'Lastname',
        salutation: 'Salutation',
        role: 'Role',
        company: 'Company',
        identity_card_number: 'Identity card number',
        nationality: 'Nationality',
        birthday: 'Birthday',
        street: 'Street',
        postal_code: 'Postal code',
        city: 'City',
        country: 'Country',
        phones: 'Phone numbers',
        emails: 'E-mails',
        birth_name: 'Birth name',
        informal_salutation: 'informal',
        relationships: 'Relationships',
      },
      array_fields: {
        phone: 'Number',
        email: 'Email',
        type: 'Type',
      },
      types: {
        relation_to: 'Relations to',
        relation_from: 'Relations from',
        seller: 'Seller',
        suitor: 'Suitor',
        buyer: 'Buyer',
        brokered: 'Brokered',
        boats: 'Boats',
        boat_name: 'Boat Name',
      },
    },
    auth_roles: {
      title: 'Roles',
    },
    roles: {
      name: 'Role |||| Roles',
      title: 'Roles',
      empty: 'There are no roles yet.',
      create: 'Add new Role',
      fields: {
        id: 'Role-ID',
        name: 'Name',
      },
    },
    boats: {
      menu: 'Boot database',
      name: 'Boat |||| Boats',
      title: 'Boats',
      empty: 'There are no boats yet.',
      create: 'Add new boat',
      base: 'General information',
      attributes: 'Equipment',
      empty_attributes: 'No equipment added, yet',
      guided_input: 'Guided Input',
      types: {
        customers: 'Related Customers',
        customer_name: 'Customer Name',
      },
      fields: {
        id: 'Boat-ID',
        manufacturer: 'Manufacturer',
        model: 'Model',
        name: 'Name',
        build_year: 'Build year',
        first_launch: 'first launch',
        status: 'Status',
        asking_price: 'Asking price',
        price_reductions: 'Price reductions',
        lower_price_limit: 'Lower price limit',
        summer_storage: 'Summer storage',
        winter_storage: 'Winter storage',
        boat_type: 'Boat type',
        motorboat_type: 'Motorboat',
        motorboat_planing_type: 'Type',
        sailboat_type: 'Sailboat',
        sailboat_rigging_type: 'Rigging',
        goods_type: 'Goods type',
        condition: 'Condition',
        previous_owners: 'Previous owners',
        build_number: 'Build number',
        ce_labeling: 'CE Labeling',
        hull_material: 'Hull material',
        hull_color: 'Hull color',
        keel_type: 'Keel type',
        lake_constance_approval: 'Lake constance approval',
        length_total: 'Total length (m)',
        length_waterline: 'Waterline length (m)',
        width: 'Width (m)',
        draft_max: 'Draft max. (m)',
        draft_min: 'Draft min. (m)',
        displacement: 'Displacement (kg)',
        ballast: 'Ballast (kg)',
        customer_id: 'Owner',
        relationships: 'Relationships',
      },
      relationships: {
        type: 'Type',
      },
      sections: {
        price: 'Price',
        storage: 'Storage',
        boat_type: 'Boat Type',
        condition: 'Condition',
        materials: 'Materials',
        certificates: 'Certificates',
        dimensions: 'Dimensions',
      },
      choices: {
        status: {
          processing: 'In process',
          mediation: 'In mediation',
          handover: 'Sold in handover',
          settlement: 'Sold in settlement',
          completed: 'Sale completed',
          cancelled: 'Contract cancelled',
        },
        type: {
          sailboat: 'Sailing boat',
          motorboat: 'Motor boat',
        },
        motorboat_type: {
          cabin_cruiser: 'Cabin cruiser',
          fishing_boat: 'Fishing boat',
          catamaran: 'Catamaran',
          hovercraft: 'Hovercraft',
          houseboat: 'House boat',
        },
        motorboat_plan: {
          glider: 'Glider',
          'semi-glider': 'Half glider',
          displacer: 'Displacer',
        },
        sailboat_type: {
          center_cockpit: 'Center Cockpit',
          daysailer: 'Daysailer',
          decksalon: 'Deck saloon',
          dinghy: 'Dinghy',
          catamaran: 'Catamaran',
          trimaran: 'Trimaran',
          classic: 'Classic',
          motor_sailer: 'Motor sailer',
          flatboat: 'Flatboat',
        },

        sailboat_rigging: {
          kat: 'Kat',
          sloop: 'Sloop',
          schooner: 'Schooner',
          ketch: 'Ketch',
          yawl: 'Yawl',
          gaff: 'Gaff',
          cutter: 'Cutter',
        },

        usedboat: {
          used: 'Used boat',
          new: 'New boat',
          display: 'Display model',
        },

        boat_condition: {
          tinkerware: 'Tinkerware',
          casco: 'Casco',
          used: 'Used',
          good: 'Good',
          very_good: 'Very good',
          like_new: 'Like new',
          new: 'new',
        },
        boat_labels: {
          a: 'A - Deep sea',
          b: 'B - Outside coastal waters',
          c: 'C - Coastal waters',
          d: 'D - Protected waters',
        },
      },
    },
    sections: {
      title: 'Sections',
      name: 'Section |||| Sections',
      add: 'Add Section',
      fields: {
        slug: 'Definite name',
        name: 'Name',
        position: 'Position',
        category_count: '# Categories',
      },
    },
    categories: {
      title: 'Equipment',
      name: 'Category |||| Categories',
      add: 'Add Equipment',
      empty: 'There is no equipment yet.',
      create: 'Add new equipment',
      notes: 'Notes',
      notes_icon: 'Input state',
      fields: {
        id: 'Category-ID',
        name: 'Name',
        section: 'Section',
        description: 'Description',
        attributes: 'Available attributes',
        attribute_count: '# Attributes',
      },
    },
    attributes: {
      title: 'Equipment items',
      save_first: '» newly added option must be saved first «',
      hint_switching_input_type:
        'Hint: The attribute "%{attribute}" changed has existing data, changing input type may cause issues.',
      fields: {
        id: 'Attribute-ID',
        name: 'Name',
        description: 'Description',
        default_value: 'Default value',
        suffix: 'Suffix',
        unit: 'Unit',
        input_type: 'Input type',
        show_by_default: 'Show by default',
        hidden: 'hidden',
        choices: 'Choices',
        selection: 'Shared Selection',
      },
      types: {
        text: 'Text',
        autocomplete: 'Autocomplete',
        numeric: 'Number',
        boolean: 'Yes / No',
        dropdown: 'Dropdown',
        shared_dropdown: 'Shared Selection',
      },
    },
    selections: {
      title: 'Selections',
      add: 'add selection',
      empty: 'There are no selections yet.',
      create: 'Add new selection',
      fields: {
        id: 'Selection-ID',
        name: 'Name (internal)',
        section: 'Section',
        context: 'Context',
        predefined: 'Predefined',
        choices: 'Choices',
        choice_count: '# Choices',
      },
      contexts: {
        attributes: {
          choices: 'Equipment-Attributes',
        },
      },
    },
    choices: {
      create: 'Add choice',
      create_item: 'Add "%{item}"',
      fields: {
        key: 'Definite name',
        name: 'Name',
      },
    },
    comments: {
      name: 'Comment |||| Comments',
      empty: 'No comments, yet.',
      title: 'Comments',
      create: 'Comment',
      update: 'Change',
      remove: 'Remove',
      loading: 'Loading comments...',
      fields: {
        comment: 'Comment',
        type: 'Type',
        linked_type: 'Link to',
      },
      linked_types: {
        boats: 'Boat',
        customers: 'Customer',
      },
    },
    attachments: {
      name: 'Attachment |||| Attachments',
      empty: 'No attachments, yet.',
      title: 'Attachments',
      create: 'Create attachment',
      upload: 'Upload',
      uploaded: 'Attachment upload successful.',
      deleted: 'Attachment was deleted.',
      loading: 'Loading attachments...',
      fields: {
        type: 'Type',
        caption: 'Caption',
      },
    },
    appointments: {
      name: 'Appointment |||| Appointments',
      menu: 'Appointment management',
      title: 'Appointments',
      create: 'Add new appointment',
      empty: 'There are no appointments yet.',
      calendar_view: 'Calendar View',
      list_view: 'List View',
      new: 'new',
      fields: {
        title: 'Title',
        start: 'Start',
        end: 'End',
        type: 'Type',
        appointment_customer: 'Customers',
        appointment_boat: 'Boats',
        allDay: 'all day',
        location: 'Location',
        notes: 'Notes',
      },
      types: {
        none: 'None (other)',
        viewing: 'Viewing',
        handover: 'Handover',
        pickup: 'Pickup',
      },
    },
    documents: {
      name: 'Document |||| Documents',
      menu: 'Document management',
      title: 'Documents',
      create: 'Add new document',
      empty: 'There are no documents yet.',
      new: 'new',
      fields: {
        title: 'Title',
        type: 'Type',
        document_customer: 'Customer(s)',
        document_boat: 'Boat(s)',
        notes: 'Notes',
      },
      types: {
        none: 'None (other)',
        brokerage_agreement: 'Brokerage Agreement',
      },
    },
    relations: {
      add: 'Add relationship',
      types: {
        seller: 'Seller',
        suitor: 'Suitor',
        buyer: 'Buyer',
        brokered: 'Brokered',
      },
    },
    history: {
      title: 'History',
      fields: {
        relation: 'Relation',
        operation: 'Action',
        time: 'Time',
      },
      operations: {
        INSERT: 'Insert',
        DELETE: 'Delete',
        UPDATE: 'Update',
      },
    },
    history_transactions: {
      title: 'History',
    },
  },
  general: {
    homepage_content: 'Here comes an overview of all customers, boats, etc....',
  },
  validation: {
    startEnd: 'Must be after start',
  },
  history: {
    modified_by: 'Modified by',
    last_modified: 'Last modifed',
  },
}

export default customEnglishMessages
