import gql from 'graphql-tag'
import { GET_LIST, GET_ONE } from 'react-admin'
import { upsertQuery } from '~/helpers'

const fields = gql`
  {
    id
    type
    values
    document_customer {
      id
      customer_id
      customer {
        firstname
        lastname
        informal_salutation
        phones {
          id
          type
          number
        }
        emails {
          id
          type
          email
        }
      }
    }
    document_boat {
      id
      boat_id
      boat {
        name
        model
      }
    }
  }
`

export default upsertQuery(
  {
    [GET_LIST]: {
      buildFields: fields,
    },
    [GET_ONE]: {
      buildFields: fields,
    },
  },
  {
    relations: ['document_customer', 'document_boat'],
  }
)
