import React, { ReactElement } from 'react'

/**
 * recursively trys to find elements of specified type
 * and then injects the given element into their children
 * stops recursion after injection
 * @param children single or multiple children
 * @param inject element to be injected
 * @param types possible target types of elements
 * @returns children param (possible with injected elements)
 */
export const injectElement = (
  children: ReactElement | ReactElement[],
  inject: ReactElement,
  types: string[]
): ReactElement | ReactElement[] => {
  if (React.Children.count(children) < 1) return children
  const injectedChildren = React.Children.map(children, (child) => {
    if (!React.isValidElement(child)) {
      return child
    }

    if ((child as ReactElement).props.children) {
      const props = {
        children: types.includes(child.type?.name)
          ? [
              React.cloneElement(inject),
              ...React.Children.toArray((child as ReactElement).props.children),
            ]
          : injectElement((child as ReactElement).props.children, inject, types),
      }
      child = React.cloneElement(child, props)
    }

    return child
  })
  return Array.isArray(children) ? injectedChildren : injectedChildren[0]
}
