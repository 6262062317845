import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer'
import get from 'lodash/get'
import React, { useContext } from 'react'
import { SelectField, TranslationContext, useLocale } from 'react-admin'
import NumberFormat from 'react-number-format'
import { SectionLabel } from '~/components'
import { standardDecimalFormat } from '~/helpers'

const styles = StyleSheet.create({
  page: {
    marginTop: 20,
    backgroundColor: '#FFFFFF',
    fontSize: 12,
  },
  title: {
    marginLeft: 20,
    marginBottom: 10,
    fontSize: 17,
    fontWeight: 'bold',
    color: '#000080',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  bullet: {
    height: '100%',
  },
  columns: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: 10,
  },
  column: {
    margin: 0,
    padding: 10,
    flex: '0 0 50%',
  },
  section: {},
  sectionLabel: {
    marginLeft: 20,
    fontSize: 14,
    color: '#808080',
  },
  categoryLabel: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#000080',
  },
  categoryDescription: {
    fontSize: 11,
  },
})

const ListItem = ({ children }) => {
  return (
    <View style={styles.row}>
      <View style={styles.bullet}>
        <Text>{'\u2022' + ' '}</Text>
      </View>
      <Text>{children}</Text>
    </View>
  )
}

const translated = (record, prop, locale) => {
  return get(record, `translated_${prop}.${locale}`) || record[prop]
}

const BoatAttribute = ({ attribute }) => {
  const locale = useLocale()
  const {
    attribute: { name, input_type, suffix, choices, selection },
    value,
  } = attribute
  if (!value || !input_type) {
    return null
  }

  const translated_name = translated(attribute.attribute, 'name', locale)

  return input_type === 'select' || input_type === 'shared_select' ? (
    <ListItem key={attribute.id}>
      {translated_name}:{' '}
      <SelectField
        record={attribute}
        source='value'
        choices={input_type === 'select' ? choices || [] : selection?.choices || []}
        optionValue='key'
        optionText={(c) => get(c, `translated_name.${locale}`) || c.key}
      />
    </ListItem>
  ) : input_type === 'numeric' ? (
    <ListItem>
      {translated_name}:{' '}
      <NumberFormat displayType='text' value={value} {...standardDecimalFormat} />
      {translated(attribute.attribute, 'suffix', locale)}
    </ListItem>
  ) : input_type === 'boolean' ? (
    value === 'true' && <ListItem>{translated_name}</ListItem>
  ) : (
    <ListItem>
      {translated_name}: {value}
      {translated(attribute.attribute, 'suffix', locale)}
    </ListItem>
  )
}

const EquipmentListDoc = ({ record: boat, locale = 'de' }) => {
  // Workaround to "renew" locale value of TranslationContext
  // which apparently is lost within the Document tree otherwise
  // i18nProvider probably has to be bridged as well if needed
  // Also see: https://github.com/facebook/react/issues/17275
  const translation = useContext(TranslationContext)
  const translationValue = { ...translation, locale: locale }

  return (
    <Document>
      <TranslationContext.Provider value={translationValue}>
        <Page size='A4' style={styles.page}>
          <Text style={styles.title}>Ausrüstungsliste {boat.model}</Text>
          {boat.boat_section &&
            (boat.boat_section.length > 0 ? (
              boat.boat_section.map((boat_section) => (
                <View style={styles.section} key={boat_section.id}>
                  <Text style={styles.sectionLabel}>
                    <SectionLabel section={boat_section.section} />
                  </Text>
                  <View style={styles.columns}>
                    {boat_section.boat_section_category.map(
                      (equipment) =>
                        equipment.category && (
                          <View style={styles.column} key={equipment.id}>
                            <Text style={styles.categoryLabel}>
                              {equipment.name
                                ? equipment.name
                                : translated(equipment.category, 'name', locale)}{' '}
                            </Text>
                            <Text style={styles.categoryDescription}>
                              {equipment.description
                                ? equipment.description
                                : translated(equipment.category, 'description', locale)}
                            </Text>
                            <ul>
                              {equipment.boat_section_attribute.map((attribute) =>
                                attribute.attribute ? (
                                  <BoatAttribute attribute={attribute} key={attribute.id} />
                                ) : (
                                  <Text>Attribute missing</Text>
                                )
                              )}
                            </ul>
                          </View>
                        )
                    )}
                  </View>
                  {/* <Grid container>
                {boat_section.boat_section_category.map((equipment) =>
                  equipment.category ? (
                    <Grid item md={6} key={equipment.id}>
                      <Typography>
                        {equipment.name
                          ? equipment.name
                          : translated(equipment.category, 'name', locale)}{' '}
                        {(equipment.notes_icon || equipment.notes) && (
                          <Tooltip title={equipment.notes || ''}>
                            <span>
                              <CategoryEntryIcon
                                values={equipment}
                                fontSize='small'
                                style={{ marginBottom: '-0.2em' }}
                              />
                            </span>
                          </Tooltip>
                        )}
                      </Typography>
                      <Typography variant='caption'>
                        {equipment.description
                          ? equipment.description
                          : translated(equipment.category, 'description', locale)}
                      </Typography>
                      <ul>
                        {equipment.boat_section_attribute.map((attribute) =>
                          attribute.attribute ? (
                            <BoatAttribute attribute={attribute} key={attribute.id} />
                          ) : (
                            <LinearProgress />
                          )
                        )}
                      </ul>
                    </Grid>
                  ) : (
                    <LinearProgress />
                  )
                )}
              </Grid> */}
                </View>
              ))
            ) : (
              <View style={styles.section}>
                <Text>Empty</Text>
              </View>
            ))}
        </Page>
      </TranslationContext.Provider>
    </Document>
  )
}

export default EquipmentListDoc
