import { FilterList, FilterListItem } from 'react-admin'

export const ChoiceFilter = ({ choices = [], source, label, icon }) => {
  const key = `filter-${source}`
  return (
    <FilterList key={key} label={label} icon={icon}>
      {choices.map((choice) => (
        <FilterListItem
          key={`${key}-${choice.id}`}
          label={choice.name}
          value={{
            [source]: choice.id,
          }}
        />
      ))}
    </FilterList>
  )
}

export default ChoiceFilter
