import { Translate as TranslateIcon } from '@material-ui/icons'
import ToggleShowButton from './ToggleShowButton'

const ToggleI18nButton = ({ showI18n, setShowI18n, ...rest }) => (
  <ToggleShowButton
    {...rest}
    showState={showI18n}
    setShowState={setShowI18n}
    label='pos.i18n.show'
    hideLabel='pos.i18n.hide'
    icon={TranslateIcon}
  />
)

export default ToggleI18nButton
