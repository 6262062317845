import React from 'react'
import { Edit, ListButton, required, SimpleForm, TextInput, TopToolbar } from 'react-admin'

const RoleName = ({ record }: { record?: { name: string } }) => {
  return <span>Rolle: {record ? `${record.name}` : ''}</span>
}

const RoleEditActions = ({ basePath, data }: { basePath?: string, data?: object }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} label='ra.action.back' />
  </TopToolbar>
)

export const RoleEdit = (props: object) => {
  return (
    <Edit title={<RoleName />} actions={<RoleEditActions />} {...props}>
      <SimpleForm redirect='list'>
        <TextInput source='name' validate={[required()]} label='resources.roles.fields.name' />
      </SimpleForm>
    </Edit>
  )
}

export default RoleEdit
