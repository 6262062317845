import deLocale from '@fullcalendar/core/locales/de'
import enLocale from '@fullcalendar/core/locales/en-gb'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import { DatesSetArg } from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import { IconButton, Typography } from '@material-ui/core'
import { makeStyles, styled } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import CloseIcon from '@material-ui/icons/Close'
import ListIcon from '@material-ui/icons/List'
import { getFilterValuesFromInterval } from '@react-admin/ra-calendar'
import React, { useCallback } from 'react'
import { Button, CreateButton, List, TopToolbar, useLocale } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import { Calendar, CalendarProps, LinkToRecord } from '~/components'
import { changeDates, changeView } from '~/reducers'
import { AppState } from '~/types'

const middleDate = (start: Date | string | number, end: Date | string | number): Date => {
  const startDate = start instanceof Date ? start : new Date(start)
  const endDate = end instanceof Date ? end : new Date(end)
  return new Date((startDate.getTime() + endDate.getTime()) / 2)
}

const useStyles = makeStyles((theme) => ({
  multiline: {
    whiteSpace: 'pre-line',
  },
}))

const AppointmentCalendarViewActions = ({ basePath, setShowCalendar }) => (
  <TopToolbar>
    <CreateButton basePath={basePath} />
    <Button onClick={() => setShowCalendar(false)} label='resources.appointments.list_view'>
      <ListIcon />
    </Button>
  </TopToolbar>
)

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: theme.palette.background.default, //'#fcfcfe',
    color: theme.palette.text.primary, //'rgba(0, 0, 0, 0.87)',
    maxWidth: 300,
    minWidth: 150,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))

const NoTransition = ({ children }) => children

const AppointmentCalendarView = ({ setShowCalendar, types, loadingTypes, ...rest }) => {
  const locale = useLocale()
  const view = useSelector((state: AppState) => state.calendar.view)
  const dates = useSelector((state: AppState) => state.calendar.dates)
  const initialDate = dates.start && dates.end ? middleDate(dates.start, dates.end) : null
  const dispatch = useDispatch()

  const classes = useStyles()

  const handleTransform = (eventData) => {
    return { ...eventData, ...eventData.extendedProps }
  }

  const translateType = (type) =>
    types ? types.choices.find((c) => c.key === type).translated_name || type : type

  const calendarProps: CalendarProps = {
    locales: [enLocale, deLocale],
    locale: locale,
    plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin],
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
    },
    eventContentExtend: (eventInfo, renderInner) => {
      const {
        id,
        title,
        start,
        end,
        extendedProps: { uid, type, appointment_boat, appointment_customer, location, notes },
      } = eventInfo.event
      const { isSelected, unselect } = eventInfo
      const openProps = isSelected
        ? { open: true, interactive: true, TransitionComponent: NoTransition }
        : {}
      return (
        <HtmlTooltip
          {...openProps}
          arrow
          title={
            <>
              {eventInfo.isSelected && (
                <IconButton
                  aria-label='close'
                  style={{ float: 'right' }}
                  size='small'
                  onClick={unselect}
                >
                  <CloseIcon />
                </IconButton>
              )}
              <Typography color='inherit'>{type ? translateType(type) : title}</Typography>
              {location}
              {appointment_boat?.map(
                ({ boat_id, boat }) =>
                  boat && (
                    <div key={boat_id}>
                      <LinkToRecord record={{ id: boat_id }} basePath='boats'>
                        <>
                          {boat.model} ({boat.name})
                        </>
                      </LinkToRecord>
                    </div>
                  )
              )}
              {appointment_customer?.map(
                ({ customer_id, customer }) =>
                  customer && (
                    <div key={customer_id}>
                      <LinkToRecord record={{ id: customer_id }} basePath='customers'>
                        <>
                          {customer.informal_salutation ? (
                            <>
                              <b>{customer.firstname}</b> {customer.lastname}
                            </>
                          ) : (
                            <>
                              {customer.firstname} <b>{customer.lastname}</b>
                            </>
                          )}
                        </>
                      </LinkToRecord>
                      {customer.phones &&
                        customer.phones.map(({ id, type, number }) => (
                          <div key={id}>
                            {number} ({type})
                          </div>
                        ))}
                      {customer.emails &&
                        customer.emails.map(({ id, type, email }) => (
                          <div key={id}>
                            {email} ({type})
                          </div>
                        ))}
                    </div>
                  )
              )}
              <div className={classes.multiline}>{notes}</div>
            </>
          }
        >
          {renderInner({
            ...eventInfo,
            titleOverride: type ? `${translateType(type)}` : `${title}`,
          })}
        </HtmlTooltip>
      )
    },
    initialView: view,
    initialDate: initialDate,
    datesSetExtend: useCallback(
      (dateInfo: DatesSetArg) => {
        if (!(dates.start === dateInfo.startStr && dates.end === dateInfo.endStr)) {
          dispatch(changeDates(dateInfo.startStr, dateInfo.endStr))
        }
        if (view !== dateInfo.view.type) {
          dispatch(changeView(dateInfo.view.type))
        }
      },
      [dispatch, view, dates.start, dates.end]
    ),
  }

  return (
    <>
      <List
        title='resources.appointments.title'
        filterDefaultValues={getFilterValuesFromInterval()}
        perPage={1000}
        actions={<AppointmentCalendarViewActions setShowCalendar={setShowCalendar} />}
        {...rest}
        pagination={false}
        hasCreate
      >
        <Calendar transform={handleTransform} {...calendarProps} />
      </List>
    </>
  )
}

export default AppointmentCalendarView
