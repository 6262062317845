import gql from 'graphql-tag'
import { GET_LIST, GET_ONE } from 'react-admin'
import { upsertQuery } from '~/helpers'

const fields = gql`
  {
    id
    manufacturer
    model
    name
    build_year
    first_launch
    status
    asking_price
    price_reductions
    lower_price_limit
    summer_storage
    winter_storage
    boat_type
    sailboat_type
    sailboat_rigging_type
    motorboat_type
    motorboat_planing_type
    previous_owners
    goods_type
    condition
    build_number
    ce_labeling
    hull_material
    hull_color
    keel_type
    lake_constance_approval
    length_total
    length_waterline
    width
    draft_max
    draft_min
    displacement
    ballast
    created_at
    attachments {
      id
      created_at
      updated_at
      type
      uri
      content_type
      title
    }
    linked_comments {
      id
      created_at
      updated_at
      type
      comment
      relation
      relation_id
      pictures {
        id
        created_at
        updated_at
        type
        uri
        content_type
        title
        caption
      }
    }
    boat_customer {
      id
      relation_type
      customer_id
      customer {
        id
        firstname
        lastname
        informal_salutation
        phones {
          id
          type
          number
        }
      }
    }
    boat_section(order_by: { section: { position: asc } }) {
      id
      section_id
      section {
        slug
        translated_name
      }
      boat_section_category(order_by: { category: { _order: asc } }) {
        id
        category_id
        name
        description
        notes
        notes_icon
        category {
          name
          translated_name
          description
          translated_description
        }
        boat_section_attribute(order_by: { attribute: { _order: asc } }) {
          id
          attribute_id
          value
          attribute {
            id
            name
            translated_name
            suffix
            translated_suffix
            input_type
            choices {
              id
              key
              translated_name
            }
            selection {
              id
              choices {
                id
                key
                translated_name
              }
            }
          }
        }
      }
    }
    appointment_boat {
      id
      appointment_id
      appointment {
        uid
        start
        end
        title
        type
        allDay
        appointment_customer {
          id
          customer_id
          customer {
            firstname
            lastname
            informal_salutation
          }
        }
      }
    }
  }
`

export default upsertQuery(
  {
    [GET_LIST]: {
      buildFields: fields,
    },
    [GET_ONE]: {
      buildFields: fields,
    },
  },
  {
    relations: ['boat_customer', 'boat_section', 'boat_section_category', 'boat_section_attribute'],
  }
)

/*
todo: Same as in customer query 
gql`
        {
          id
          manufacturer
          model
          name
          created_at
        }
      `
*/
