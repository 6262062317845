import { get } from 'flocky'
import {
  CREATE,
  DELETE,
  DELETE_MANY,
  GET_LIST,
  GET_MANY,
  GET_MANY_REFERENCE,
  GET_ONE,
  UPDATE,
  UPDATE_MANY,
} from 'ra-core'
import { backendPlus, fileStorage, generateClient } from '~/helpers'
import buildQueryInit from './buildQuery'
import {
  ALL_TYPES as INNER_ALL_TYPES,
  MUTATION_TYPES as INNER_MUTATION_TYPES,
  QUERY_TYPES as INNER_QUERY_TYPES,
} from './constants'
import defaultResolveIntrospection from './introspection'

export const QUERY_TYPES = INNER_QUERY_TYPES
export const MUTATION_TYPES = INNER_MUTATION_TYPES
export const ALL_TYPES = INNER_ALL_TYPES

const defaultOptions = {
  resolveIntrospection: defaultResolveIntrospection,
  introspection: {
    operationNames: {
      [GET_LIST]: (resource) => `${resource.name}`,
      [GET_ONE]: (resource) => `${resource.name}`,
      [GET_MANY]: (resource) => `${resource.name}`,
      [GET_MANY_REFERENCE]: (resource) => `${resource.name}`,
      [CREATE]: (resource) => `insert_${resource.name}`,
      [UPDATE]: (resource) => `update_${resource.name}`,
      [UPDATE_MANY]: (resource) => `update_${resource.name}`,
      [DELETE]: (resource) => `delete_${resource.name}`,
      [DELETE_MANY]: (resource) => `delete_${resource.name}`,
    },
    exclude: undefined,
    include: undefined,
  },
}

const getOptions = (options, aorFetchType, resource) => {
  if (typeof options === 'function') {
    return options(resource, aorFetchType)
  }

  return options
}

export const raDataGraphql = (options) => {
  const {
    client: clientOptions,
    introspection,
    resolveIntrospection,
    buildQuery: buildQueryOptions = {},
    override = {},
    ...otherOptions
  } = { ...defaultOptions, ...options }

  if (override && process.env.NODE_ENV === 'production') {
    console.warn(
      // eslint-disable-line
      'The override option is deprecated. You should instead wrap the buildQuery function provided by the dataProvider you use.'
    )
  }

  let introspectionResults = {
    resources: [],
  }
  const { auth, storage } = backendPlus(clientOptions)
  const fStorage = fileStorage({ auth, storage })

  const hbpWithCookie = backendPlus({ ...clientOptions, useCookies: true })
  const authWithCookie = hbpWithCookie.auth

  // hack to additionally have auth enabled with cookie for direct file access
  auth.orgLogin = auth.login
  auth.login = (params) => {
    authWithCookie.login(params)
    return auth.orgLogin(params)
  }
  auth.orgLogout = auth.logout
  auth.logout = () => {
    authWithCookie.logout()
    return auth.orgLogout()
  }

  // todo fetch introspection on auth change - create singleton object
  const client = generateClient({
    ...clientOptions,
    auth,
    onAuthStateChanged: (d) => console.log('auth state', d),
  })

  const fetchIntrospection = async (reload = false) => {
    if (!reload && introspectionResults) {
      return introspectionResults
    }

    if (introspection) {
      introspectionResults = await resolveIntrospection(client, introspection)
    }
    return introspectionResults
  }

  const buildQuery = buildQueryInit(buildQueryOptions)(fetchIntrospection, otherOptions)

  const dataProvider = async (aorFetchType, resource, params) => {
    const overriddenBuildQuery = get(override, `${resource}.${aorFetchType}`)

    try {
      const buildQueryResults = await buildQuery(aorFetchType, resource, params, fStorage)
      const { parseResponse, ...query } = overriddenBuildQuery
        ? {
            ...buildQueryResults,
            ...overriddenBuildQuery(params),
          }
        : buildQueryResults

      const operation = getQueryOperation(query.query)

      if (operation === 'query') {
        const apolloQuery = {
          ...query,
          fetchPolicy: 'network-only',
          ...getOptions(otherOptions.query, aorFetchType, resource),
        }

        return client.query(apolloQuery).then((response) => parseResponse(response))
      }

      const apolloQuery = {
        mutation: query.query,
        variables: query.variables,
        ...getOptions(otherOptions.mutation, aorFetchType, resource),
      }

      return client.mutate(apolloQuery).then(parseResponse)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  dataProvider.observeRequest = (aorFetchType, resource, params) => {
    const { parseResponse, ...query } = buildQuery(aorFetchType, resource, params)

    const apolloQuery = {
      ...query,
      ...getOptions(otherOptions.watchQuery, aorFetchType, resource),
    }

    return client.watchQuery(apolloQuery).then(parseResponse)
  }

  dataProvider.saga = () => {}

  return { auth, storage, client, dataProvider }
}

const getQueryOperation = (query) => {
  if (query && query.definitions && query.definitions.length > 0) {
    return query.definitions[0].operation
  }

  throw new Error('Unable to determine the query operation')
}
