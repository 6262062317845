import { useState } from 'react'
import { Create, ListButton, SimpleForm, TopToolbar } from 'react-admin'
import { ToggleShowButton, UpsertDocument } from '~/components'

const DocumentCreateActions = ({ basePath, data, showPreview, setShowPreview }) => (
  <TopToolbar>
    <ToggleShowButton showState={showPreview} setShowState={setShowPreview} label='PDF Preview' />
    <ListButton basePath={basePath} record={data} label='ra.action.back' />
  </TopToolbar>
)

const DocumentCreate = (props: object) => {
  const [showPreview, setShowPreview] = useState(false)

  return (
    <Create
      title='resources.documents.create'
      actions={<DocumentCreateActions showPreview={showPreview} setShowPreview={setShowPreview} />}
      {...props}
    >
      <SimpleForm redirect='list' warnWhenUnsavedChanges>
        <UpsertDocument {...props} showPreview={showPreview} />
      </SimpleForm>
    </Create>
  )
}

export default DocumentCreate
