import { Edit as EditIcon, Event as AppointmentIcon } from '@material-ui/icons'
import React, { useCallback, useRef, useState } from 'react'
import {
  ArrayField,
  Button,
  ChipField,
  Datagrid,
  DateField,
  FunctionField,
  linkToRecord,
  ResourceContextProvider,
  SimpleForm,
  SingleFieldList,
  TextField,
  TextInput,
  useMutation,
  useRefresh,
} from 'react-admin'
import { Link } from 'react-router-dom'
import {
  CreateModal,
  CustomerChipField,
  EditModal,
  Empty,
  SelectionField,
  UpsertAppointment,
} from '~/components'

export const EditAppointments = (props: object) => {
  const { relation, stateCreate } = props
  const refresh = useRefresh()
  const [mutate] = useMutation()
  const [relName, relId] = relation

  const refCreateCanvas = useRef(null)

  const createAppointment = useCallback(
    async ({}) => {
      await mutate(
        {
          type: 'create',
          resource: 'appointments',
          payload: {
            data: {
              // TODO
            },
          },
        },
        { returnPromise: true }
      )
      refresh()
      stateCreate[1](false)
    },
    [stateCreate, mutate, refresh, relation]
  )

  const stateEditModal = useState(false)
  const [editAppointmentId, edit] = useState(0)

  const editAppointment = useCallback(
    async ({ id }) => {
      await mutate(
        {
          type: 'update',
          resource: 'appointments',
          payload: {
            id,
            data: {
              // TODO
            },
          },
        },
        { returnPromise: true }
      )
      refresh()
      stateEditModal[1](false)
    },
    [stateEditModal, mutate, refresh]
  )

  // todo: move this somehow to editdialog and add button to datagrid
  // can't figure out how to access List from Create-, EditModal (has to be possible)
  const EditModalButton = ({ source, record }) => (
    <Button
      component={Link}
      to={linkToRecord('/appointments', record.appointment_id, 'edit')}
      // onClick={(e) => {
      //   edit(record.appointment_id)
      //   stateEditModal[1](true)
      // }}
      label='ra.action.edit'
    >
      <EditIcon />
    </Button>
  )

  return (
    <>
      <ResourceContextProvider value='appointments'>
        <Empty {...props} icon={<AppointmentIcon />} resource='appointments'>
          <Datagrid>
            <SelectionField source='appointment.type' label='resources.appointments.fields.type' />
            <TextField source='appointment.title' label='resources.appointments.fields.title' />
            <FunctionField
              source='appointment.start'
              label='resources.appointments.fields.start'
              render={({ appointment }) => (
                <DateField source='appointment.start' showTime={!appointment.allDay} />
              )}
            />
            <FunctionField
              source='appointment.end'
              label='resources.appointments.fields.end'
              render={({ appointment }) => (
                <DateField source='appointment.end' showTime={!appointment.allDay} />
              )}
            />
            {relName !== 'customers' && (
              <ArrayField
                source='appointment.appointment_customer'
                label='resources.appointments.fields.appointment_customer'
                fieldKey='customer_id'
                basePath='/customers'
              >
                <SingleFieldList linkType='show'>
                  <CustomerChipField source='customer' />
                </SingleFieldList>
              </ArrayField>
            )}
            {relName !== 'boats' && (
              <ArrayField
                source='appointment.appointment_boat'
                label='resources.appointments.fields.appointment_boat'
                fieldKey='boat_id'
                basePath='/boats'
              >
                <SingleFieldList linkType='show'>
                  <ChipField source='boat.model' />
                </SingleFieldList>
              </ArrayField>
            )}
            <EditModalButton />
            {/* <DeleteButton redirect={false} /> */}
          </Datagrid>
        </Empty>
      </ResourceContextProvider>

      <CreateModal
        title='resources.appointments.create'
        state={stateCreate}
        {...props}
        resource='appointments'
      >
        <SimpleForm redirect={false} save={createAppointment}>
          <TextInput source='title' />
        </SimpleForm>
      </CreateModal>

      <EditModal state={stateEditModal} {...props} resource='appointments' id={editAppointmentId}>
        <SimpleForm redirect={false} save={editAppointment}>
          <UpsertAppointment />
        </SimpleForm>
      </EditModal>
    </>
  )
}

export default EditAppointments
