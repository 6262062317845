import { useState } from 'react'
import { Create, SimpleForm, TopToolbar } from 'react-admin'
import { ToggleI18nButton, UpsertSelection } from '~/components'

const SelectionCreateActions = ({ basePath, data, showI18n, setShowI18n }) => (
  <TopToolbar>
    <ToggleI18nButton showI18n={showI18n} setShowI18n={setShowI18n} />
  </TopToolbar>
)

const SelectionCreate = (props: object) => {
  const [showI18n, setShowI18n] = useState(true)

  return (
    <Create
      title='resources.selections.create'
      actions={<SelectionCreateActions showI18n={showI18n} setShowI18n={setShowI18n} />}
      {...props}
    >
      <SimpleForm redirect='list' warnWhenUnsavedChanges>
        <UpsertSelection {...props} showI18n={showI18n} />
      </SimpleForm>
    </Create>
  )
}

export default SelectionCreate
