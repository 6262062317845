import { Reducer } from 'redux'
import { ThemeName } from '~/types'
import { changeTheme, CHANGE_THEME } from './actions.ts'

type State = ThemeName
type Action = ReturnType<typeof changeTheme> | { type: 'OTHER_ACTION'; payload?: any }

export * from './actions'
/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (previousState = 'light', action): Reducer<State, Action> => {
  if (action.type === CHANGE_THEME) {
    return action.payload
  }
  return previousState
}
