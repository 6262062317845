import { print } from 'graphql'
import {
  buildApolloArgs,
  buildArgs,
  buildFields,
  buildGqlQuery,
  buildMetaArgs,
  buildVariables as defaultBuildVariables,
  defaultGetResponseParser,
} from 'ra-data-hasura'
import getFinalType from './getFinalType'

const buildGqlQueryDefaults = {
  buildFields,
  buildMetaArgs,
  buildArgs,
  buildApolloArgs,
}

const castParams = (params, resource) => {
  const { id, target = 'id' } = params
  if (!id) return

  const typedParam = resource.type.fields.find((f) => target === f.name)
  if (!typedParam) return

  switch (getFinalType(typedParam.type).name) {
    case 'Int':
      params[target] = parseInt(params[target])
      break
  }
}

const lookupResource = async (fetchIntrospection, resourceName, forced = false) => {
  const introspectionResults = await fetchIntrospection(forced)
  console.log(introspectionResults)
  const resource = introspectionResults.resources.find((r) => r.type.name === resourceName)

  if (!resource) {
    if (!forced) {
      return lookupResource(fetchIntrospection, resourceName, true)
    }
    throw new Error(
      `Unknown resource ${resourceName}. No resources were found. Make sure it has been declared on your server side schema and check if your Authorization header is properly set up.`
    )
  }

  return { introspectionResults, resource }
}

export const buildQuery = ({ queries, ...options }) => (fetchIntrospection) => async (
  aorFetchType,
  resourceName,
  params,
  fileStorage // TODO: think of better (separated) handling of file uploads
) => {
  const { introspectionResults, resource } = await lookupResource(fetchIntrospection, resourceName)
  const queryType = resource[aorFetchType]

  if (!queryType) {
    throw new Error(
      `No query or mutation matching fetch type ${aorFetchType} could be found for resource ${resource.type.name}`
    )
  }

  const resourceQuery = (queries[resourceName] || {})[aorFetchType] || {}
  let { extendFields, buildFields, buildMetaArgs, buildArgs, buildApolloArgs } = {
    ...buildGqlQueryDefaults,
    ...options,
    ...resourceQuery,
  }
  let { query, variables, parseResponse } = resourceQuery

  // some helpers - shortcuts
  if (typeof buildFields === 'object' && buildFields.kind === 'Document') {
    buildFields = ((gqlObject: any) => () => gqlObject.definitions[0].selectionSet.selections)(
      buildFields
    )
  }

  // cast fields
  // util fixed: https://github.com/hasura/ra-data-hasura/issues/50
  castParams(params, resource)

  variables = await (variables || defaultBuildVariables)(introspectionResults)(
    resource,
    aorFetchType,
    params,
    queryType,
    fileStorage
  )
  query = (query || buildGqlQuery)(
    introspectionResults,
    buildFields,
    buildMetaArgs,
    buildArgs,
    buildApolloArgs
  )(resource, aorFetchType, queryType, variables)
  parseResponse = (parseResponse || defaultGetResponseParser)(introspectionResults)(
    aorFetchType,
    resource,
    queryType
  )

  // auto extend graphql query
  if (Array.isArray(extendFields)) {
    const resourceFields = resource.type.fields.map((f) => f.name)
    // todo: first selection should be the resourceName -- maybe check?
    const selections = query.definitions[0].selectionSet.selections[0].selectionSet.selections
    const selectionFields = selections.map((s) => s.name.value)
    // extend fields
    selections.push(
      ...extendFields
        // check if fields are to be added
        .filter((field) => field[aorFetchType] && !(field.ignore || []).includes(resourceName))
        // flatten all fields to add
        .map((field) => field[aorFetchType].definitions[0].selectionSet.selections)
        .flat()
        // only fetch fields supported by the resource type
        .filter((selection) => resourceFields.includes(selection.name.value))
        // apperently the previous query is reused (cashed) so we prevent adding fields twice
        // skip if already defined
        .filter((selection) => !selectionFields.includes(selection.name.value))
    )
  }

  console.groupCollapsed(`📯 ${resourceName} ${aorFetchType} `)
  console.debug(resource.type)
  console.groupCollapsed(`❓ query`)
  console.debug(query)
  console.debug(print(query))
  console.groupEnd()
  console.groupCollapsed(`📦 variables`)
  console.debug(variables)
  console.debug(JSON.stringify(variables))
  console.groupEnd()
  console.groupCollapsed(`💽 form data`)
  console.debug(params)
  console.groupEnd()
  console.groupEnd()
  parseResponse = ((parseResponse) => (introspectionResults) => {
    const result = parseResponse(introspectionResults)
    console.debug(`💚 ${resourceName} ${aorFetchType} `, introspectionResults, result)
    return result
  })(parseResponse)
  return {
    query,
    variables,
    parseResponse,
  }
}

export default buildQuery
