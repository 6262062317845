import Queue from '@material-ui/icons/Queue'
import { isArray, isObject } from 'lodash'
import PropTypes from 'prop-types'
import { stringify } from 'query-string'
import { Record, useResourceContext } from 'ra-core'
import * as React from 'react'
import { memo, ReactElement } from 'react'
import { Button, ButtonProps } from 'react-admin'
import { Link } from 'react-router-dom'

export const RecursiveCloneButton = (props: RecursiveCloneButtonProps) => {
  const {
    basePath = '',
    label = 'ra.action.clone',
    scrollToTop = true,
    record,
    icon = defaultIcon,
    ...rest
  } = props

  const resource = useResourceContext()
  const pathname = basePath ? `${basePath}/create` : `/${resource}/create`
  return (
    <Button
      component={Link}
      to={
        record
          ? {
              pathname,
              search: stringify({
                source: JSON.stringify(recursiveOmitId(record)),
              }),
              state: { _scrollToTop: scrollToTop },
            }
          : pathname
      }
      label={label}
      onClick={stopPropagation}
      {...rest}
    >
      {icon}
    </Button>
  )
}

const defaultIcon = <Queue />

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = (e) => e.stopPropagation()

const recursiveOmitId = ({ id, history, ...rest }: Record) => {
  const recordWithoutIds = {}
  for (const key in rest) {
    if (rest.hasOwnProperty(key) && isArray(rest[key])) {
      if (!key.endsWith('Ids'))
        recordWithoutIds[key] = rest[key].map((a) => (isObject(a) ? recursiveOmitId(a) : a))
    } else {
      recordWithoutIds[key] = rest[key]
    }
  }
  return recordWithoutIds
}

interface Props {
  basePath?: string
  record?: Record
  icon?: ReactElement
  scrollToTop?: boolean
}

export type RecursiveCloneButtonProps = Props & ButtonProps

RecursiveCloneButton.propTypes = {
  basePath: PropTypes.string,
  icon: PropTypes.element,
  label: PropTypes.string,
  record: PropTypes.any,
}

export default memo(RecursiveCloneButton)
