import gql from 'graphql-tag'
import { GET_LIST, GET_MANY_REFERENCE, UPDATE } from 'react-admin'

const AttributeQueries = {
  [GET_LIST]: {
    buildFields: gql`
      {
        id
        name
        description
        default_value
        suffix
        input_type
        category_id
      }
    `,
  },
  [UPDATE]: {
    buildFields: gql`
      {
        id
      }
    `,
  },
  [GET_MANY_REFERENCE]: {
    buildFields: gql`
      {
        id
        name
      }
    `,
  },
}
export default AttributeQueries
