import DateFnsUtils from '@date-io/date-fns'
import { Grid } from '@material-ui/core'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import deLocale from 'date-fns/locale/de'
import enLocale from 'date-fns/locale/en-US'
import React from 'react'
import {
  AutocompleteArrayInput,
  BooleanInput,
  FormDataConsumer,
  ReferenceArrayInput,
  required,
  TextInput,
  useLocale,
} from 'react-admin'
import { useForm } from 'react-final-form'
import {
  AutocompleteTextInput,
  CreateCustomer,
  KeyboardDateInput,
  KeyboardTimeInput,
  SelectionInput,
} from '~/components'
import './UpsertAppointment.css'

const dateLocales = {
  en: enLocale,
  de: deLocale,
}

const endValidation = (value, allValues) => {
  if (!value || !allValues.start) {
    return 'Event has no duration'
  }
  if (value - allValues.start <= 0) {
    return 'validation.startEnd'
  }
  return undefined
}

const titleValidation = (value, allValues) => {
  if (!value && !allValues.type) {
    return 'ra.validation.required'
  }
  return undefined
}

export const UpsertAppointment = ({ record }) => {
  const locale = useLocale()
  const dateProviderOptions = { utils: DateFnsUtils, locale: dateLocales[locale] }
  const dateInputOptions = {
    autoOk: true,
    variant: 'inline',
    format: 'yyyy/MM/dd',
    placeholder: 'yyyy/MM/dd',
  }
  const dateTimeInputOptions = {
    ...dateInputOptions,
    format: 'HH:mm',
    placeholder: 'HH:mm',
    keyboardIcon: <AccessTimeIcon />,
    ampm: false,
    views: ['hours', 'minutes'],
  }
  const validateStart = [required()]
  const validateEnd = [required(), endValidation]
  const validateTitle = [titleValidation]

  const hour = 60 * 60 * 1000
  const { change, getState } = useForm()
  const { values } = getState()
  const [duration, setDuration] = React.useState(
    Date.parse(values.end) - Date.parse(values.start) || hour
  )

  const handleStartChange = (value: Date) => {
    change('end', new Date(value.getTime() + duration))
  }

  const handleEndChange = (value: Date) => {
    setDuration(value.getTime() - Date.parse(values.start))
  }

  const handleTypeChange = ({ target }) => {
    if (target.value) {
      change('title', null)
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item md={2}>
        <SelectionInput
          source='type'
          label='resources.appointments.fields.type'
          fullWidth
          allowEmpty
          emptyText='resources.appointments.types.none'
          onChange={handleTypeChange}
        />
      </Grid>
      <FormDataConsumer>
        {({ formData }) => (
          <>
            <Grid item md={formData.allDay ? 4 : 2}>
              <KeyboardDateInput
                source='start'
                label='resources.appointments.fields.start'
                options={dateInputOptions}
                providerOptions={dateProviderOptions}
                onlyDate
                onChange={handleStartChange}
                validate={validateStart}
              />
            </Grid>
            {!formData.allDay && (
              <Grid item md={2}>
                <KeyboardTimeInput
                  source='start'
                  label='resources.appointments.fields.start'
                  options={dateTimeInputOptions}
                  providerOptions={dateProviderOptions}
                  onlyTime
                  onChange={handleStartChange}
                  validate={validateStart}
                />
              </Grid>
            )}
          </>
        )}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }) => (
          <>
            <Grid item md={formData.allDay ? 4 : 2}>
              <KeyboardDateInput
                source='end'
                label='resources.appointments.fields.end'
                options={dateInputOptions}
                providerOptions={dateProviderOptions}
                onlyDate
                onChange={handleEndChange}
                validate={validateEnd}
              />
            </Grid>
            {!formData.allDay && (
              <Grid item md={2}>
                <KeyboardTimeInput
                  source='end'
                  label='resources.appointments.fields.end'
                  options={dateTimeInputOptions}
                  providerOptions={dateProviderOptions}
                  onlyTime
                  onChange={handleEndChange}
                  validate={validateEnd}
                />
              </Grid>
            )}
          </>
        )}
      </FormDataConsumer>
      <Grid item md={2}>
        <BooleanInput source='allDay' label='resources.appointments.fields.allDay' />
      </Grid>
      <Grid item md={6}>
        <FormDataConsumer>
          {({ formData }) => (
            <ReferenceArrayInput
              source='appointment_boat'
              reference='boats'
              label='resources.appointments.fields.appointment_boat'
              format={(rels) => (rels ? rels.map((rel) => rel.boat_id) : [])}
              parse={(ids, source) =>
                ids.map(
                  (id) => formData[source]?.find((rel) => rel.boat_id === id) || { boat_id: id }
                )
              }
              enableGetChoices={({ q }) => (q ? q.length >= 1 : false)}
            >
              <AutocompleteArrayInput
                optionText={(record) => record && `${record.model} (${record.name})`}
                shouldRenderSuggestions={(value) => value.length >= 1}
              />
            </ReferenceArrayInput>
          )}
        </FormDataConsumer>
      </Grid>
      <Grid item md={6}>
        <FormDataConsumer>
          {({ formData }) => (
            <ReferenceArrayInput
              source='appointment_customer'
              reference='customers'
              label='resources.appointments.fields.appointment_customer'
              format={(rels) => (rels ? rels.map((rel) => rel.customer_id) : [])}
              parse={(ids, source) =>
                ids.map(
                  (id) =>
                    formData[source]?.find((rel) => rel.customer_id === id) || { customer_id: id }
                )
              }
              enableGetChoices={({ q }) => (q ? q.length >= 1 : false)}
            >
              <AutocompleteArrayInput
                optionText={(record) =>
                  record && record.id === '@@ra-create'
                    ? record.name
                    : `${record.firstname} ${record.lastname}`
                }
                shouldRenderSuggestions={(value) => value.length >= 1}
                create={<CreateCustomer />}
              />
            </ReferenceArrayInput>
          )}
        </FormDataConsumer>
      </Grid>
      <Grid item md={6}>
        <FormDataConsumer>
          {({ formData }) => (
            <TextInput
              source='title'
              fullWidth
              label='resources.appointments.fields.title'
              validate={validateTitle}
              disabled={!!formData.type}
            />
          )}
        </FormDataConsumer>
      </Grid>
      <Grid item md={6}>
        <AutocompleteTextInput
          source='location'
          fullWidth
          label='resources.appointments.fields.location'
        />
      </Grid>
      <Grid item md={12}>
        <TextInput multiline source='notes' fullWidth label='resources.appointments.fields.notes' />
      </Grid>
    </Grid>
  )
}

export default UpsertAppointment
