import { gql } from '@apollo/client'

export const selectionChoicesQuery = ({ resource, source, locale }) => gql`
{
  choices(where: {selection: {_and: {context: {_eq: "resources.${resource}"}, name: {_eq: "${source}"}}}}) {
    key
    translated_name(path: "${locale}")
  }
}
`

export const selectionIdQuery = ({ resource, source }) => gql`
{
  selections(where: {_and: {context: {_eq: "resources.${resource}"}, name: {_eq: "${source}"}}}) {
    id
  }
}
`
