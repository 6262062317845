import { gql, useQuery } from '@apollo/client'
import { BooleanInput, email, PasswordInput, required, SelectInput, TextInput } from 'react-admin'

export const UpsertUser = (props) => {
  const { data } = useQuery(
    gql`
      {
        auth_roles {
          role
        }
      }
    `
  )
  let roles = (data ? data.auth_roles : []).map(({ role }) => ({ id: role, name: role }))

  return (
    <>
      <TextInput
        fullWidth
        redirect='list'
        source='email'
        type='email'
        validate={[required(), email()]}
        label='resources.users.fields.email'
      />
      {props.create && (
        <PasswordInput
          source='password'
          validate={[required()]}
          label='resources.users.fields.password'
        />
      )}
      <SelectInput
        source='default_role'
        choices={roles}
        validate={[required()]}
        label='resources.users.fields.role'
      />
      {!props.create && (
        <>
          <TextInput
            fullWidth
            redirect='list'
            source='user.display_name'
            validate={[required()]}
            label='resources.users.fields.username'
          />
          <BooleanInput source='active' label='resources.users.fields.active' />
        </>
      )}
    </>
  )
}

export default UpsertUser
