import { cloneElement } from 'react'
import { TextField } from 'react-admin'

export const DynamicField = ({ component = <TextField />, record, source, ...rest }) => {
  return cloneElement(component, {
    ...rest,
    record: { ...record, _: eval('`' + source + '`') },
    source: '_',
  })
}

export default DynamicField
