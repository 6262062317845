import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'

// FIXME: react-admin version throws Legacy context API error in StrictMode
ReactDOM.render(
  <React.Fragment>
    {/*<React.StrictMode>*/}
    <App />
    {/*</React.StrictMode>*/}
  </React.Fragment>,
  document.getElementById('root')
)
