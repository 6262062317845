import React from 'react'
import {
  CreateButton,
  Datagrid,
  DateField,
  EditButton,
  ExportButton,
  Filter,
  FunctionField,
  List,
  ListButton,
  TextField,
  TextInput,
  TopToolbar,
} from 'react-admin'
import { RecursiveCloneButton, SectionLabel } from '~/components'

const CategoriesFilter = (props: object) => (
  <Filter {...props}>
    <TextInput label='pos.search' source='name' alwaysOn />
  </Filter>
)

const CategoryListActions = ({ basePath }) => (
  <TopToolbar>
    <CreateButton basePath={basePath} />
    <ListButton basePath='/sections' label='resources.sections.title' />
    <ExportButton />
  </TopToolbar>
)

const CategoryList = (props: object) => {
  return (
    <List
      title='resources.categories.title'
      filters={<CategoriesFilter />}
      actions={<CategoryListActions />}
      bulkActionButtons={false}
      sort={{ field: 'section_id', order: 'ASC' }}
      {...props}
    >
      <Datagrid rowClick='edit'>
        <TextField source='name' label='resources.categories.fields.name' />
        <TextField source='description' label='resources.categories.fields.description' />
        <FunctionField
          source='section_id'
          label='resources.categories.fields.section'
          render={(record) => (record.section ? <SectionLabel section={record.section} /> : '')}
        />
        <FunctionField
          source='attributes'
          label='resources.categories.fields.attribute_count'
          render={({ attributes }) => (attributes ? attributes.length : '')}
        />
        <TextField source='history[0].user.display_name' label='history.modified_by' />
        <DateField source='history[0].time' showTime label='history.last_modified' />
        <RecursiveCloneButton />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export default CategoryList
