import { ApolloProvider } from '@apollo/client/react'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { NhostAuthProvider, useAuth } from '@nhost/react-auth'
import { createBrowserHistory } from 'history'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import { AdminContext, CoreAdminRouter, Loading, Logout, Resource } from 'react-admin'
import { Provider } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { authProvider as authProviderInit, raDataGraphql, StorageContext } from '~/helpers'
import i18n from '~/i18n'
import { Layout, Login } from '~/layouts'
import { Configuration, Dashboard } from '~/pages'
import createStore from '~/reducers'
import {
  AppointmentCreate,
  AppointmentEdit,
  AppointmentIcon,
  AppointmentList,
  AppointmentQueries,
  AttributeQueries,
  BoatCreate,
  BoatEdit,
  BoatIcon,
  BoatList,
  BoatQueries,
  BoatShow,
  CategoryCreate,
  CategoryEdit,
  CategoryIcon,
  CategoryList,
  CategoryQueries,
  CommentQueries,
  CustomerCreate,
  CustomerEdit,
  CustomerIcon,
  CustomerList,
  CustomerQueries,
  CustomerShow,
  DocumentCreate,
  DocumentEdit,
  DocumentIcon,
  DocumentList,
  DocumentQueries,
  HistoryIcon,
  HistoryList,
  HistoryQueries,
  HistoryTransactionIcon,
  HistoryTransactionList,
  HistoryTransactionQueries,
  RoleCreate,
  RoleEdit,
  RoleIcon,
  RoleList,
  SectionEdit,
  SectionIcon,
  SectionList,
  SectionQueries,
  SelectionCreate,
  SelectionEdit,
  SelectionIcon,
  SelectionList,
  SelectionQueries,
  SupplierCreate,
  SupplierEdit,
  SupplierList,
  SupplierQueries,
  UserCreate,
  UserEdit,
  UserList,
  UserQueries,
} from '~/resources'
import './App.css'
import { fileStorage } from './helpers'

const { REACT_APP_GRAPHQL_URI, REACT_APP_HBP_URI = 'http://localhost:3005' } = process.env

const AuthGate = ({ children }) => {
  const { signedIn } = useAuth()

  if (signedIn === null) {
    return <Loading />
  }

  if (!signedIn) {
    return <Redirect to='/login' />
  }

  return children
}

export const { dataProvider, auth, storage, client } = raDataGraphql({
  client: {
    baseURL: REACT_APP_HBP_URI,
    graphqlURL: REACT_APP_GRAPHQL_URI,
    useCookies: false,
  },
  buildQuery: {
    queries: {
      history_transactions: HistoryTransactionQueries,
      history: HistoryQueries,
      customers: CustomerQueries,
      boats: BoatQueries,
      categories: CategoryQueries,
      attributes: AttributeQueries,
      auth_accounts: UserQueries,
      suppliers: SupplierQueries,
      sections: SectionQueries,
      selections: SelectionQueries,
      comments: CommentQueries,
      appointments: AppointmentQueries,
      documents: DocumentQueries,
    },
    extendFields: [HistoryQueries.extendFields],
  },
})

// ! important: resources and custom queries have to be added here
export const App = () => {
  const history = createBrowserHistory()
  const authProvider = authProviderInit(auth)
  const i18nProvider = polyglotI18nProvider((locale) => i18n[locale] || i18n['de'])
  const fStore = fileStorage({ auth, storage })

  const { store, persistor } = createStore({
    authProvider,
    dataProvider,
    history,
  })

  // todo: is there a better way to set default language?
  // persistor.subscribe(() => i18nProvider.changeLocale(store.getState().locale))
  return (
    <NhostAuthProvider auth={auth}>
      <ApolloProvider client={client}>
        <StorageContext.Provider value={fStore}>
          <Provider store={store}>
            <PersistGate persistor={persistor}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <AdminContext
                  dataProvider={dataProvider}
                  authProvider={authProvider}
                  i18nProvider={i18nProvider}
                  history={history}
                >
                  <RouteSwitch />
                </AdminContext>
              </MuiPickersUtilsProvider>
            </PersistGate>
          </Provider>
        </StorageContext.Provider>
      </ApolloProvider>
    </NhostAuthProvider>
  )
}
const RouteSwitch = (props) => {
  const title = 'yachthandel24'

  /* epanded from: https://github.com/marmelab/react-admin/blob/master/packages/ra-core/src/core/CoreAdminUI.tsx */
  return (
    <Switch>
      <Route exact path='/login' render={(props) => <Login {...props} title={title} />} />
      <AuthGate>
        <Route
          path='/'
          render={(props) => (
            <CoreAdminRouter
              catchAll={() => null}
              customRoutes={[
                <Route exact path='/configuration' render={() => <Configuration />} />,
                <Route exact path='/boats/guided_create'>
                  <Redirect to='/boats/create/guided' />
                </Route>,
                <Route
                  exact
                  path='/boats/guided_edit/:id'
                  render={({ match }) => <Redirect to={`/boats/${match.params.id}/guided`} />}
                />,
              ]}
              dashboard={Dashboard}
              layout={Layout}
              loading={() => null}
              logout={<Logout />}
              ready={false}
              title={title}
              {...props}
            >
              <Resource
                name='customers'
                icon={CustomerIcon}
                list={CustomerList}
                show={CustomerShow}
                edit={CustomerEdit}
                create={CustomerCreate}
              />
              <Resource
                name='suppliers'
                icon={CustomerIcon}
                create={SupplierCreate}
                list={SupplierList}
                edit={SupplierEdit}
              />
              <Resource
                name='boats'
                icon={BoatIcon}
                list={BoatList}
                show={BoatShow}
                edit={BoatEdit}
                create={BoatCreate}
              />
              <Resource
                name='sections'
                icon={SectionIcon}
                list={SectionList}
                edit={SectionEdit}
                // create={SectionCreate}
              />
              <Resource
                name='categories'
                icon={CategoryIcon}
                list={CategoryList}
                edit={CategoryEdit}
                create={CategoryCreate}
              />
              <Resource
                name='selections'
                icon={SelectionIcon}
                list={SelectionList}
                edit={SelectionEdit}
                create={SelectionCreate}
              />
              <Resource
                name='documents'
                icon={DocumentIcon}
                list={DocumentList}
                edit={DocumentEdit}
                create={DocumentCreate}
              />
              <Resource name='choices' />
              <Resource name='sections' />
              <Resource name='supplier_services_lkup' />
              <Resource name='emails' />
              <Resource name='phones' />
              <Resource name='comments' />
              <Resource name='attachments' />
              <Resource name='attributes' />
              <Resource
                name='appointments'
                icon={AppointmentIcon}
                list={AppointmentList}
                edit={AppointmentEdit}
                create={AppointmentCreate}
              />
              <Resource name='appointment_customer' />
              <Resource name='history' icon={HistoryIcon} list={HistoryList} />
              <Resource
                name='history_transactions'
                icon={HistoryTransactionIcon}
                list={HistoryTransactionList}
              />
              <Resource name='users' />
              <Resource
                name='auth_accounts'
                icon={RoleIcon}
                list={UserList}
                create={UserCreate}
                edit={UserEdit}
              />
              <Resource
                name='auth_roles'
                icon={RoleIcon}
                list={RoleList}
                edit={RoleEdit}
                create={RoleCreate}
              />
              <Resource name='auth_roles' />
            </CoreAdminRouter>
          )}
        />
      </AuthGate>
    </Switch>
  )
}

export default App
