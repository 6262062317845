import { useState } from 'react'
import { Create, SimpleForm, TopToolbar } from 'react-admin'
import { ToggleI18nButton, UpsertCategory } from '~/components'

const CategoryCreateActions = ({ basePath, data, showI18n, setShowI18n }) => (
  <TopToolbar>
    <ToggleI18nButton showI18n={showI18n} setShowI18n={setShowI18n} />
  </TopToolbar>
)

const CategoryCreate = (props: object) => {
  const [showI18n, setShowI18n] = useState(false)

  return (
    <Create
      title='resources.categories.create'
      actions={<CategoryCreateActions showI18n={showI18n} setShowI18n={setShowI18n} />}
      {...props}
    >
      <SimpleForm redirect='list' warnWhenUnsavedChanges>
        <UpsertCategory {...props} showI18n={showI18n} />
      </SimpleForm>
    </Create>
  )
}

export default CategoryCreate
