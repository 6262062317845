import useMediaQuery from '@material-ui/core/useMediaQuery'
import LinearScaleIcon from '@material-ui/icons/LinearScale'
import { useState } from 'react'
import { Button, Edit, FormTab, ListButton, SaveButton, TabbedForm, TopToolbar } from 'react-admin'
import { useSelector } from 'react-redux'
import { Link, useRouteMatch } from 'react-router-dom'
import {
  GuidedBoatForm,
  ToggleNotesButton,
  ToolbarAddBoatSection,
  UpsertBoat,
  UpsertBoatAttributes,
} from '~/components'

const BoatName = ({ record }: { record?: { name: string } }) => {
  return <span>Boot: {record ? `${record.name}` : ''}</span>
}

const RemoteSaveButton = ({ saveProps }) => {
  const {
    handleSubmitWithRedirect,
    handleSubmit,
    invalid,
    pristine,
    redirect,
    saving,
    submitOnEnter,
  } = saveProps
  return (
    <SaveButton
      variant='text'
      size='small'
      handleSubmitWithRedirect={handleSubmitWithRedirect || handleSubmit}
      disabled={invalid || pristine}
      invalid={invalid}
      redirect={redirect}
      saving={saving}
      submitOnEnter={submitOnEnter}
    />
  )
}

const BoatEditActions = ({
  basePath,
  data,
  guided,
  saveProps,
  showToggleNotes,
  showNotes,
  setShowNotes,
}) => (
  <TopToolbar>
    {saveProps && <RemoteSaveButton saveProps={saveProps} />}
    {showToggleNotes && <ToggleNotesButton showNotes={showNotes} setShowNotes={setShowNotes} />}
    {!guided && (
      <Button
        component={Link}
        to={`${basePath}/guided_edit/${data?.id}`}
        label='resources.boats.guided_input'
      >
        <LinearScaleIcon />
      </Button>
    )}
    <ListButton basePath={basePath} record={data} label='ra.action.back' />
  </TopToolbar>
)

export const BoatEdit = (props: object) => {
  const url = useRouteMatch().url
  const guidedMatch = useRouteMatch(`${url}/guided`)
  const firstTabMatch = useRouteMatch(`${url}/1`)
  const guided = !!guidedMatch
  const open = useSelector((state) => state.admin.ui.sidebarOpen)
  const [saveProps, setSaveProps] = useState(null)
  const [showNotes, setShowNotes] = useState(false)

  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'))

  return guided ? (
    <Edit
      title={<BoatName />}
      actions={<BoatEditActions guided saveProps={saveProps} />}
      {...props}
      style={isXSmall ? {} : { marginLeft: open ? '244px' : '60px' }}
      mutationMode='pessimistic'
      key='guided_edit'
    >
      <GuidedBoatForm
        formRootPathname={`${url}/guided`}
        redirect=''
        warnWhenUnsavedChanges
        saveProps={saveProps}
        setSaveProps={setSaveProps}
      />
    </Edit>
  ) : (
    <Edit
      title={<BoatName />}
      actions={
        <BoatEditActions
          showToggleNotes={!!firstTabMatch}
          showNotes={showNotes}
          setShowNotes={setShowNotes}
        />
      }
      {...props}
      mutationMode='undoable'
    >
      <TabbedForm submitOnEnter={true} warnWhenUnsavedChanges redirect='show'>
        <FormTab label='resources.boats.base'>
          <UpsertBoat {...props} />
        </FormTab>
        <FormTab label='resources.boats.attributes'>
          <UpsertBoatAttributes {...props} showNotes={showNotes} />
          <ToolbarAddBoatSection />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}

export default BoatEdit
