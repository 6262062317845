import gql from 'graphql-tag'
import { GET_LIST, GET_ONE } from 'react-admin'
import { upsertQuery } from '~/helpers'

const fields = gql`
  {
    id
    name
    context
    predefined
    choices(order_by: { _order: asc }) {
      id
      key
      name
      translated_name
    }
    choices_aggregate {
      aggregate {
        count
      }
    }
  }
`

export default upsertQuery(
  {
    [GET_LIST]: {
      buildFields: fields,
    },
    [GET_ONE]: {
      buildFields: fields,
    },
  },
  {
    relations: ['choices'],
  }
)
