import { Divider, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslate } from 'react-admin'

const deviderGridStyles = makeStyles({
  root: {
    margin: 'auto',
  },
})

export const GridDevider = (props) => {
  const { light = false, variant = 'subtitle2', color = 'textSecondary', text = '🛥' } = props
  const gridClasses = deviderGridStyles()
  const translate = useTranslate()
  return (
    <>
      <Grid item md={5} classes={gridClasses}>
        <Divider variant='fullWidth' light={light} />
      </Grid>
      <Grid item md={2}>
        <Typography variant={variant} align='center' color={color}>
          {translate(text)}
        </Typography>
      </Grid>
      <Grid item md={5} classes={gridClasses}>
        <Divider variant='fullWidth' light={light} />
      </Grid>
    </>
  )
}
export default GridDevider
