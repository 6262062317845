import { NumberFormatPropsBase } from 'react-number-format'

export const moneyEuroFormat: NumberFormatPropsBase = {
  thousandSeparator: '.',
  decimalSeparator: ',',
  suffix: '€',
}

export const standardDecimalFormat: NumberFormatPropsBase = {
  thousandSeparator: '.',
  decimalSeparator: ',',
}

export const positivDecimalFormat: NumberFormatPropsBase = {
  thousandSeparator: '.',
  decimalSeparator: ',',
  allowNegative: false,
}
