import { gql, useLazyQuery } from '@apollo/client'
import { Attachment as AttachmentIcon, Edit as EditIcon } from '@material-ui/icons'
// import { catch } from 'fetch-mock'
import React, { useCallback, useContext, useRef, useState } from 'react'
import {
  Button,
  Datagrid,
  DateField,
  DeleteButton,
  FileInput,
  ResourceContextProvider,
  SimpleForm,
  TextField,
  useMutation,
  useRefresh,
} from 'react-admin'
import { AutocompleteFreeSolo, CreateModal, EditModal, Empty, FileImageField } from '~/components'
import { StorageContext, uploadBlob } from '~/helpers'
const { REACT_APP_HBP_URI } = process.env

export const EditAttachments = (props: object) => {
  const { relation, stateCreate } = props
  // const [images, setImages] = useState([])
  const storage = useContext(StorageContext)

  const [getAttachmentTypes, { data }] = useLazyQuery(gql`
    {
      attachments(distinct_on: type) {
        type
      }
    }
  `)
  let attachmentsTypes = (data ? data.attachments : []).map((data) => data.type || '')
  const refresh = useRefresh()
  const [mutate] = useMutation()

  const refCreateCanvas = useRef(null)
  const createAttachment = useCallback(
    async ({ type, file }) => {
      //const blob = await new Promise((resolve) => refCreateCanvas.current.toBlob(resolve))
      const blob = file.rawFile
      //const blob = refCreateCanvas.current.files[0]

      //Adding this block to prevent from crashing
      //try {
      const data = await uploadBlob(blob, file, storage)

      const [relName, relId] = relation

      await mutate(
        {
          type: 'create',
          resource: 'attachments',
          payload: {
            data: {
              type,
              uri: data.key,
              relation: relName,
              relation_id: parseInt(relId),
              title: file.title,
              content_type: data.ContentType,
            },
          },
        },
        { returnPromise: true }
      )
      refresh()
      stateCreate[1](false)
      console.log(data)
    },
    [stateCreate, mutate, refresh, relation, storage]
  )

  const stateEditModal = useState(false)
  const [editAttachmentId, edit] = useState(0)
  // const refEditCanvas = useRef(null)
  const editAttachment = useCallback(
    async ({ id, type, file }) => {
      let fileInfo = {}
      if (file) {
        const blob = file.rawFile
        const data = await uploadBlob(blob, file, storage)
        fileInfo = {
          uri: data.key,
          title: file.title,
          content_type: data.ContentType,
        }
      }

      // const [relName, relId] = relation
      await mutate(
        {
          type: 'update',
          resource: 'attachments',
          payload: {
            id,
            data: {
              type,
              updated_at: new Date(),
              ...fileInfo,
            },
          },
        },
        { returnPromise: true }
      )
      refresh()
      stateEditModal[1](false)
    },
    [stateEditModal, mutate, refresh, storage]
  )

  // todo: move this somehow to editdialog and add button to datagrid
  // can't figure out how to access List from Create-, EditModal (has to be possible)
  const EditModalButton = ({ source, record }) => (
    <Button
      onClick={(e) => {
        edit(record.id)
        stateEditModal[1](true)
      }}
      label='ra.action.edit'
    >
      <EditIcon />
    </Button>
  )

  const ImageFieldFoo = ({ source, record, ...rest }) => (
    <FileImageField
      record={{ ...record, [source]: `${REACT_APP_HBP_URI}/storage/o/${record[source]}` }}
      source={source}
      typeSource='content_type'
      title='title'
      target='_blank'
      {...rest}
    />
  )

  /* const selectFiles = (event) => {
    let images = []

    for (let i = 0; i < event.target.files.length; i++) {
      images.push(URL.createObjectURL(event.target.files[i]))
    }

    setImages(images)
  } */

  return (
    <>
      <ResourceContextProvider value='attachments'>
        <Empty {...props} icon={<AttachmentIcon />} resource='attachments'>
          <Datagrid expand={<ImageFieldFoo source='uri' />} rowClick='expand'>
            <TextField source='id' label='#' />
            <TextField source='type' />
            <DateField source='created_at' />
            <DateField source='updated_at' />
            <EditModalButton />
            <DeleteButton redirect={false} />
          </Datagrid>
        </Empty>
      </ResourceContextProvider>

      <CreateModal
        title='resources.attachments.create'
        state={stateCreate}
        {...props}
        resource='attachments'
      >
        <SimpleForm redirect={false} save={createAttachment}>
          <AutocompleteFreeSolo
            source='type'
            label='Type'
            getChoices={getAttachmentTypes}
            choices={attachmentsTypes}
          />
          {/* <FileInputPreview source='uri' refCanvas={refCreateCanvas} label='' /> */}
          {/* <input
            type='file'
            source='uri'
            onChange={(evt) => selectFiles(evt)}
            multiple={true}
            ref={refCreateCanvas}
          />
          {images.map((image) => (
            <img src={image} />
          ))} */}
          <FileInput source='file' label='' refCanvas={refCreateCanvas}>
            <FileImageField source='src' title='title' />
          </FileInput>
        </SimpleForm>
      </CreateModal>

      <EditModal state={stateEditModal} {...props} resource='attachments' id={editAttachmentId}>
        <SimpleForm redirect={false} save={editAttachment}>
          <AutocompleteFreeSolo
            source='type'
            label='Type'
            getChoices={getAttachmentTypes}
            choices={attachmentsTypes}
          />
          <ImageFieldFoo source='uri' fullWidth />
          <FileInput source='file' label='' refCanvas={refCreateCanvas}>
            <FileImageField source='src' title='title' />
          </FileInput>
        </SimpleForm>
      </EditModal>
    </>
  )
}

export default EditAttachments
