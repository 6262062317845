import CircularProgress from '@material-ui/core/CircularProgress'
import DownloadIcon from '@material-ui/icons/GetApp'
import PdfIcon from '@material-ui/icons/PictureAsPdf'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { React, useState } from 'react'
import {
  ArrayField,
  BooleanField,
  Button,
  Datagrid,
  EditButton,
  FormTab,
  FunctionField,
  ListButton,
  SelectField,
  TextField,
  TopToolbar,
  useLocale,
} from 'react-admin'
import NumberFormat from 'react-number-format'
import {
  CompactShow,
  CreateButton,
  CustomerTextField,
  EditAppointments,
  EditAttachments,
  EditComments,
  EquipmentListDoc,
  LinkToRecord,
  ShowBoatCategories,
} from '~/components'
import { moneyEuroFormat, positivDecimalFormat, standardDecimalFormat } from '~/helpers'
import {
  boatCeLabelingChoices,
  boatConditionChoices,
  boatStatusChoices,
  boatTypeChoices,
  motorboatPlaningTypeChoices,
  motorboatTypeChoices,
  sailboatRiggingTypeChoices,
  sailboatTypeChoices,
  usedBoatChoices,
} from '~/types/choices'

const BoatName = ({ record }: { record?: { name: string } }) => {
  return <span>Boot: {record ? `${record.name}` : ''}</span>
}

const DownloadPDFButton = ({ document, fileName, label }) => {
  const [loadPdf, setLoadPdf] = useState(false)

  return loadPdf ? (
    <PDFDownloadLink document={document} fileName={fileName}>
      {({ blob, url, loading, error }) =>
        loading ? (
          <Button disabled label={label}>
            <CircularProgress size={18} thickness={2} />
          </Button>
        ) : (
          <Button label={label}>
            <DownloadIcon />
          </Button>
        )
      }
    </PDFDownloadLink>
  ) : (
    <Button label={label} onClick={() => setLoadPdf(true)}>
      <PdfIcon />
    </Button>
  )
}

const BoatShowActions = ({ basePath, data }: { basePath?: string, data?: object }) => {
  const locale = useLocale()

  return (
    <TopToolbar>
      {data && (
        <DownloadPDFButton
          label={'Ausrüstungsliste'}
          document={<EquipmentListDoc record={data} locale={locale} />}
          fileName={`equipment-${data.model}.pdf`}
        />
      )}
      <ListButton basePath={basePath} record={data} label='ra.action.back' />
      <EditButton basePath={basePath} record={data} />
    </TopToolbar>
  )
}

const renderNumberFormat = (numberFormat = standardDecimalFormat) => {
  return (record, source) => {
    return <NumberFormat displayType='text' value={record[source]} {...numberFormat} />
  }
}

export const BoatShow = (props: object) => {
  const relation = [props.resource, props.id]
  const stateCreateComment = useState(false)
  const stateCreateAttachment = useState(false)
  const stateCreateAppointment = useState(false)

  return (
    <CompactShow
      {...props}
      component='div'
      // eslint-disable-next-line no-template-curly-in-string
      title={<BoatName />}
      actions={<BoatShowActions />}
      aside={(record) => [
        <SelectField source='status' choices={boatStatusChoices} size='3' />,

        <TextField source='name' />,
        <TextField source='manufacturer' />,
        <TextField source='model' />,

        <TextField source='build_number' />,
        <TextField source='build_year' />,
        <TextField source='first_launch' />,

        <FunctionField source='asking_price' render={renderNumberFormat(moneyEuroFormat)} />,
        <FunctionField source='lower_price_limit' render={renderNumberFormat(moneyEuroFormat)} />,
        <TextField source='price_reductions' />,

        <TextField source='summer_storage' size='1.5' />,
        <TextField source='winter_storage' size='1.5' />,

        <SelectField source='boat_type' choices={boatTypeChoices} />,
        record && record.boat_type ? (
          record.boat_type === 'sailboat' ? (
            <SelectField source='sailboat_type' choices={sailboatTypeChoices} />
          ) : (
            <SelectField source='motorboat_type' choices={motorboatTypeChoices} />
          )
        ) : (
          <></>
        ),
        record && record.boat_type ? (
          record.boat_type === 'sailboat' ? (
            <SelectField source='sailboat_rigging_type' choices={sailboatRiggingTypeChoices} />
          ) : (
            <SelectField source='motorboat_planing_type' choices={motorboatPlaningTypeChoices} />
          )
        ) : (
          <></>
        ),

        <SelectField source='goods_type' choices={usedBoatChoices} />,
        <SelectField source='condition' choices={boatConditionChoices} />,
        <TextField source='previous_owners' />,

        <TextField source='hull_material' />,
        <TextField source='hull_color' />,
        <TextField source='keel_type' />,

        <SelectField source='ce_labeling' choices={boatCeLabelingChoices} size='1.5' />,
        <BooleanField source='lake_constance_approval' size='1.5' />,

        <FunctionField source='length_total' render={renderNumberFormat(positivDecimalFormat)} />,
        <FunctionField
          source='length_waterline'
          render={renderNumberFormat(positivDecimalFormat)}
        />,
        <FunctionField source='width' render={renderNumberFormat(positivDecimalFormat)} />,

        <FunctionField
          source='draft_max'
          render={renderNumberFormat(positivDecimalFormat)}
          size='0.75'
        />,
        <FunctionField
          source='draft_min'
          render={renderNumberFormat(positivDecimalFormat)}
          size='0.75'
        />,
        <FunctionField
          source='displacement'
          render={renderNumberFormat(positivDecimalFormat)}
          size='0.75'
        />,
        <FunctionField
          source='ballast'
          render={renderNumberFormat(positivDecimalFormat)}
          size='0.75'
        />,

        <ArrayField
          source='boat_customer'
          label='resources.boats.types.customers'
          currentSort={{ field: 'type', order: 'ASC' }}
        >
          <Datagrid>
            <TextField source='relation_type' />
            <LinkToRecord basePath='/customers' id='customer_id'>
              <CustomerTextField source='customer.firstname' />
              <CustomerTextField source='customer.lastname' />
            </LinkToRecord>
          </Datagrid>
        </ArrayField>,
      ]}
      toolbars={[
        <></>,
        <></>,
        <CreateButton
          onClick={(e) => {
            e.stopPropagation()
            stateCreateAttachment[1](true)
          }}
        />,
      ]}
    >
      <FormTab label='resources.comments.title'>
        <ArrayField source='linked_comments' label=''>
          <EditComments
            disableDelete
            listLinked
            relation={relation}
            stateCreate={stateCreateComment}
          />
        </ArrayField>
      </FormTab>

      <FormTab label='resources.categories.title'>
        <FunctionField
          source='boat_section'
          label=''
          render={(record) => <ShowBoatCategories record={record} />}
        />
      </FormTab>

      <FormTab label='resources.attachments.title'>
        <ArrayField source='attachments' label=''>
          <EditAttachments disableDelete relation={relation} stateCreate={stateCreateAttachment} />
        </ArrayField>
      </FormTab>

      <FormTab label='resources.appointments.title'>
        <ArrayField source='appointment_boat' label=''>
          <EditAppointments
            disableDelete
            relation={relation}
            stateCreate={stateCreateAppointment}
          />
        </ArrayField>
      </FormTab>
    </CompactShow>
  )
}

export default BoatShow
