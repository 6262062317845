import React from 'react'
import { Edit, ListButton, SimpleForm, TopToolbar } from 'react-admin'
import { UpsertUser } from '~/components'

const UserName = ({ record }: { record?: { name: string } }) => {
  return <span>Benutzer: {record ? `${record.username}` : ''}</span>
}

const UserEditActions = ({ basePath, data }: { basePath?: string, data?: object }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} label='ra.action.back' />
  </TopToolbar>
)

export const UserEdit = (props: object) => {
  return (
    <Edit title={<UserName />} actions={<UserEditActions />} {...props}>
      <SimpleForm>
        <UpsertUser />
      </SimpleForm>
    </Edit>
  )
}

export default UserEdit
