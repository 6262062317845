import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import {
  BooleanField,
  Datagrid,
  EditButton,
  Filter,
  FunctionField,
  List,
  SelectField,
  TextField,
  TextInput,
} from 'react-admin'
import { selectionContextChoices } from '~/types/choices'
//import Chip, { ChipProps } from '@material-ui/core/Chip';

const SelectionsFilter = (props: object) => (
  <Filter {...props}>
    <TextInput label='pos.search' source='name' alwaysOn />
  </Filter>
)

const useStyles = makeStyles((theme) => ({
  tableCell: {
    maxWidth: 330,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}))

const SelectionList = (props: object) => {
  const classes = useStyles()
  return (
    <List
      title='resources.selections.title'
      filters={<SelectionsFilter />}
      bulkActionButtons={false}
      {...props}
    >
      <Datagrid rowClick='edit'>
        <TextField source='name' label='resources.selections.fields.name' />
        <SelectField
          source='context'
          choices={selectionContextChoices}
          label='resources.selections.fields.context'
        />
        {/* <FunctionField
          source='section_id'
          label='resources.selections.fields.section'
          render={(record) => (record.section ? <SectionLabel section={record.section} /> : '')}
        /> */}
        {/* <TextField
          source='choices_aggregate.aggregate.count'
          sortBy='choices_aggregate.count'
          label='resources.selections.fields.choice_count'
        /> */}
        <FunctionField
          cellClassName={classes.tableCell}
          sortBy='choices_aggregate.count'
          label='resources.selections.fields.choice_count'
          // render={record => (record.choices.map(c => <Chip label={c.name} />))}
          render={(record) =>
            record.choices_aggregate
              ? record.choices_aggregate.aggregate.count +
                ': ' +
                record.choices.map((c) => c.key).join(', ')
              : ''
          }
        />
        <BooleanField source='predefined' />
        {/* <TextField source='history[0].user.display_name' label='history.modified_by' /> */}
        {/* <DateField source='history[0].time' showTime label='history.last_modified' /> */}
        <EditButton />
      </Datagrid>
    </List>
  )
}

export default SelectionList
