import { print } from 'graphql'
import gql from 'graphql-tag'
import moment from 'moment'
import { CREATE, GET_ONE, UPDATE } from 'react-admin'
import { uploadBlob } from '~/helpers'

export const castTypes = {
  date: (value) => {
    if (typeof value === 'string') {
      return moment(value).toDate()
    }
    return value
  },
  Int: (value) => parseInt(value),
  String: (value) => (value?.toString !== undefined ? value.toString() : ''),
}

export const castField = (field, value) => {
  // is relation?
  if (field.args.length) return value

  const required = field.type.kind === 'NON_NULL'
  const type = (field.type.ofType || field.type).name
  if (!required && value === '') {
    return null
  }
  return (castTypes[type] || ((_) => _))(value)
}

export const castFields = (fields, data) =>
  Object.keys(data).reduce(
    (o, key) => ({
      ...o,
      [key]: fields.filter((f) => f.name === key).map((f) => castField(f, data[key]))[0],
    }),
    {}
  )

export const getFirstOfType = (field, kind = 'OBJECT') => {
  return !field ? null : field.kind === kind ? field : getFirstOfType(field.ofType || field.type)
}

export const getPKeyConstraint = (introspection, field) => {
  const constraint = introspection.types.find((t) => t.name === `${field.name}_constraint`)
  if (!constraint) {
    console.debug(
      `🏥 Could not find constraint for ${field.name} 💘 beeing optimistic and bet on '${field}_pkey'`
    )
    return `${field.name}_pkey`
  }
  const pkey = constraint.enumValues.find((ev) => ev.name.endsWith('_pkey'))
  if (!pkey) {
    console.debug(
      `🩺 Found constraint but no pkey match. Possible values are ${JSON.stringify(
        constraint.enumValues
      )}.`
    )
    return `${field.name}_pkey`
  }
  return pkey.name
}

export const getIntrospectionType = (introspectionResults, name) => {
  return introspectionResults.types.find((t) => t.name === name)
}

// todo: maybe we can get rid of skip and required if we look into introspectionResults
export const upsertQuery = (
  queries,
  {
    relations: upsertRelations = [],
    ignore: upsertIgnore = [],
    attachments: upsertAttachments = [],
  }
) => {
  queries[CREATE] = queries[UPDATE] = {
    query: (introspectionResults) => (resource, aorFetchType, queryType, variables) => {
      const deleteRelations = Object.keys(variables).filter((key) => key.startsWith('delete_'))
      return gql`
        mutation upsert_${resource.type.name}(
          $objects: [${resource.type.name}_insert_input!]!
          $on_conflict: ${resource.type.name}_on_conflict!
          ${deleteRelations.map((r) => `$${r}: [Int!]!`)}
        ) {
          data: insert_${resource.type.name}(objects: $objects, on_conflict: $on_conflict) {
            returning ${
              queries[GET_ONE] && queries[GET_ONE].buildFields
                ? print(queries[GET_ONE].buildFields)
                : '{}'
            }
          }
          ${deleteRelations.map((r) => `${r}(where: { id: { _in: $${r} } }) { affected_rows }`)}
        }
      `
    },
    variables:
      (introspectionResults) => async (resource, aorFetchType, params, queryType, fileStorage) => {
        const recursiveBuild = async (args, resourceType, _data, _previous, includeAll = false) => {
          let on_conflict = {
            constraint: getPKeyConstraint(introspectionResults, resourceType),
            update_columns: [],
          }

          const buildObject = async (data, previous) =>
            Object.keys(data).reduce(async (acc, key) => {
              // relation ids from new object are null
              if (key === 'id' && !data[key]) return await acc

              if (upsertIgnore.includes(key)) return await acc

              const field = resourceType.fields.find((field) => field.name === key)
              if (!field) {
                console.debug(
                  `👷 ${resource.type.name} ${aorFetchType}: field not found in resource definition ➡️ ${key}`
                )
                return await acc
              }

              const relation = getFirstOfType(field)
              if (relation && !upsertRelations.includes(relation.name)) {
                console.debug(
                  `👮 ${resource.type.name} ${aorFetchType}: relation not whitelisted ➡️ ${relation.name} (${key})`
                )
                return await acc
              }

              // TODO: find other way to handle file uploading, avoiding need for async here
              if (relation && upsertAttachments.includes(field.name)) {
                console.debug(
                  `⬆️ ${resource.type.name} ${aorFetchType}: handling file uploads ➡️ ${relation.name} (${key})`
                )
                console.log(data[key])
                const newAttachments = data[key].filter((a) => a.rawFile instanceof File)
                const oldAttachments = data[key].filter((a) => !(a.rawFile instanceof File))

                const uploadedAttachments = await Promise.all(
                  newAttachments.map(async (file) => {
                    const data = await uploadBlob(file.rawFile, file, fileStorage)
                    return {
                      type: field.name,
                      uri: data.key,
                      title: file.title,
                      content_type: data.ContentType,
                      caption: file.caption,
                    }
                  })
                )
                data[key] = oldAttachments.concat(uploadedAttachments)
              }

              const notDirty = data[key] === (previous ?? {})[key]
              const notRequired = !!relation || field.type.kind !== 'NON_NULL'
              // nothing to do if it's not dirty nor valid (in field set)
              if (!includeAll && notDirty && notRequired) {
                return await acc
              }

              let value = castField(field, data[key])
              if (relation) {
                const relationType = getIntrospectionType(introspectionResults, relation.name)
                const build = await recursiveBuild(
                  args,
                  relationType,
                  value,
                  (previous ?? {})[key],
                  true
                )
                value = {
                  data: build.objects,
                  on_conflict: build.on_conflict,
                }
                // if dataset is array we need to potantially delete old records
                if (Array.isArray(value.data)) {
                  // delete id's not in data set
                  const keep = value.data.map(({ id }) => id)
                  const dele = (data[`${key}Ids`] || []).filter((id) => !keep.includes(id))
                  // only add arg if there is somthing to delete
                  if (dele.length) {
                    args[`delete_${relationType.name}`] = dele
                  }
                  // if there also no data skip field
                  else if (!value.data.length) {
                    return await acc
                  }
                }
              } else {
                on_conflict.update_columns.push(key)
              }

              return {
                ...(await acc),
                [key]: value,
              }
            }, {})

          let objects
          if (Array.isArray(_data)) {
            const hasOrder = resourceType.fields.find((field) => field.name === '_order')
            objects = []
            for (let i = 0; i < _data.length; i++) {
              if (hasOrder && _data.length > 1) {
                _data[i]['_order'] = i
              }
              objects[i] = await buildObject(_data[i], _previous ? _previous[i] : undefined)
            }
          } else {
            objects = await buildObject(_data, _previous)
          }

          return { objects, on_conflict }
        }
        const args = {}
        const { objects, on_conflict } = await recursiveBuild(
          args,
          resource.type,
          [params.data],
          [params.previousData]
        )
        return { objects, on_conflict, ...args }
      },
  }
  return queries
}

export default upsertQuery
