import gql from 'graphql-tag'
import { GET_LIST, GET_MANY_REFERENCE, GET_ONE } from 'react-admin'

const buildFields = gql`
  {
    id
    relation
    relation_id
    operation
    change
    time
    user {
      id
      display_name
      account {
        role {
          role
        }
      }
    }
  }
`

const extendFields = gql`
  {
    history(limit: 1, order_by: { time: desc }) {
      id
      operation
      time
      user {
        id
        display_name
        account {
          role {
            role
          }
        }
      }
    }
  }
`

export const HistoryQueries = {
  [GET_LIST]: { buildFields },
  [GET_MANY_REFERENCE]: { buildFields },
  extendFields: {
    // ignroe this extension on the following resources
    ignore: ['history', 'history_transactions'],
    // add to all other queries
    [GET_ONE]: extendFields,
    [GET_LIST]: extendFields,
  },
}
export default HistoryQueries
