import { useQuery } from '@apollo/client'
import { makeStyles } from '@material-ui/core/styles'
import DateRangeIcon from '@material-ui/icons/DateRange'
import { useState } from 'react'
import {
  ArrayField,
  Button,
  ChipField,
  CreateButton,
  Datagrid,
  DateField,
  EditButton,
  ExportButton,
  Filter,
  FunctionField,
  List,
  SelectField,
  SingleFieldList,
  TextField,
  TextInput,
  TopToolbar,
  useLocale,
} from 'react-admin'
import { CustomerChipField } from '~/components'
import { selectionChoicesQuery } from '~/helpers'
import AppointmentCalendarView from './CalendarView'

const AppointmentsFilter = (props: object) => (
  <Filter {...props}>
    <TextInput label='pos.search' source='title' alwaysOn />
  </Filter>
)

const useStyles = makeStyles((theme) => ({
  tableCell: {
    maxWidth: 330,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}))

const AppointmentListActions = ({ basePath, setShowCalendar }) => (
  <TopToolbar>
    <CreateButton basePath={basePath} />
    <Button onClick={() => setShowCalendar(true)} label='resources.appointments.calendar_view'>
      <DateRangeIcon />
    </Button>
    <ExportButton />
  </TopToolbar>
)

const AppointmentList = (props: object) => {
  const [showCalendar, setShowCalendar] = useState(true)
  const classes = useStyles()
  const locale = useLocale()
  const { data: types, loading: loadingTypes } = useQuery(
    selectionChoicesQuery({ resource: 'appointments', source: 'type', locale })
  )

  return showCalendar ? (
    <AppointmentCalendarView
      setShowCalendar={setShowCalendar}
      types={types}
      loadingTypes={loadingTypes}
      {...props}
    />
  ) : (
    <List
      title='resources.appointments.title'
      filters={<AppointmentsFilter />}
      actions={<AppointmentListActions setShowCalendar={setShowCalendar} />}
      bulkActionButtons={false}
      currentSort={{ field: 'start', order: 'DESC' }}
      {...props}
    >
      <Datagrid>
        <TextField source='title' />
        <SelectField
          source='type'
          choices={types ? types.choices.map((c) => ({ id: c.key, name: c.translated_name })) : []}
          loading={loadingTypes}
        />
        <FunctionField
          source='start'
          render={(appointment) => <DateField source='start' showTime={!appointment.allDay} />}
        />
        <FunctionField
          source='end'
          render={(appointment) => <DateField source='end' showTime={!appointment.allDay} />}
        />
        <ArrayField source='appointment_customer' fieldKey='customer_id' basePath='/customers'>
          <SingleFieldList linkType='show'>
            <CustomerChipField source='customer' />
          </SingleFieldList>
        </ArrayField>
        <ArrayField source='appointment_boat' fieldKey='boat_id' basePath='/boats'>
          <SingleFieldList linkType='show'>
            <ChipField source='boat.model' />
          </SingleFieldList>
        </ArrayField>
        <TextField source='history[0].user.display_name' label='history.modified_by' />
        <DateField source='history[0].time' showTime label='history.last_modified' />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export default AppointmentList
