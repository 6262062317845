// Overrides for @react-admin/ra-form-layout WizardForm Component
import {
  WizardFormProgressProps as OriginalWizardFormProgressProps,
  WizardProgress,
  WizardToolbar,
  WizardToolbarProps as OriginalWizardToolbarProps,
} from '@react-admin/ra-form-layout'
import classnames from 'classnames'
import { isEqual, omit } from 'lodash'
import * as React from 'react'
import { HtmlHTMLAttributes, KeyboardEvent, useState } from 'react'
import { CardContentInner, FormWithRedirect, FormWithRedirectProps } from 'react-admin'
import { FormProps } from 'react-final-form'
import { useHistory, useRouteMatch } from 'react-router-dom'

/**
 * Form component rendering a wizard form with stepper
 *
 * Alternative to <SimpleForm>, to be used as child of <Create>.
 * Expects <WizardFormStep> elements as children.
 *
 * @param {ComponentType} toolbar An alternative toolbar element (to customize form buttons)
 * @param {ComponentType} progress An alternative progress bar element (to customize stepper)
 *
 * @example
 *
 * import React, { FC } from 'react';
 * import { Create, TextInput, required } from 'react-admin';
 * import { WizardForm, WizardFormStep } from '@react-admin/ra-form-layout';
 *
 * const PostCreate: FC = props => (
 *   <Create {...props}>
 *       <WizardForm>
 *           <WizardFormStep label="First step">
 *               <TextInput source="title" validate={required()} />
 *           </WizardFormStep>
 *           <WizardFormStep label="Second step">
 *               <TextInput source="description" />
 *           </WizardFormStep>
 *           <WizardFormStep label="Third step">
 *               <TextInput source="fullDescription" validate={required()} />
 *           </WizardFormStep>
 *       </WizardForm>
 *   </Create>
 * );
 */
const WizardForm = (props: Omit<FormWithRedirectProps, 'render'>) => (
  <FormWithRedirect
    {...props}
    render={(formProps): React.ReactElement => (
      <WizardFormView {...formProps} formRootPathname={props.formRootPathname} />
    )}
  />
)

const WizardFormView = ({
  basePath,
  children,
  className,
  handleSubmit,
  handleSubmitWithRedirect,
  invalid,
  pristine,
  redirect,
  saving,
  submitOnEnter = true,
  toolbar = WizardToolbar,
  progress = WizardProgress,
  formRootPathname,
  syncWithLocation = true,
  ignoreKeyPress,
  saveProps,
  setSaveProps,
  ...rest
}: any) => {
  const match = useRouteMatch(`${formRootPathname}/:index`)
  const routeStep = match ? parseInt(match.params?.index) : 0
  const history = useHistory()

  const [currentStep, setCurrentStep] = useState<number>(routeStep)
  const [dirtySteps, setDirtySteps] = useState<number[]>([])

  React.useEffect(() => {
    syncWithLocation &&
      routeStep !== currentStep &&
      history.push(currentStep ? `${formRootPathname}/${currentStep}` : formRootPathname)
  }, [currentStep, formRootPathname, history, routeStep, syncWithLocation])

  // Just keep track of visited steps if form is dirty (simple solution for now)
  // TODO: Actually just keep steps with dirty/invalid fields
  React.useEffect(() => {
    pristine
      ? dirtySteps.length > 0 && setDirtySteps([])
      : !dirtySteps.includes(currentStep) && setDirtySteps((visited) => [...visited, currentStep])
  }, [currentStep, pristine, dirtySteps])

  const handleNext = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault()
    setCurrentStep((step) => step + 1)
  }

  const handlePrevious = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault()
    setCurrentStep((step) => step - 1)
  }

  // We can't go forward using the progress stepper
  // So we don't need extra checks here
  const handleIndexNavigation = (index: number): void => {
    setCurrentStep(index)
  }

  const handleKeyPress = (event: KeyboardEvent<HTMLFormElement>): void => {
    if (
      event.key === 'Enter' &&
      // @ts-ignore
      typeof event.target.tagName !== 'undefined' &&
      // @ts-ignore
      event.target.tagName !== 'button'
    ) {
      // Even if we don't have a next step, we should prevent the default form submit
      // in case users have set the submitOnEnter prop.
      event.preventDefault()
      if (hasNextStep) {
        setCurrentStep((step) => step + 1)
      }
    }
  }

  const steps = React.Children.toArray(children)

  const hasPreviousStep = currentStep > 0
  const hasNextStep = currentStep < steps.length - 1

  React.useEffect(() => {
    if (!setSaveProps) return
    const newProps = {
      handleSubmitWithRedirect,
      handleSubmit,
      invalid,
      pristine,
      redirect,
      saving,
      submitOnEnter,
    }
    const omitProps = ['handleSubmitWithRedirect', 'handleSubmit']
    !isEqual(omit(saveProps, omitProps), omit(newProps, omitProps)) && setSaveProps(newProps)
  }, [
    handleSubmit,
    handleSubmitWithRedirect,
    invalid,
    pristine,
    redirect,
    saveProps,
    saving,
    setSaveProps,
    submitOnEnter,
  ])

  return (
    <>
      {React.createElement(progress, {
        steps,
        currentStep,
        onStepClick: handleIndexNavigation,
        invalid,
      })}
      {/* eslint-disable-next-line */}
      <form
        className={classnames('wizard-form', className)}
        onKeyPress={ignoreKeyPress ? null : handleKeyPress}
        {...sanitizeRestProps(rest)}
      >
        <CardContentInner>
          {React.Children.map(children, (step, index) =>
            React.isValidElement(step) && (index === currentStep || dirtySteps.includes(index)) ? (
              <span style={currentStep !== index ? { display: 'none' } : {}}>
                {React.cloneElement(step as React.ReactElement, rest)}
              </span>
            ) : null
          )}
        </CardContentInner>
        {toolbar &&
          React.createElement(toolbar, {
            hasNextStep,
            hasPreviousStep,
            onNextClick: handleNext,
            onPreviousClick: handlePrevious,
            invalid,
            handleSubmit,
            handleSubmitWithRedirect,
            pristine,
            redirect,
            saving,
            submitOnEnter,
          })}
      </form>
    </>
  )
}

const sanitizeRestProps = ({
  active,
  dirty,
  dirtyFields,
  dirtyFieldsSinceLastSubmit,
  dirtySinceLastSubmit,
  error,
  errors,
  form,
  hasSubmitErrors,
  hasValidationErrors,
  initialValues,
  modified,
  modifiedSinceLastSubmit,
  mutationMode,
  save,
  submitError,
  submitErrors,
  submitFailed,
  submitSucceeded,
  submitting,
  touched,
  valid,
  validating,
  values,
  visited,
  __versions,
  ...props
}): any => props

export interface WizardFormProps
  extends Omit<FormProps, 'onSubmit'>,
    Omit<HtmlHTMLAttributes<HTMLFormElement>, 'onSubmit' | 'children'> {
  progress?: React.ComponentType
  toolbar?: React.ComponentType
  ignoreKeyPress?: boolean
}

export interface WizardToolbarProps extends OriginalWizardToolbarProps {}

export interface WizardFormProgressProps extends OriginalWizardFormProgressProps {
  invalid?: boolean
}

export default WizardForm
