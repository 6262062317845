import { useQuery } from '@apollo/client'
import React from 'react'
import {
  AutocompleteInput,
  LinearProgress,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  TranslatableInputs,
  useCreate,
  useCreateSuggestionContext,
  useInput,
  useLocale,
  useRecordContext,
  useResourceContext,
} from 'react-admin'
import { CreateModal } from '~/components'
import { selectionChoicesQuery, selectionIdQuery } from '~/helpers'

const CreateChoice = ({ resource, source, onNewChoice }) => {
  const locale = useLocale()
  const { data, loading } = useQuery(selectionIdQuery({ resource, source }))
  const { filter, onCancel, onCreate } = useCreateSuggestionContext()
  const [create] = useCreate('choices')
  const initialValues = () => ({ key: filter })

  const handleSubmit = (values) => {
    create(
      {
        payload: {
          data: {
            ...values,
            selection_id: data?.selections?.[0]?.id,
          },
        },
      },
      {
        onSuccess: ({ data }) => {
          const newChoice = {
            ...data,
            id: data.key,
            name: data.translated_name?.[locale] || data.key,
          }
          onCreate(newChoice)
          onNewChoice(newChoice)
        },
      }
    )
  }

  return (
    <CreateModal title='resources.choices.create' open onClose={onCancel} resource='choices'>
      <SimpleForm
        redirect={false}
        save={handleSubmit}
        initialValues={initialValues}
        toolbar={<Toolbar alwaysEnableSaveButton />}
      >
        {loading ? (
          <LinearProgress />
        ) : (
          <>
            <TextInput source='key' label='resources.choices.fields.key' validate={[required()]} />
            <TranslatableInputs locales={['de', 'en']} defaultLocale={locale}>
              <TextInput source={'translated_name'} label='resources.choices.fields.name' />
            </TranslatableInputs>
          </>
        )}
      </SimpleForm>
    </CreateModal>
  )
}

export const SelectionInput = ({ basePath, autocomplete, ...props }) => {
  const locale = useLocale()
  // TODO: it might be possible to have ra passing the record prop implicitly
  const record = useRecordContext(props) || {}
  const [newChoices, setNewChoices] = React.useState([])
  let resource = useResourceContext()
  // Get form value via Input-Hook if record context is missing
  const {
    input: { value },
  } = useInput(props)

  let source = props.source
  const splitSourceArray = props.source.split(/\[\d+\]\./)
  if (splitSourceArray.length > 1) {
    resource = splitSourceArray[splitSourceArray.length - 2]
    source = splitSourceArray[splitSourceArray.length - 1]
  }

  const { data, loading } = useQuery(selectionChoicesQuery({ resource, source, locale }))
  const choices = (data ? data.choices : [])
    .map((data) => ({
      id: data.key,
      name: data.translated_name || data.key,
    }))
    .concat(newChoices)

  const onNewChoice = (newChoice) => setNewChoices(newChoices.concat([newChoice]))

  return autocomplete ? (
    <AutocompleteInput
      choices={choices}
      record={record}
      loading={loading}
      create={<CreateChoice resource={resource} source={source} onNewChoice={onNewChoice} />}
      createLabel='resources.choices.create'
      createItemLabel='resources.choices.create_item'
      {...props}
    />
  ) : (
    <SelectInput choices={choices} record={record} loading={loading} {...props} />
  )
}

export default SelectionInput
