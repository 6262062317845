import { useTranslate } from 'react-admin'

const TranslateField = ({ record, translate }) => {
  const t = useTranslate()
  if (!record) {
    return null
  }
  return <span>{t(eval('`' + translate + '`'))}</span>
}

export default TranslateField
