import { Chip, Grid, makeStyles } from '@material-ui/core'
import {
  ArrayInput,
  EditButton,
  FormDataConsumer,
  NumberInput,
  required,
  SimpleFormIterator,
  TextInput,
  TranslatableInputs,
  useLocale,
} from 'react-admin'
import { CompactRemoveButton } from '~/components'

const useStyles = makeStyles((theme) => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    'margin-top': '-1em',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}))

export const UpsertSection = ({ record, showI18n }) => {
  const classes = useStyles()
  const locale = useLocale()

  return (
    <Grid container spacing={3}>
      <Grid item md={3}>
        <TextInput source='slug' fullWidth disabled label='resources.sections.fields.slug' />
        {showI18n && (
          <TranslatableInputs locales={['de', 'en']} defaultLocale={locale} groupKey='name'>
            <TextInput source='translated_name' label='resources.sections.fields.name' fullWidth />
          </TranslatableInputs>
        )}
      </Grid>
      <Grid item md={3}>
        <NumberInput source='position' fullWidth />
      </Grid>
      <Grid item md={12}>
        <ArrayInput record={record} source='categories' label='resources.categories.title'>
          <SimpleFormIterator
            TransitionProps={{ classNames: '' }}
            disableRemove={(r) => r.id > 0}
            removeButton={<CompactRemoveButton />}
          >
            <FormDataConsumer>
              {({ scopedFormData: { id, name, attributes } = {}, getSource, ...rest }) => (
                <Grid container spacing={1}>
                  <Grid item md={3}>
                    <TextInput
                      source={getSource('name')}
                      validate={[required()]}
                      label='resources.categories.fields.name'
                      fullWidth
                      autoFocus={!name}
                    />
                    {showI18n && (
                      <TranslatableInputs
                        locales={['de', 'en']}
                        defaultLocale={locale}
                        groupKey={getSource('name')}
                      >
                        <TextInput
                          source={getSource('translated_name')}
                          label='resources.categories.fields.name'
                          fullWidth
                        />
                      </TranslatableInputs>
                    )}
                  </Grid>
                  <Grid item md={6}>
                    <TextInput
                      source={getSource('description')}
                      label='resources.categories.fields.description'
                      fullWidth
                    />
                    {showI18n && (
                      <TranslatableInputs
                        locales={['de', 'en']}
                        defaultLocale={locale}
                        groupKey={getSource('description')}
                      >
                        <TextInput
                          source={getSource('translated_description')}
                          label='resources.categories.fields.description'
                          fullWidth
                        />
                      </TranslatableInputs>
                    )}
                  </Grid>
                  <Grid item md={3}>
                    {id && <EditButton basePath='/categories' record={{ id: id }} />}
                  </Grid>
                  <Grid item md={12}>
                    <div className={classes.chips}>
                      {attributes?.map((a) => (
                        <Chip key={a.id} label={a.name} />
                      ))}
                    </div>
                  </Grid>
                </Grid>
              )}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </Grid>
    </Grid>
  )
}

export default UpsertSection
