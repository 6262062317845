import { Card as MuiCard, CardContent, withStyles } from '@material-ui/core'
import { FilterLiveSearch } from 'react-admin'

const Card = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      order: -1, // display on the left rather than on the right of the list
      width: '18em',
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))(MuiCard)

export const FilterSidebar = ({ children, search }) => (
  <Card>
    <CardContent>
      {search && <FilterLiveSearch source={search} />}
      {children}
    </CardContent>
  </Card>
)

export default FilterSidebar
