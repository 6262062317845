import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import LensIcon from '@material-ui/icons/Lens'
import NotInterestedIcon from '@material-ui/icons/NotInterested'
import TripOriginIcon from '@material-ui/icons/TripOrigin'
import React from 'react'
import { categoryEntryState } from '~/helpers'
import { notesIconChoices } from '~/types/choices'

const CategoryEntryIcon = ({
  values,
  state,
  ...rest
}: {
  values: any
  state: 'none' | 'some' | 'all' | undefined
}) => {
  if (!values) return <NotInterestedIcon />
  if (!state && values.category?.attributes)
    state = categoryEntryState(values, values.category).attributesState
  const iconProp = state === 'none' ? 'outlineIcon' : 'icon'
  const notesIcon = notesIconChoices.find((c) => c.id === values.notes_icon)?.[iconProp]
  return notesIcon ? (
    React.createElement(notesIcon, rest)
  ) : state === 'all' ? (
    <CheckCircleIcon color='primary' {...rest} />
  ) : state === 'some' ? (
    <LensIcon {...rest} />
  ) : (
    <TripOriginIcon {...rest} />
  )
}

export default CategoryEntryIcon
