import gql from 'graphql-tag'
import { GET_LIST, GET_ONE } from 'react-admin'
import { upsertQuery } from '~/helpers'

const fields = gql`
  {
    id
    name
    translated_name
    description
    translated_description
    section_id
    section {
      id
      slug
      translated_name
    }
    attributes(order_by: { _order: asc }) {
      id
      name
      translated_name
      input_type
      description
      default_value
      suffix
      translated_suffix
      show_by_default
      hidden
      choices(order_by: { _order: asc }) {
        id
        key
        name
        translated_name
      }
      selection_id
      selection {
        id
        name
        choices(order_by: { _order: asc }) {
          id
          key
          name
          translated_name
        }
      }
    }
  }
`

export default upsertQuery(
  {
    [GET_LIST]: {
      buildFields: fields,
    },
    [GET_ONE]: {
      buildFields: fields,
    },
  },
  {
    relations: ['attributes', 'section', 'choices'],
  }
)
