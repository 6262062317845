import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core'
import { Rowing } from '@material-ui/icons'
import ExpandMore from '@material-ui/icons/ExpandMore'
import {
  ArrayInput,
  FormDataConsumer,
  LinearProgress,
  Loading,
  SimpleFormIterator,
  useGetList,
} from 'react-admin'
import { useFormState } from 'react-final-form'
import { Empty, SectionLabel, UpsertBoatSection } from '~/components'
import { Sections } from '~/graphql/generated'
import './UpsertBoatAttributes.css'

const UpsertBoatAttributes = (props) => {
  const { values } = useFormState()
  const { data: sections, loading } = useGetList<Sections>(
    'sections',
    { page: 1, perPage: 25 },
    { field: 'position', order: 'ASC' }
  )

  return loading ? (
    <LinearProgress />
  ) : (
    <>
      {values.boat_section && values.boat_section.length ? (
        <ArrayInput source='boat_section' label='' className='boat-sections'>
          <SimpleFormIterator
            disableAdd
            disableRemove
            disableReordering
            TransitionProps={{ classNames: '' }}
          >
            <FormDataConsumer>
              {({ scopedFormData: { section_id } = {}, getSource, ...rest }) => {
                if (!section_id) {
                  return null
                }
                const categorySource = getSource('boat_section_category')
                // const { data, loading } = useGetOne('sections', section_id)
                const data = sections
                  ? Object.values(sections).find((s) => s.id === section_id)
                  : null

                return loading ? (
                  <Loading />
                ) : (
                  <Accordion>
                    <AccordionSummary
                      id={`panel${data.id}-header`}
                      key={data.id}
                      expandIcon={<ExpandMore />}
                    >
                      <SectionLabel section={data} />
                    </AccordionSummary>
                    <AccordionDetails>
                      <UpsertBoatSection
                        section={data}
                        source={categorySource}
                        formData={values}
                        {...props}
                      />
                    </AccordionDetails>
                  </Accordion>
                )
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      ) : (
        // values.boat_section.map((section) => {
        //   const data = sections[section.section_id]
        //   return <SectionAccordion section={section} key={data.id} {...props} />
        // })
        <Empty icon={<Rowing />} message='resources.boats.empty_attributes' />
      )}
    </>
  )
}

export default UpsertBoatAttributes
