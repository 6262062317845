export const PropsCreateEdit = [
  'basePath',
  'resource',
  'id',
  'hasList',
  'hasEdit',
  'hasShow',
  'hasCreate',
  'history',
  'match',
  'location',
  'redirect',
]
export const PropsForm = [
  'handleSubmitWithRedirect',
  'handleSubmit',
  'redirect',
  'undoable',
  'submitOnEnter',
  'saving',
  'record',
  'resource',
  'basePath',
]
export const PropsMaterialUi = [
  'classes',
  'color',
  'component',
  'disabled',
  'error',
  'focused',
  'fullWidth',
  'hiddenLabel',
  'margin',
  'required',
  'size',
  'variant',
  'inputVariant',
  'fullWidth',
]

/**
 * Select the props injected by the controller hook
 * to be passed to the children need
 * This is an implementation of pick()
 */
export const reduceProps = (props, to = PropsCreateEdit) =>
  to.reduce((acc, key) => ({ ...acc, [key]: props[key] }), {})

/**
 * Select the props not injected by the controller hook
 * to be used inside the children to sanitize props
 * This is an implementation of omit()
 */
export const sanitizeProps = (props, to = PropsCreateEdit) =>
  Object.keys(props)
    .filter((propName) => !to.includes(propName))
    .reduce((acc, key) => ({ ...acc, [key]: props[key] }), {})
