import React from 'react'
import { Filter, List, TextInput } from 'react-admin'
import { HistoryTransactionDatagrid } from '~/components'

const HistoryTransactionFilter = (props: object) => (
  <Filter {...props}>
    <TextInput label='pos.search' source='relation' alwaysOn />
  </Filter>
)

const HistoryTransactionList = (props: object) => {
  const searchColums = ['relation', 'operation']
  return (
    <List
      label='resources.name'
      filters={<HistoryTransactionFilter {...props} columns={searchColums} />}
      bulkActionButtons={false}
      {...props}
      sort={{ field: 'action_tstamp_tx', order: 'DESC' }}
    >
      <HistoryTransactionDatagrid />
    </List>
  )
}

export default HistoryTransactionList
