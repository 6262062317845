// todo default onSeccess function will return null as id - despide it being there
export const onSuccess = (redirect, notify, basePath, redirectTo) => ({ data }) => {
  notify('ra.notification.created', 'info', { smart_count: 1 })
  redirect(
    (() => {
      switch (redirectTo) {
        case 'list':
          return basePath
        case 'create':
          return `${basePath}/create`
        case 'edit':
          return `${basePath}/${data.id}`
        case 'show':
          return `${basePath}/${data.id}/show`
        default:
          return redirectTo
      }
    })()
  )
}
