// Overrides for @react-admin/ra-calendar Calendar Component
import {
  DateSelectArg,
  DatesSetArg,
  DateUnselectArg,
  EventApi,
  EventClickArg,
  EventContentArg,
  getSegAnchorAttrs,
  Seg,
  ViewContext,
} from '@fullcalendar/react'
import AddIcon from '@material-ui/icons/Add'
import {
  Calendar as OriginalCalendar,
  CalendarProps as OriginalCalendarProps,
  getFilterValuesFromInterval as defaultGetFilterValuesFromInterval,
} from '@react-admin/ra-calendar'
import isEqual from 'lodash/isEqual'
import { useState } from 'react'
import { Button, linkToRecord, useListContext } from 'react-admin'
import { useHistory } from 'react-router-dom'

interface EventContentArgExt extends EventContentArg {
  titleOverride: any
}

const renderInnerContent = (innerProps: EventContentArgExt) => {
  const { titleOverride } = innerProps
  return (
    <div className='fc-event-main-frame'>
      {innerProps.timeText && <div className='fc-event-time'>{innerProps.timeText}</div>}
      <div className='fc-event-title-container'>
        <div className='fc-event-title fc-sticky'>
          {titleOverride || innerProps.event.title || <>&nbsp;</>}
        </div>
      </div>
    </div>
  )
}

const renderDayGridInnerContent = (innerProps: EventContentArgExt) => {
  const { titleOverride } = innerProps
  return (
    // workaround: Wrap in span element to be usable with tooltip
    <span
      className='fc-daygrid-dot-event'
      style={{
        overflow: 'auto',
        width: '100%',
        background: innerProps.isSelected ? 'rgba(0,0,0,0.1)' : '',
      }}
    >
      <div
        className='fc-daygrid-event-dot'
        style={{ borderColor: innerProps.borderColor || innerProps.backgroundColor }}
      />
      {innerProps.timeText && <div className='fc-event-time'>{innerProps.timeText}</div>}
      <div className='fc-event-title'>{titleOverride || innerProps.event.title || <>&nbsp;</>}</div>
    </span>
  )
}

const renderListInnerContent = (seg: Seg, context: ViewContext) => {
  const { titleOverride } = seg
  let interactiveAttrs = getSegAnchorAttrs(seg, context)
  return (
    <a {...interactiveAttrs}>
      {/* TODO: document how whole row become clickable */}
      {titleOverride || seg.event.title}
    </a>
  )
}

export const renderEventInnerContent = (props: EventContentArgExt) => {
  const {
    event: { _context, _def, _instance },
  } = props
  return _context.options.type === 'list'
    ? renderListInnerContent({ ...props, eventRange: { def: _def, instance: _instance } }, _context)
    : _context.options.type === 'dayGrid'
    ? renderDayGridInnerContent(props)
    : renderInnerContent(props)
}

export const Calendar = (props: CalendarProps) => {
  const history = useHistory()

  const [selectedEventId, setSelectedEventId] = useState('')

  const [selectedDate, setSelectedDate] = useState<{
    selectInfo: DateSelectArg | null
    placeholderEvent: EventApi | null
  }>({
    selectInfo: null,
    placeholderEvent: null,
  })

  const {
    getFilterValueFromInterval = defaultGetFilterValuesFromInterval,
    datesSetExtend,
    eventContentExtend,
    eventContent,
    onEventSelect,
    onEventUnselect,
    ...rest
  } = props

  const { ids, data, basePath, filterValues, setFilters, displayedFilters, resource } =
    useListContext()

  const selectEvent = (id: string) => {
    setSelectedEventId(id)
    onEventSelect && onEventSelect(id)
  }

  const unselectEvent = () => {
    onEventUnselect && onEventUnselect(selectedEventId)
    setSelectedEventId('')
  }

  const handleDatesSet = (dateInfo: DatesSetArg): void => {
    unselectEvent()
    const newFilterValues = getFilterValueFromInterval(dateInfo, filterValues)
    if (!isEqual(filterValues, newFilterValues)) {
      setFilters(newFilterValues, displayedFilters)
    }
    // Allow extending callback function
    if (datesSetExtend) datesSetExtend(dateInfo)
  }

  const handleDateSelect = (selectInfo: DateSelectArg): void => {
    const calendarApi = selectInfo.view.calendar
    if (selectedDate.placeholderEvent) {
      selectedDate.placeholderEvent.remove()
    }
    const { allDay, start, end, view } = selectInfo
    const day = 24 * 60 * 60 * 1000
    const override: { start?: Date; end?: Date; allDay?: boolean } = {}
    if (allDay && view.type === 'dayGridMonth' && end.getTime() - start.getTime() <= day) {
      override.start = new Date(start)
      override.end = new Date(start)
      override.start.setHours(6, 0)
      override.end.setHours(7, 0)
      override.allDay = false
    }
    setSelectedDate({
      selectInfo,
      placeholderEvent: calendarApi.addEvent({
        allDay: allDay,
        start: start,
        end: end,
        ...override,
        title: 'Add new event?',
        editable: false,
        color: 'rgba(200,200,200, 0.5)',
      }),
    })
    // calendarApi.unselect(); // clear date selection
    // history.push(`${basePath}/create`, {
    //   record: {
    //       start: selectInfo.startStr,
    //       end: selectInfo.endStr,
    //       allDay: selectInfo.allDay,
    //   },
    // })
  }

  const handleDateUnselect = (selectInfo: DateUnselectArg): void => {
    if (selectedDate.placeholderEvent) {
      selectedDate.placeholderEvent.remove()
    }
    setSelectedDate({ selectInfo: null, placeholderEvent: null })
  }

  const handleEventClick = (clickInfo: EventClickArg): void => {
    const { event } = clickInfo
    if (event.id) {
      if (selectedEventId === event.id) {
        history.push(linkToRecord(basePath, event.id))
      } else {
        unselectEvent()
        selectEvent(event.id)
      }
    } else {
      history.push(`${basePath}/create`, {
        record: {
          start: event.startStr,
          end: event.endStr,
          allDay: event.allDay,
        },
      })
    }
  }

  const renderEventContent = (arg: EventContentArg) => {
    return arg.event.id ? (
      eventContentExtend ? (
        eventContentExtend(
          { ...arg, isSelected: arg.event.id === selectedEventId, unselect: unselectEvent },
          renderEventInnerContent
        )
      ) : (
        eventContent
      )
    ) : (
      // <>{renderEventInnerContent(arg)}</>
      <Button
        fullWidth
        style={{ height: 'inherit' }}
        size={arg.event.allDay || arg.view.type === 'dayGridMonth' ? 'small' : 'large'}
        // label='resources.appointments.new'
        onMouseUp={() => handleEventClick(arg)}
      >
        <AddIcon />
      </Button>
    )
  }

  return (
    <OriginalCalendar
      {...rest}
      datesSet={handleDatesSet}
      eventContent={renderEventContent}
      select={handleDateSelect}
      unselectCancel='.fc-event-main, .MuiTooltip-tooltip'
      unselect={handleDateUnselect}
      selectMirror={false}
      eventClick={handleEventClick}
    />
  )
}

export interface CalendarProps extends OriginalCalendarProps {
  datesSetExtend: (arg: DatesSetArg) => void
  eventContentExtend: (
    arg: EventContentExtendArg,
    renderInner: (arg: EventContentArg) => any
  ) => any
  onEventSelect: (event_id: string) => void
  onEventUnselect: (event_id: string) => void
}

export interface EventContentExtendArg extends EventContentArg {
  isSelected: boolean
  unselect: () => void
}

export default Calendar
