import { Chip } from '@material-ui/core'
import React from 'react'
import {
  ArrayField,
  Datagrid,
  DateField,
  EditButton,
  Filter,
  List,
  SingleFieldList,
  TextField,
  TextInput,
} from 'react-admin'
import { CustomerTextField, CustomSearchFilter } from '~/components'

const CustomerFilter = (props: object) => (
  <Filter {...props}>
    <TextInput label='pos.search' source='lastname' alwaysOn />
  </Filter>
)

const EmailChip = ({ record }) => (
  <Chip
    source='email'
    size='small'
    label={record.email}
    onClick={(event) => {
      if (typeof event.stopPropagation === 'function') {
        event.stopPropagation()
      } else {
        event.cancelBubble = true
      }

      // redirect to email
      window.open(`mailto:${record.email}`, '_blank')
    }}
  />
)

const CustomerList = (props: object) => {
  const searchColums = ['firstname', 'lastname']
  return (
    <List
      title='Kunden'
      filters={<CustomSearchFilter {...props} columns={searchColums} />}
      {...props}
    >
      <Datagrid rowClick='show'>
        <CustomerTextField source='firstname' />
        <CustomerTextField source='lastname' />
        <TextField source='company' />
        <ArrayField source='emails'>
          <SingleFieldList linkType={false}>
            <EmailChip source='email' />
          </SingleFieldList>
        </ArrayField>
        <TextField source='history[0].user.display_name' label='history.modified_by' />
        <DateField source='history[0].time' showTime label='history.last_modified' />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export default CustomerList
