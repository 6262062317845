import { Reducer } from 'redux'
import { LocaleName } from '~/types'
import { changeLocale, CHANGE_LOCALE } from './actions.ts'

type State = LocaleName
type Action = ReturnType<typeof changeLocale> | { type: 'OTHER_ACTION'; payload?: any }

export * from './actions'
/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (previousState = 'de', action): Reducer<State, Action> => {
  if (action.type === CHANGE_LOCALE) {
    return action.payload
  }
  return previousState
}
