import React from 'react'
import { Create, SimpleForm } from 'react-admin'
import { UpsertCustomer } from '~/components'

const CustomerCreate = (props: object) => {
  return (
    <Create title='resources.customers.create' {...props}>
      <SimpleForm submitOnEnter={true} redirect='show'>
        <UpsertCustomer {...props} />
      </SimpleForm>
    </Create>
  )
}

export default CustomerCreate
