import { routerMiddleware } from 'connected-react-router'
import { History } from 'history'
import {
  adminSaga,
  AuthProvider,
  CLEAR_STATE,
  createAppReducer,
  DataProvider,
  I18nProvider,
  InitialState,
} from 'react-admin'
import { applyMiddleware, compose, createStore } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'
import { all, fork } from 'redux-saga/effects'
import CalendarReducer from './calendar'
import LocaleReducer from './locale'
import ThemeReducer from './theme'

interface Window {
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: (traceOptions: object) => Function
}

interface Params {
  dataProvider: DataProvider
  history: History
  authProvider?: AuthProvider
  customReducers?: any
  customSagas?: any[]
  i18nProvider?: I18nProvider
  initialState?: InitialState
  locale?: string
}

export * from './calendar'
export * from './locale'
export * from './theme'
/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default ({
  dataProvider,
  history,
  customReducers = {},
  authProvider = null,
  customSagas = [],
  initialState,
}: Params) => {
  const appReducer = createAppReducer(
    {
      theme: ThemeReducer,
      locale: LocaleReducer,
      calendar: CalendarReducer,
      ...customReducers,
    },
    history
  )

  const resettableAppReducer = (state, action) =>
    appReducer(
      action.type !== CLEAR_STATE
        ? state
        : // Erase data from the store but keep location, notifications, ui prefs, etc.
          // This allows e.g. to display a notification on logout
          {
            ...state,
            admin: {
              ...state.admin,
              loading: 0,
              resources: {},
              customQueries: {},
              references: { oneToMany: {}, possibleValues: {} },
            },
          },
      action
    )
  const saga = function* rootSaga() {
    yield all([adminSaga(dataProvider, authProvider), ...customSagas].map(fork))
  }
  const sagaMiddleware = createSagaMiddleware()
  const typedWindow = typeof window !== 'undefined' && (window as Window)

  const composeEnhancers =
    (process.env.NODE_ENV === 'development' &&
      typeof typedWindow !== 'undefined' &&
      typedWindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      typedWindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
      })) ||
    compose

  const persistedReducer = persistReducer(
    {
      key: 'root',
      storage,
    },
    resettableAppReducer
  )

  const store = createStore(
    persistedReducer,
    typeof initialState === 'function' ? initialState() : initialState,
    composeEnhancers(applyMiddleware(sagaMiddleware, routerMiddleware(history)))
  )
  sagaMiddleware.run(saga)
  return { store, persistor: persistStore(store) }
}

/*

import { connectRouter, routerMiddleware } from 'connected-react-router'
import { adminReducer, adminSaga, USER_LOGOUT } from 'react-admin'
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import createSagaMiddleware from 'redux-saga'
import { all, fork } from 'redux-saga/effects'
import LocaleReducer from './locale'
import ThemeReducer from './theme'

export * from './locale'
export * from './theme'
export default ({ authProvider, dataProvider, history }) => {
  const reducer = combineReducers({
    admin: adminReducer,
    router: connectRouter(history),
    // add your own reducers here
    theme: ThemeReducer,
    locale: LocaleReducer,
  })
  const resettableAppReducer = (state, action) =>
    reducer(action.type !== USER_LOGOUT ? state : undefined, action)

  const saga = function* rootSaga() {
    yield all(
      [
        adminSaga(dataProvider, authProvider),
        // add your own sagas here
      ].map(fork)
    )
  }
  const sagaMiddleware = createSagaMiddleware()

  const composeEnhancers =
    (process.env.NODE_ENV === 'development' &&
      typeof window !== 'undefined' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
      })) ||
    compose

  const persistedReducer = persistReducer(
    {
      key: 'root',
      storage,
    },
    resettableAppReducer
  )
  const store = createStore(
    resettableAppReducer,
    // inital state
    { theme: 'light' },
    composeEnhancers(
      applyMiddleware(
        sagaMiddleware,
        routerMiddleware(history)
        // add your own middlewares here
      )
      // add your own enhancers here
    )
  )
  sagaMiddleware.run(saga)
  return { store, persistor: persistStore(store) }
}*/
