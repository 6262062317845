import gql from 'graphql-tag'
import { GET_LIST, GET_ONE } from 'react-admin'
import { upsertQuery } from '~/helpers'

const fields = gql`
  {
    id
    slug
    translated_name
    position
    categories(order_by: { _order: asc }) {
      id
      name
      description
      translated_name
      translated_description
      attributes(order_by: { _order: asc }) {
        id
        name
        input_type
        description
        default_value
        suffix
        show_by_default
        hidden
        choices {
          id
          key
          name
          translated_name
        }
        selection_id
        selection {
          id
          name
          choices(order_by: { _order: asc }) {
            id
            key
            name
            translated_name
          }
        }
      }
    }
  }
`

export default upsertQuery(
  {
    [GET_LIST]: {
      buildFields: fields,
    },
    [GET_ONE]: {
      buildFields: fields,
    },
  },
  {
    relations: ['categories'],
  }
)
