import { Chip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import get from 'lodash/get'
import { useRecordContext } from 'react-admin'

const useStyles = makeStyles({
  preferredSalutation: { 'font-weight': 'bold' },
})

const CustomerChipField = ({ source, ...rest }) => {
  const classes = useStyles()
  const record = useRecordContext(rest)
  const customerPath = source
  const customer = customerPath ? get(record, customerPath) : record

  if (!customer) return null

  const classNames = {
    firstname: customer.informal_salutation ? classes.preferredSalutation : '',
    lastname: customer.informal_salutation ? '' : classes.preferredSalutation,
  }
  const value = (
    <>
      <span className={classNames.firstname}>{customer.firstname}</span>{' '}
      <span className={classNames.lastname}>{customer.lastname}</span>
    </>
  )

  return <Chip label={value} {...rest} />
}

CustomerChipField.displayName = 'CustomerChipField'

CustomerChipField.defaultProps = {
  addLabel: true,
}

export default CustomerChipField
