import { useState } from 'react'
import { Edit, ListButton, SimpleForm, TopToolbar, useTranslate } from 'react-admin'
import { ToggleShowButton, UpsertDocument } from '~/components'

const DocumentName = ({ record }: { record?: { title: string } }) => {
  const translate = useTranslate()
  return (
    <span>
      {record ? translate(`resources.documents.types.${record.type}`) : ''}:{' '}
      {record?.document_boat?.length > 0 ? record.document_boat[0].boat.model : ''}
    </span>
  )
}

const DocumentEditActions = ({ basePath, data, showPreview, setShowPreview }) => (
  <TopToolbar>
    <ToggleShowButton showState={showPreview} setShowState={setShowPreview} label='PDF Preview' />
    <ListButton basePath={basePath} record={data} label='ra.action.back' />
  </TopToolbar>
)

export const DocumentEdit = (props: object, record: object) => {
  const [showPreview, setShowPreview] = useState(false)

  return (
    <Edit
      title={<DocumentName />}
      actions={<DocumentEditActions showPreview={showPreview} setShowPreview={setShowPreview} />}
      {...props}
    >
      <SimpleForm redirect='list' warnWhenUnsavedChanges>
        <UpsertDocument {...props} showPreview={showPreview} />
      </SimpleForm>
    </Edit>
  )
}

export default DocumentEdit
