import { FormControl, InputLabel, ListSubheader, MenuItem, Select } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import groupBy from 'lodash/groupBy'
import { useEffect, useState } from 'react'
import { LinearProgress, useGetList, useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import { Categories } from '~/graphql/generated'

const useStyles = makeStyles((theme) => {
  console.log(theme)
  return {
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    label: {
      background: theme.palette.background.default,
      padding: '0 2px',
    },
  }
})

const ToolbarAddBoatCategory = (props) => {
  const classes = useStyles(props)
  const translate = useTranslate()
  const { change, getState } = useForm()
  const { values } = getState()
  const [categoryId, setCategory] = useState('')

  const { data: categories, loading } = useGetList<Categories>('categories')

  const categoriesBySection = groupBy(categories, (category) => category.section?.slug)

  useEffect(() => {
    if (categoryId === '') return

    change('boat_section_category', [
      ...(values.boat_section_category ?? []),
      {
        category_id: categoryId,
      },
    ])
    setCategory('')
  }, [categoryId, setCategory, change])

  return loading ? (
    <LinearProgress />
  ) : (
    <>
      <FormControl variant='outlined' margin='dense' className={classes.formControl}>
        <InputLabel className={classes.label} id='category-label'>
          {translate('resources.categories.title')}
        </InputLabel>
        <Select
          labelId='category-label'
          onChange={(e) => setCategory(e.target.value)}
          value={categoryId}
        >
          {Object.keys(categoriesBySection).map((sectionSlug) => (
            <>
              <ListSubheader>{sectionSlug}</ListSubheader>
              {Object.values(categoriesBySection[sectionSlug]).map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.name}
                </MenuItem>
              ))}
            </>
          ))}
        </Select>
      </FormControl>
    </>
  )
}

export default ToolbarAddBoatCategory
