import React from 'react'
import { SimpleForm, Toolbar, useCreate, useCreateSuggestionContext } from 'react-admin'
import { CreateModal, UpsertCustomer } from '~/components'

const CreateCustomer = () => {
  const { filter, onCancel, onCreate } = useCreateSuggestionContext()
  const [create] = useCreate('customers')
  const initialValues = () => {
    const [first, last] = filter?.split(' ') || []
    return {
      firstname: last ? first : '',
      lastname: last || first,
    }
  }

  const handleSubmit = (values) => {
    create(
      {
        payload: {
          data: {
            ...values,
          },
        },
      },
      {
        onSuccess: ({ data }) => {
          onCreate(data)
        },
      }
    )
  }

  return (
    <CreateModal title='resources.customers.create' open onClose={onCancel} resource='customers'>
      <SimpleForm
        redirect={false}
        save={handleSubmit}
        initialValues={initialValues}
        toolbar={<Toolbar alwaysEnableSaveButton />}
      >
        <UpsertCustomer essentialOnly />
      </SimpleForm>
    </CreateModal>
  )
}

export default CreateCustomer
