import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes'
import SpeakerNotesOffIcon from '@material-ui/icons/SpeakerNotesOff'
import ToggleShowButton from './ToggleShowButton'

const ToggleNotesButton = ({ showNotes, setShowNotes, ...rest }) => (
  <ToggleShowButton
    {...rest}
    showState={showNotes}
    setShowState={setShowNotes}
    label='pos.notes.show'
    hideLabel='pos.notes.hide'
    icon={SpeakerNotesIcon}
    hideIcon={SpeakerNotesOffIcon}
  />
)

export default ToggleNotesButton
