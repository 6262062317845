import path from 'path'
// const { REACT_APP_IMAGE_STORAGE } = process.env

export const uploadBlob = (blob, formFile, storage) => {
  const file = formFile.rawFile

  /*
  let fileBlob = null
  if (blob) {
    fileBlob = blob
  } else {
    fileBlob = file.slice(0, file.size, file.type)
  }
  */

  const fileName = formFile.src.split('/').pop()
  const fileExtension = path.extname(file.name)
  const newFilename = fileName + fileExtension
  //const newFile = new File([fileBlob], newFilename, { type: file.type })
  // const formData = new FormData()
  //formData.append('file', newFile)

  return new Promise((resolve, reject) => {
    storage
      .putPrivate(newFilename, blob)
      .then((result) => {
        resolve(result)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export default uploadBlob
