import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import { Rowing } from '@material-ui/icons'
import get from 'lodash/get'
import { LinearProgress, SelectField, useLocale } from 'react-admin'
import NumberFormat from 'react-number-format'
import { CategoryEntryIcon, Empty, SectionLabel } from '~/components'
import { Boats, Boat_Section_Attribute } from '~/graphql/generated'
import { standardDecimalFormat } from '~/helpers'

const useStyles = makeStyles((theme) => ({
  twoColumns: {
    display: 'grid',
    'grid-template-columns': 'repeat(2, 1fr)',
    // '-webkit-columns': 3,
    // '-moz-columns': 3,
    // 'list-style-position': 'inside',
  },
}))

const translated = (record, prop, locale) => {
  return get(record, `translated_${prop}.${locale}`) || record[prop]
}

const BoatAttribute = ({ attribute }: { attribute: Boat_Section_Attribute }) => {
  const locale = useLocale()
  const {
    attribute: { name, input_type, suffix, choices, selection },
    value,
  } = attribute
  if (!value || !input_type) {
    return null
  }

  const translated_name = translated(attribute.attribute, 'name', locale)

  return input_type === 'select' || input_type === 'shared_select' ? (
    <li key={attribute.id}>
      {translated_name}:{' '}
      <SelectField
        record={attribute}
        source='value'
        choices={input_type === 'select' ? choices || [] : selection?.choices || []}
        optionValue='key'
        optionText={(c) => get(c, `translated_name.${locale}`) || c.key}
      />
    </li>
  ) : input_type === 'numeric' ? (
    <li>
      {translated_name}:{' '}
      <NumberFormat displayType='text' value={value} {...standardDecimalFormat} />
      {translated(attribute.attribute, 'suffix', locale)}
    </li>
  ) : input_type === 'boolean' ? (
    value === 'true' ? (
      <li>{translated_name}</li>
    ) : null
  ) : (
    <li>
      {translated_name}: {value}
      {translated(attribute.attribute, 'suffix', locale)}
    </li>
  )
}

const ShowBoatCategories = ({ record: boat }: { record: Boats }) => {
  const classes = useStyles()
  const locale = useLocale()

  return (
    <div className='MuiTypography-body2'>
      {boat.boat_section &&
        (boat.boat_section.length > 0 ? (
          boat.boat_section.map((boat_section) => (
            <div key={boat_section.id}>
              <Typography variant='h6' color='textSecondary'>
                <SectionLabel section={boat_section.section} />
              </Typography>
              <Grid container>
                {boat_section.boat_section_category.map((equipment) =>
                  equipment.category ? (
                    <Grid item md={6} key={equipment.id}>
                      <Typography>
                        {equipment.name
                          ? equipment.name
                          : translated(equipment.category, 'name', locale)}{' '}
                        {(equipment.notes_icon || equipment.notes) && (
                          <Tooltip title={equipment.notes || ''}>
                            <span>
                              <CategoryEntryIcon
                                values={equipment}
                                fontSize='small'
                                style={{ marginBottom: '-0.2em' }}
                              />
                            </span>
                          </Tooltip>
                        )}
                      </Typography>
                      <Typography variant='caption'>
                        {equipment.description
                          ? equipment.description
                          : translated(equipment.category, 'description', locale)}
                      </Typography>
                      <ul>
                        {equipment.boat_section_attribute.map((attribute) =>
                          attribute.attribute ? (
                            <BoatAttribute attribute={attribute} key={attribute.id} />
                          ) : (
                            <LinearProgress />
                          )
                        )}
                      </ul>
                    </Grid>
                  ) : (
                    <LinearProgress />
                  )
                )}
              </Grid>
            </div>
          ))
        ) : (
          <Empty icon={<Rowing />} message='resources.boats.empty_attributes' />
        ))}
    </div>
  )
}
export default ShowBoatCategories
