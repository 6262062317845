import { DirectionsBoat as TypeIcon, Healing as StatusIcon } from '@material-ui/icons'
import { Datagrid, EditButton, List, TextField, useRecordContext } from 'react-admin'
import {
  ChoiceFilter,
  CustomerTextField,
  ExpandBoat,
  FilterSidebar,
  LinkToRecord,
} from '~/components'
import { boatSellers } from '~/helpers'
import { boatStatusChoices, boatTypeChoices } from '~/types/choices'

const BoatSellerField = ({ source }) => {
  const record = useRecordContext()
  const seller = boatSellers(record, source)[0]
  return seller ? (
    <span>
      <LinkToRecord record={seller} basePath='/customers' id='customer_id' disabled>
        <CustomerTextField source='customer.firstname' />
        <CustomerTextField source='customer.lastname' />
      </LinkToRecord>
    </span>
  ) : null
}

const BoatList = (props: object) => (
  <List
    title='resources.boats.title'
    aside={
      <FilterSidebar search='model'>
        <ChoiceFilter
          choices={boatStatusChoices}
          source='status'
          label='resources.boats.fields.status'
          icon={<StatusIcon />}
        />
        <ChoiceFilter
          choices={boatTypeChoices}
          source='boat_type'
          label='resources.boats.fields.boat_type'
          icon={<TypeIcon />}
        />
      </FilterSidebar>
    }
    bulkActionButtons={false}
    sort={{ field: 'model', order: 'ASC' }}
    {...props}
  >
    <Datagrid expand={<ExpandBoat />} rowClick='show'>
      <TextField source='model' label='resources.boats.fields.model' />
      <BoatSellerField
        source='boat_customer'
        label='resources.relations.types.seller'
        sortable={false}
      />
      <TextField source='status' label='resources.boats.fields.status' />
      <EditButton />
    </Datagrid>
  </List>
)

export default BoatList
