import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import React, { Dispatch, SetStateAction } from 'react'
import { Button } from 'react-admin'

const ToggleShowButton = ({
  showState,
  setShowState,
  icon,
  hideIcon,
  label,
  hideLabel,
  disabled,
}: ToggleShowButtonProps) => {
  return showState ? (
    <Button onClick={() => setShowState(false)} label={hideLabel || label} disabled={disabled}>
      {React.createElement(hideIcon || icon || VisibilityOffIcon)}
    </Button>
  ) : (
    <Button onClick={() => setShowState(true)} label={label} disabled={disabled}>
      {React.createElement(icon || VisibilityIcon)}
    </Button>
  )
}

export interface ToggleShowButtonProps {
  showState: boolean
  setShowState: Dispatch<SetStateAction<boolean>>
  icon?: React.ComponentType
  hideIcon?: React.ComponentType
  label: string
  hideLabel?: string
  disabled?: boolean
}

export default ToggleShowButton
