import { KeyboardDatePicker as MaterialDatePicker } from '@material-ui/pickers'
import { useCallback } from 'react'
import { useForm } from 'react-final-form'
import { PropsCreateEdit, sanitizeProps } from '~/helpers'

export const DatePicker = ({ record = {}, source, basePath, ...rest }) => {
  const form = useForm()
  const change = useCallback(
    (date) => {
      if (!date) {
        return
      }
      if (!date.isValid()) {
        return
      }
      form.change(source, date.toDate())
    },
    [form, source]
  )
  return (
    <MaterialDatePicker
      value={record[source]}
      onChange={change}
      animateYearScrolling
      {...sanitizeProps(rest, [...PropsCreateEdit, 'className'])}
      inputVariant='filled'
      margin='dense'
    />
  )
}

export default DatePicker
