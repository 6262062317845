import { useState } from 'react'
import {
  ArrayField,
  Datagrid,
  EditButton,
  EmailField,
  FormTab,
  ListButton,
  TextField,
  TopToolbar,
} from 'react-admin'
import {
  CompactShow,
  CreateButton,
  CustomerTextField,
  DynamicField,
  EditAppointments,
  EditAttachments,
  EditComments,
  LinkToRecord,
} from '~/components'

const CustomerShowActions = ({ basePath, data }: { basePath?: string, data?: object }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} label='ra.action.back' />
    <EditButton basePath={basePath} record={data} />
  </TopToolbar>
)

const CustomerShow = (props: object) => {
  const relation = [props.resource, props.id]
  const stateCreateComment = useState(false)
  const stateCreateAttachment = useState(false)
  const stateCreateAppointment = useState(false)

  return (
    <CompactShow
      {...props}
      component='div'
      // eslint-disable-next-line no-template-curly-in-string
      title={<DynamicField source='Kunde: ${record.firstname} ${record.lastname}' />}
      actions={<CustomerShowActions />}
      aside={[
        <TextField source='title' />,
        <TextField source='salutation' size='2' />,
        <CustomerTextField source='firstname' />,
        <CustomerTextField source='lastname' />,
        <CustomerTextField source='birth_name' />,
        <CustomerTextField source='birthday' />,
        <TextField source='nationality' />,
        <TextField source='identity_card_number' />,
        <ArrayField source='emails' currentSort={{ field: 'type', order: 'ASC' }} size='3'>
          <Datagrid>
            <TextField source='type' />
            <EmailField source='email' />
          </Datagrid>
        </ArrayField>,
        <ArrayField source='phones' currentSort={{ field: 'type', order: 'ASC' }} size='3'>
          <Datagrid>
            <TextField source='type' />
            <TextField source='number' />
          </Datagrid>
        </ArrayField>,
        <TextField source='company' />,
        <TextField source='street' size='2' />,
        <TextField source='postal_code' />,
        <TextField source='city' />,
        <TextField source='country' />,
        <ArrayField
          source='customer_customer'
          label='resources.customers.types.relation_to'
          currentSort={{ field: 'type', order: 'ASC' }}
          size='3'
        >
          <Datagrid>
            <TextField source='relation_type' />
            <LinkToRecord basePath='/customers' id='related_customer_id'>
              <CustomerTextField source='related.firstname' />
              <CustomerTextField source='related.lastname' />
            </LinkToRecord>
          </Datagrid>
        </ArrayField>,
        <ArrayField
          source='related_customer'
          label='resources.customers.types.relation_from'
          currentSort={{ field: 'type', order: 'ASC' }}
          size='3'
        >
          <Datagrid>
            <TextField source='relation_type' />
            <LinkToRecord basePath='/customers' id='customer_id'>
              <CustomerTextField source='customer.firstname' />
              <CustomerTextField source='customer.lastname' />
            </LinkToRecord>
          </Datagrid>
        </ArrayField>,
        <ArrayField
          source='boat_customer'
          label='resources.customers.types.boats'
          currentSort={{ field: 'type', order: 'ASC' }}
          size='3'
        >
          <Datagrid>
            <TextField source='relation_type' />
            <LinkToRecord id='boat_id' linkType='show' basePath='/boats'>
              <TextField source='boat.model' />
            </LinkToRecord>
          </Datagrid>
        </ArrayField>,
      ]}
      toolbars={[
        <CreateButton
          onClick={(e) => {
            e.stopPropagation()
            stateCreateComment[1](true)
          }}
        />,
        <CreateButton
          onClick={(e) => {
            e.stopPropagation()
            stateCreateAttachment[1](true)
          }}
        />,
      ]}
    >
      <FormTab label='resources.comments.title'>
        <ArrayField source='comments' label=''>
          <EditComments disableDelete relation={relation} stateCreate={stateCreateComment} />
        </ArrayField>
      </FormTab>

      <FormTab label='resources.attachments.title'>
        <ArrayField source='attachments' label=''>
          <EditAttachments disableDelete relation={relation} stateCreate={stateCreateAttachment} />
        </ArrayField>
      </FormTab>

      <FormTab label='resources.appointments.title'>
        <ArrayField source='appointment_customer' label=''>
          <EditAppointments
            disableDelete
            relation={relation}
            stateCreate={stateCreateAppointment}
          />
        </ArrayField>
      </FormTab>
    </CompactShow>
  )
}

export default CustomerShow
