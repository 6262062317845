import React from 'react'
import { Create, required, SimpleForm, TextInput } from 'react-admin'

const RoleCreate = (props: object) => {
  return (
    <Create title='resources.roles.create' {...props}>
      <SimpleForm redirect='list'>
        <TextInput source='name' validate={[required()]} label='resources.roles.fields.name' />
      </SimpleForm>
    </Create>
  )
}

export default RoleCreate
