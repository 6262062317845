import { CalendarViewName } from '~/types'

export const CHANGE_VIEW = 'CHANGE_VIEW'
export const CHANGE_DATES = 'CHANGE_DATES'

export const changeView = (view: CalendarViewName) => ({
  type: CHANGE_VIEW,
  payload: view,
})

export const changeDates = (start: Date, end: Date) => ({
  type: CHANGE_DATES,
  payload: { start, end },
})
