import get from 'lodash/get'
import { FileField, ImageField } from 'react-admin'

export const FileImageField = ({
  record,
  typeSource = 'rawFile.type',
  captionSource = 'caption',
  uriPrefix = '',
  source,
  ...rest
}) => {
  const type = get(record, typeSource)
  const pRecord = uriPrefix ? { ...record, [source]: `${uriPrefix}${record[source]}` } : record
  return type.startsWith('image') ? (
    <figure>
      <ImageField record={pRecord} source={source} {...rest} />
      {pRecord[captionSource] && <figcaption>{pRecord[captionSource]}</figcaption>}
    </figure>
  ) : (
    <FileField record={pRecord} source={source} target='_blank' {...rest} />
  )
}

export default FileImageField
