import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Inbox from '@material-ui/icons/Inbox'
import { useGetResourceLabel, useListContext, useResourceContext, useTranslate } from 'ra-core'
import * as React from 'react'
import { FC } from 'react'
import { ClassesOverride, CreateButton } from 'react-admin'

const useStyles = makeStyles(
  (theme) => ({
    message: {
      textAlign: 'center',
      opacity: theme.palette.type === 'light' ? 0.5 : 0.8,
      margin: '0 1em',
      color: theme.palette.type === 'light' ? 'inherit' : theme.palette.text.primary,
    },
    icon: {
      width: '9em',
      height: '9em',
    },
    toolbar: {
      textAlign: 'center',
      marginTop: '2em',
    },
  }),
  { name: 'RaEmpty' }
)

const Empty: FC<EmptyProps> = (props) => {
  const { basePath, hasCreate } = useListContext(props)
  const resource = useResourceContext(props)
  const classes = useStyles(props)
  const translate = useTranslate()
  const { icon = Inbox } = props

  const getResourceLabel = useGetResourceLabel()
  const resourceName = translate(`resources.${resource}.forcedCaseName`, {
    smart_count: 0,
    _: getResourceLabel(resource, 0),
  })

  const emptyMessageDefault = translate(`resources.${resource}.empty`, {
    _: translate('ra.page.empty', { name: resourceName }),
  })
  const inviteMessageDefault = translate(`resources.${resource}.invite`, {
    _: translate('ra.page.invite'),
  })
  const emptyMessage = props.message
    ? translate(props.message, { _: props.message })
    : emptyMessageDefault
  const inviteMessage = props.message
    ? translate(props.message, { _: props.message })
    : inviteMessageDefault

  return (props.ids ?? []).length ? (
    props.children
  ) : (
    <>
      <div className={classes.message}>
        <icon.type className={classes.icon} />
        <Typography variant='h4' paragraph>
          {emptyMessage}
        </Typography>
        {hasCreate && <Typography variant='body1'>{inviteMessage}</Typography>}
      </div>
      {hasCreate && (
        <div className={classes.toolbar}>
          <CreateButton variant='contained' basePath={basePath} />
        </div>
      )}
    </>
  )
}

export interface EmptyProps {
  classes?: ClassesOverride<typeof useStyles>
  resource?: string
  message?: string
}

export default Empty
