import React from 'react'
import { Edit, ListButton, SimpleForm, TopToolbar, useTranslate } from 'react-admin'
import { UpsertAppointment } from '~/components'

const AppointmentName = ({ record }: { record?: { title: string } }) => {
  const translate = useTranslate()
  return (
    <span>
      {record ? translate(`resources.appointments.types.${record.type}`) : ''}:{' '}
      {record ? `${record.title}` : ''}
    </span>
  )
}

const AppointmentEditActions = ({ basePath, data }: { basePath?: string, data?: object }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} label='ra.action.back' />
  </TopToolbar>
)

export const AppointmentEdit = (props: object, record: object) => {
  return (
    <Edit title={<AppointmentName />} actions={<AppointmentEditActions />} {...props}>
      <SimpleForm redirect='list' warnWhenUnsavedChanges>
        <UpsertAppointment {...props} />
      </SimpleForm>
    </Edit>
  )
}

export default AppointmentEdit
