import { makeStyles } from '@material-ui/core/styles'
import { cloneElement } from 'react'
import { DeleteButton, SaveButton, Toolbar } from 'react-admin'
import { Route } from 'react-router-dom'
import { PropsForm, reduceProps } from '~/helpers'

const useToolbarStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

const TabToolbar = ({ toolbars = [], resource, match, ...rest }) => {
  const props = reduceProps(rest, PropsForm)
  const standard = toolbars[0] || (
    <>
      <SaveButton {...props} />
      <DeleteButton {...props} />
    </>
  )
  const render = ({ match }) => {
    const toolbar = toolbars[match.params.tab_index]
    return toolbar ? cloneElement(toolbars[match.params.tab_index], props) : null
  }

  return (
    <Toolbar {...props} classes={useToolbarStyles()}>
      {match ? (
        <>
          <Route exact path={`${match.path}/:tab_index`} render={render} />
          <Route exact path={match.path} render={() => standard} />
        </>
      ) : (
        standard
      )}
    </Toolbar>
  )
}

export default TabToolbar
