import gql from 'graphql-tag'
import { GET_LIST, GET_MANY, GET_ONE } from 'react-admin'
import { upsertQuery } from '~/helpers'

// todo: this is stupid: https://stackoverflow.com/questions/54516028/get-list-is-overriding-get-one-request
// find way to rather merge storage representation of records instaed of overwriting it..
/* then replace GET_LIST with only needed data
      `
        {
          id
          firstname
          lastname
          company
          phones {
            id
            type
            number
          }
          emails {
            id
            type
            email
          }
        }
      `
*/

const fields = gql`
  {
    id
    firstname
    lastname
    birth_name
    salutation
    title
    company
    identity_card_number
    nationality
    birthday
    street
    postal_code
    city
    country
    informal_salutation
    phones {
      id
      type
      number
    }
    emails {
      id
      type
      email
    }
    customer_customer {
      id
      relation_type
      related_customer_id
      related {
        id
        firstname
        lastname
        informal_salutation
      }
    }
    boat_customer {
      id
      boat_id
      customer_id
      relation_type
      boat {
        id
        name
        model
      }
    }
    related_customer {
      id
      relation_type
      customer_id
      customer {
        id
        firstname
        lastname
        informal_salutation
      }
    }
    comments {
      id
      created_at
      updated_at
      type
      comment
      linked_type
      linked_id
      pictures {
        id
        created_at
        updated_at
        type
        uri
        content_type
        title
        caption
      }
    }
    attachments {
      id
      created_at
      updated_at
      type
      uri
      content_type
      title
    }
    appointment_customer {
      id
      appointment_id
      appointment {
        uid
        start
        end
        title
        type
        allDay
        appointment_boat {
          id
          boat_id
          boat {
            name
            model
          }
        }
      }
    }
  }
`

export default upsertQuery(
  {
    [GET_ONE]: {
      buildFields: fields,
    },
    [GET_LIST]: {
      buildFields: fields,
    },
    [GET_MANY]: {
      buildFields: fields,
    },
  },
  {
    relations: ['phones', 'emails', 'customer_customer', 'boat_customer'],
    ignore: ['related_customer', 'boat_customer'],
  }
)
/*
  ['comments', 'attachments'],
  ['firstname', 'lastname']
  */
