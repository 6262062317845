import { makeStyles } from '@material-ui/core/styles'
import { useTranslate } from 'react-admin'
import NumberFormat from 'react-number-format'
import { CustomerTextField, LinkToRecord } from '~/components'
import { Boats, Customers } from '~/graphql/generated'
import { boatSellers, moneyEuroFormat } from '~/helpers'

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 400,
    padding: theme.spacing(2, 4, 3),
  },
}))

const CustomerPhones = ({ customer }: { customer: Customers }) => {
  return customer.phones ? (
    <span>{customer.phones.map((phone) => `, ${phone.number} (${phone.type})`)}</span>
  ) : null
}

const ExpandBoat = ({ record: boat }: { record: Boats }) => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <div className={classes.paper}>
      <p>
        <b>{translate('resources.boats.fields.manufacturer')} :</b> {boat.manufacturer}
      </p>
      <p>
        <b>{translate('resources.boats.fields.model')} :</b> {boat.model}
      </p>
      <p>
        <b>{translate('resources.boats.fields.build_number')} :</b> {boat.build_number}
      </p>
      <p>
        <b>{translate('resources.boats.fields.build_year')} :</b> {boat.build_year}
      </p>

      <b>{translate('resources.customers.types.seller')} :</b>
      <ul>
        {boatSellers(boat).map((seller) => {
          return (
            <li key={seller.customer_id}>
              <LinkToRecord record={seller} basePath='customers' id='customer_id'>
                <CustomerTextField source='customer.firstname' />
                <CustomerTextField source='customer.lastname' />
              </LinkToRecord>
              <CustomerPhones customer={seller.customer} />
            </li>
          )
        })}
      </ul>

      <p>
        <b>{translate('resources.boats.fields.summer_storage')} :</b> {boat.summer_storage}
      </p>
      <p>
        <b>{translate('resources.boats.fields.winter_storage')} :</b> {boat.winter_storage}
      </p>
      <p>
        <b>{translate('resources.boats.fields.asking_price')} :</b>{' '}
        <NumberFormat displayType='text' value={boat.asking_price} {...moneyEuroFormat} />
      </p>
      <p>
        <b>{translate('resources.boats.fields.lower_price_limit')} :</b>{' '}
        <NumberFormat displayType='text' value={boat.lower_price_limit} {...moneyEuroFormat} />
      </p>
    </div>
  )
}
export default ExpandBoat
