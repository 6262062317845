import React from 'react'
import { Create, ListButton, SimpleForm, TopToolbar } from 'react-admin'
import { UpsertAppointment } from '~/components'

const AppointmentCreateActions = ({ basePath, data }: { basePath?: string, data?: object }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} label='ra.action.back' />
  </TopToolbar>
)

const AppointmentCreate = (props: object) => {
  return (
    <Create title='resources.appointments.create' actions={<AppointmentCreateActions />} {...props}>
      <SimpleForm redirect='list' warnWhenUnsavedChanges>
        <UpsertAppointment {...props} />
      </SimpleForm>
    </Create>
  )
}

export default AppointmentCreate
