import { get } from 'flocky'
import { Children, cloneElement } from 'react'
import { Link, linkToRecord } from 'react-admin'

export const LinkToRecord = ({
  record,
  basePath,
  id = 'id',
  text = record[id],
  linkType = 'show',
  children,
  childrenSeparator = ' ',
  disabled = false,
}) => {
  const linkToUser = linkToRecord(basePath, get(record, id), linkType)
  const label = children
    ? Children.map(children, (child, index) => {
        return (
          <span>
            {!!index && childrenSeparator}
            {cloneElement(child, { record, ...child.props })}
          </span>
        )
      })
    : eval('try{`' + text + '`} catch(e) { "" }')

  return disabled ? <span>{label}</span> : <Link to={linkToUser}>{label}</Link>
}

export default LinkToRecord
