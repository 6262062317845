import {
  Datagrid,
  DateField,
  FunctionField,
  ReferenceField,
  SelectField,
  TextField,
  useResourceContext,
} from 'react-admin'
import { JsonField } from 'react-admin-json-view'
import { CustomerTextField, SelectionField, TranslateField } from '~/components'
import { History, History_Transactions } from '~/graphql/generated'
import { documentTypeChoices } from '~/types/choices'

const EnityRefNameField = () => {
  const resource = useResourceContext()
  switch (resource) {
    case 'customers':
      return (
        <>
          <CustomerTextField source='firstname' /> <CustomerTextField source='lastname' />
        </>
      )
    case 'boats':
      return (
        <>
          <TextField source='model' /> (<TextField source='name' />)
        </>
      )
    case 'appointments':
      return (
        <>
          <TextField source='title' />
          <SelectionField source='type' />
        </>
      )
    case 'documents':
      return (
        <SelectField
          source='type'
          choices={documentTypeChoices}
          label='resources.documents.fields.type'
        />
      )
    default:
      return <TextField source='name' />
  }
}

export const HistoryTransactionDatagrid = ({ hideRelation }) => {
  return (
    <Datagrid
      expand={
        <FunctionField
          source='history'
          render={(history_tx: History_Transactions) =>
            history_tx.history
              // .filter(h => JSON.stringify(h.change) !== '{}')
              .map((history: History) => (
                <JsonField
                  record={history}
                  source='change'
                  reactJsonOptions={{
                    name:
                      history.relation !== history_tx.relation ||
                      history.relation_id !== history_tx.relation_id
                        ? `${history.operation} ${history.relation}(${history.relation_id})`
                        : null,
                    collapsed: false,
                    enableClipboard: true,
                    displayDataTypes: false,
                  }}
                  addLabel={false}
                />
              ))
          }
        />
      }
    >
      {!hideRelation && (
        <TranslateField
          // eslint-disable-next-line no-template-curly-in-string
          translate='resources.${record.relation}.title'
          source='relation'
          label='resources.history.fields.relation'
        />
      )}
      {!hideRelation && (
        <FunctionField
          source='relation_id'
          label='id/Ref'
          render={({ relation, operation }: History_Transactions) =>
            operation !== 'DELETE' ? (
              <>
                <TextField source='relation_id' />{' '}
                <ReferenceField source='relation_id' reference={relation} link='show'>
                  <EnityRefNameField />
                </ReferenceField>
              </>
            ) : (
              <TextField source='relation_id' />
            )
          }
        />
      )}
      <TranslateField
        // eslint-disable-next-line no-template-curly-in-string
        translate='resources.history.operations.${record.operation}'
        source='operation'
        label='resources.history.fields.operation'
      />
      <DateField source='action_tstamp_tx' showTime label='resources.history.fields.time' />
      <TextField source='user.display_name' label='resources.users.title' />
      <TextField source='user.account.role.role' label='resources.users.fields.role' />
    </Datagrid>
  )
}

export default HistoryTransactionDatagrid
