import { Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { Edit, useTranslate } from 'react-admin'
import { FormSpy } from 'react-final-form'
import { injectElement, reduceProps } from '~/helpers'

/*
 * Custom css for dialog
 */
const useStyles = makeStyles({
  scrollPaper: {
    alignItems: 'baseline',
  },
  root: {
    '& div.MuiDialogContent-root': {
      padding: 0,
    },
  },
})

/*
 * EditModal
 */
const EditModal = (props: object) => {
  const { fullWidth, title, children, state, onClose, ...rest } = props
  const [open, setOpen] = state
  const translate = useTranslate()
  const [dirtyState, setDirtyState] = React.useState(false)

  const handleClose = () => {
    if (dirtyState) {
      if (window.confirm(translate('ra.message.unsaved_changes')))
        state ? setOpen(false) : onClose()
    } else {
      state ? setOpen(false) : onClose()
    }
  }

  let finalTitle = title
  if (!finalTitle) {
    const resourceName = translate(`resources.${rest.resource}.name`, { smart_count: 1 })
    finalTitle = `${resourceName} #${rest.id}`
  }

  const inject = (
    <FormSpy
      subscription={{ dirty: true }}
      onChange={(props) => {
        dirtyState !== props.dirty && setDirtyState(props.dirty)
      }}
    />
  )

  return (
    <Dialog
      // fullWidth={fullWidth}
      open={open}
      onClose={handleClose}
      aria-label={finalTitle}
      classes={useStyles()}
      maxWidth='md'
      fullWidth={true}
    >
      <DialogTitle>{finalTitle}</DialogTitle>
      <DialogContent>
        <Edit {...reduceProps(rest)} component='div' redirect={false}>
          {injectElement(children, inject, ['SimpleForm', 'FormTab'])}
        </Edit>
      </DialogContent>
    </Dialog>
  )
}

export default EditModal
